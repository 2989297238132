import React, { memo } from 'react'

import { Grid } from '@mui/material'

import { Select } from '@e3dc-react/shell/Elements/Select'
import { DatePicker } from '@e3dc-react/shell/Elements/InputFields'
import { deepEqual } from 'fast-equals'
import { ValidationResult } from 'src/Types/globalTypes'
import { CustomerReportLocation, CustomerReportModel, CustomerReportReason } from 'src/Routes/CustomerReport/CustomerReportModel'
import moment, { Moment } from 'moment'
import { useCustomTranslation } from '@e3dc-react/shell/Hooks/useCustomTranslation'

interface VisitDetailsObject {
  reasons: CustomerReportReason[]
  locations: CustomerReportLocation[]
  reason_id: number
  date?: Date
  location_id: number
}

interface VisitDetailsProps {
  validation: ValidationResult
  visitDetails: VisitDetailsObject
  handleInput: (name: keyof CustomerReportModel, value: unknown) => void
  disabled: boolean
}

/**
 * Part of the customer report editor with the details of visit.
 */
const VisitDetails: React.VFC<VisitDetailsProps> = ({ validation, visitDetails, handleInput, disabled }) => {
  const { reasons, locations, reason_id, date, location_id } = visitDetails
  const { t } = useCustomTranslation()

  return (
    <>
      <Grid item xs={12} sm={6}>
        <Select
          // reasons with id < 13 are old ones used only to support and show the old reports
          options={reasons.filter(reason => reason.id > 13 || reason.id === reason_id).map(reason => ({ label: reason.reason, value: reason.id }))}
          value={reason_id}
          onChange={(value: number) => handleInput('reason_id', value)}
          label={t('customerReport.reason')}
          error={validation.reason_id ? validation.reason_id.message : undefined}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Select
          error={validation.location_id ? validation.location_id.message : undefined}
          label={t('common.location')}
          value={location_id}
          onChange={(value: number) => handleInput('location_id', value)}
          // location with id === 5 is an old one used only to support and show the old reports
          options={locations
            .filter(location => location.id !== 5 || location.id === location_id)
            .map(location => ({ label: location.location, value: location.id }))}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <DatePicker
          label={t('customerReport.dayOfVisit')}
          value={moment(date)}
          onChange={(date: Moment | null) => handleInput('date', date ? date.toDate() : '')}
          clearable={false}
          disabled={disabled}
        />
      </Grid>
    </>
  )
}

export default memo(
  VisitDetails,
  (prevProps, nextProp) => deepEqual(prevProps.visitDetails, nextProp.visitDetails) && deepEqual(prevProps.validation, nextProp.validation)
)
