import React, { memo } from 'react'
import moment, { Moment } from 'moment'
import { Grid } from '@mui/material'
import { DatePicker, CheckboxField, MemoizedNumberField } from '@e3dc-react/shell/Elements/InputFields'
import { shallowEqual } from 'fast-equals'
import { EditorSkeleton } from '@e3dc-react/shell/Elements/Skeletons'
import { ProjectModel } from '../ProjectModel'
import { ValidationResult } from 'src/Types/globalTypes'

interface StatusProps {
  status?: Partial<ProjectModel>
  validation: ValidationResult
  onChange: (name: string, value: unknown) => void
}

const Status: React.VFC<StatusProps> = ({ status, validation, onChange }) => {
  if (!status) return <EditorSkeleton small />
  const startDate = moment(status.project_start)
  const endDate = moment(status.project_end)

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <DatePicker
              label="Realisierungszeitraum von"
              value={startDate || null}
              onChange={(value: Moment | null) => {
                onChange('project_start', value ? value.toDate() : '')
              }}
              helperText={validation['project_start'] ? validation['project_start'].message : undefined}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <DatePicker
              label="Realisierungszeitraum bis"
              minDate={startDate || null}
              minDateMessage={'Ende darf nicht vor dem Start liegen'}
              value={endDate || null}
              onChange={(value: Moment | null) => onChange('project_end', value ? value.toDate() : '')}
              helperText={validation['project_end'] ? validation['project_end'].message : undefined}
              required
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={6}>
            <CheckboxField label="in Planung" value={status.is_planned || 0} onChange={value => onChange('is_planned', value)} mapping={[0, 1]} />
          </Grid>
          <Grid item xs={6}>
            <CheckboxField
              label="in Realisierung"
              value={status.is_realised || 0}
              onChange={value => onChange('is_realised', value)}
              mapping={[0, 1]}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <MemoizedNumberField
          helperText={validation['probability_of_realisation'] ? validation['probability_of_realisation'].message : undefined}
          label="Wahrscheinlichkeit der Realisierung in %"
          value={status.probability_of_realisation || 0}
          onChange={value => onChange('probability_of_realisation', value)}
          min={0}
          max={100}
        />
      </Grid>
    </Grid>
  )
}

export default memo(
  Status,
  (prevProps, nextProp) => shallowEqual(prevProps.status, nextProp.status) && shallowEqual(prevProps.validation, nextProp.validation)
)
