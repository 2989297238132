import React, { lazy, useEffect } from 'react'
import { Route, Routes } from 'react-router-dom'

// Shell
import { AppShell } from '@e3dc-react/shell/Elements/AppShell'
import { Relogin } from '@e3dc-react/shell/Elements/Relogin'
import { useLanguageController } from '@e3dc-react/shell/Hooks/LanguageController'
import HeaderProvider from '@e3dc-react/shell/Libs/Header/provider'

// import {graylog} from './Libs/graylog'
import { store, persistor } from './State/Store'
import useI18Next from '@e3dc-react/shell/Hooks/useI18Next'

// themes
import { getThemes } from './Libs/muiTheme'

import Logo from './Resources/Images/E3DC_Logo_438px_sRGB_grey.png'
import ModalRoot from './Elements/ModalRoot'
import { useTypedSelector } from 'src/State/RootReducer'
// TS connection
import { TSConnectionProvider } from './Elements/TSConnection/TSConnection'

import { useDispatch } from 'react-redux'
import { updateUserData } from './State/User/UserActions'
import { UserModel } from './Routes/Users/UserModel'
import useFetch from '@e3dc-react/shell/Hooks/useFetch'
import { DefaultOptions } from 'react-query'

// import Framework from './Elements/Framework'
const Framework = lazy(() => import('./Elements/Framework'))

// Routes (Shell)
const Login = lazy(() => import('@e3dc-react/shell/Routes/Login'))
const ResetPassword = lazy(() => import('@e3dc-react/shell/Routes/ResetPassword'))
const NewPassword = lazy(() => import('@e3dc-react/shell/Routes/NewPassword'))
const Logout = lazy(() => import('@e3dc-react/shell/Routes/Logout'))
const ServerOffline = lazy(() => import('@e3dc-react/shell/Routes/ServerOffline'))
const IconOverview = lazy(() => import('@e3dc-react/shell/Routes/Icons'))

// Routes (App)
const Dashboard = lazy(() => import('./Routes/Dashboard/Dashboard'))
const CustomerData = lazy(() => import('./Routes/CustomerData/Customers'))
const ProspectiveCustomers = lazy(() => import('./Routes/CustomerData/ProspectiveCustomers'))
const ManagementDashboard = lazy(() => import('./Routes/ManagementDashboard/ManagementDashboard'))
const SalesInfo = lazy(() => import('./Routes/SalesInfo/SalesInfo'))
const CustomerReport = lazy(() => import('./Routes/CustomerReport/CustomerReport'))
const Expenses = lazy(() => import('./Routes/Expenses/Expenses'))
const Todos = lazy(() => import('./Routes/Todos/Todos'))
const Notes = lazy(() => import('./Routes/Notes/Notes'))
const Retrofitcheck = lazy(() => import('./Routes/Retrofitcheck/Retrofitcheck'))
// const Calls = lazy(() => import('./Routes/Calls/Calls'))
const MandatoryContact = lazy(() => import('./Routes/MandatoryContact/MandatoryContact'))
const DeletedUsers = lazy(() => import('./Routes/Users/DeletedUsers'))
const ActiveUsers = lazy(() => import('./Routes/Users/ActiveUsers'))
// const Projects = lazy(() => import('./Routes/Projects/Projects'))
// const Testing = lazy(() => import('./Routes/Testing/Testing'))
const { themes, defaultTheme } = getThemes()

const apiEndpoint = process.env.REACT_APP_API_ENDPOINT ?? ''
const appName = process.env.REACT_APP_NAME ?? ''
const logoutRoute = process.env.REACT_APP_LOGOUT_ROUTE ?? ''
const authApiEndpoint = process.env.REACT_APP_AUTH_API_ENDPOINT ?? ''
const apiEndpointAuthRoute = process.env.REACT_APP_API_ENDPOINT_AUTHROUTE ?? ''

const queryProviderProps: DefaultOptions = {
  queries: {
    refetchOnWindowFocus: false,
    staleTime: 4000,
  },
}

/**
 * Main App Component
 */
function App(): React.ReactElement {
  return (
    <AppShell store={store} persistor={persistor} themes={themes} defaultTheme={defaultTheme} queryProviderDefaultOptions={queryProviderProps}>
      <Relogin>
        {/** @todo: combine settings fetch and relogin and other fetches like systems  */}
        <Content />
      </Relogin>
    </AppShell>
  )
}

/**
 * Content component
 * content is a seperate component since it uses redux hooks which are not available until AppShell mounted!
 */
const Content = (): React.ReactElement => {
  const i18next = useI18Next(process.env.REACT_APP_NAME ?? '', process.env.REACT_APP_TRANSLATION_ENDPOINT ?? '')
  useLanguageController(i18next)
  const userId = useTypedSelector(state => state.auth.decoded?.userId)
  const [user] = useFetch<UserModel>({ url: 'user-e3crm/' + userId, doNotFetch: userId === undefined })
  const dispatch = useDispatch()

  useEffect(() => {
    if (user) {
      dispatch(updateUserData(user))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  return (
    <TSConnectionProvider>
      <HeaderProvider>
        <Routes>
          <Route
            path="/login"
            element={
              <Login
                logo={Logo}
                withoutPasswordReset={true}
                redirect={{ pathname: '/' }}
                apiEndpointAuthRoute={apiEndpointAuthRoute}
                authApiEndpoint={authApiEndpoint}
              />
            }
          />
          <Route path="/reset-password" element={<ResetPassword logo={Logo} apiEndpoint={apiEndpoint} appName={appName} />} />
          <Route path="/new-password" element={<NewPassword logo={Logo} apiEndpoint={apiEndpoint} />} />
          <Route path="/logout" element={<Logout redirect={{ pathname: '/login' }} appName={appName} logoutRoute={logoutRoute} />} />
          <Route path="/server-offline" element={<ServerOffline logo={Logo} />} />
          <Route path="/icons" element={<IconOverview />} />
          <Route
            path="*"
            element={
              <Framework>
                <Routes>
                  <Route path="/" element={<Dashboard />} />
                  {/* <Route path="/calls/:id?" exact component={Calls} /> */}
                  <Route path="/notes" element={<Notes />} />
                  <Route path="/customer-data/customers/:id?" element={<CustomerData />} />
                  <Route path="/customer-data/prospects" element={<ProspectiveCustomers />} />
                  <Route path="/management-dashboard" element={<ManagementDashboard />} />
                  <Route path="/sales-info" element={<SalesInfo />} />
                  <Route path="/expenses" element={<Expenses />} />
                  <Route path="/customer-reports" element={<CustomerReport />} />
                  <Route path="/todos*" element={<Todos />} />
                  <Route path="/mandatory-contact" element={<MandatoryContact />} />
                  <Route path="/notes" element={<Notes />} />
                  <Route path="/retrofitcheck" element={<Retrofitcheck />} />

                  {user?.user_level === 'E3DC_ADMIN' && <Route path="/users/active" element={<ActiveUsers />} />}
                  {user?.user_level === 'E3DC_ADMIN' && <Route path="/users/deleted" element={<DeletedUsers />} />}
                  <Route path="*" element={<Dashboard />} />
                </Routes>
                <ModalRoot />
              </Framework>
            }
          />
        </Routes>
      </HeaderProvider>
    </TSConnectionProvider>
  )
}

export default App
