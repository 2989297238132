export interface TodosFetchModel {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any[]
  call_id: number[]
  created_at: Date[]
  remind_at: Date[]
  created_user_id: number[]
  customer_report_id: number[]
  id: number[]
  priority: number[]
  sapCustomerId: string[]
  status: string[]
  text: string[]
  user_id: number[]
  project_id: number[]
}
export interface TodoModel {
  call_id?: number
  created_at?: string
  remind_at?: string
  created_user_id?: number
  customer_report_id?: number
  id: number
  priority: number
  sapCustomerId: string
  status: string
  text: string
  user_id: number
  project_id?: number
}
export interface CurrentTodoModel {
  call_id?: number
  sales_note_id?: number
  customer_note_id?: number
  created_at?: Date
  remind_at?: Date
  created_user_id?: number
  customer_report_id?: number
  id?: number
  priority: number
  sapCustomerId?: string
  status?: string
  text?: string
  user_id?: number
  project_id?: number
}

export const PRIORITY_COLORS = ['#a3e64c', '#a3e64c', '#e6e14c', '#e6a64c', '#e6564c']
