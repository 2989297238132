import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import Joi from 'joi'
import { Moment } from 'moment'

import { makeStyles } from 'tss-react/mui'
import { Grid, Typography, Checkbox, FormGroup, FormControlLabel, FormControl, Divider, Radio, RadioGroup } from '@mui/material'

import { useValidation } from '@e3dc-react/shell/Hooks/useValidation/useValidation'
import { useAuthFetchWithResult } from '@e3dc-react/shell/Hooks/AuthFetch'
import { refreshApiView } from '@e3dc-react/shell/State/ApiView/ApiViewActions'
import { DateTimePicker, TextField } from '@e3dc-react/shell/Elements/InputFields'
import { Dialog } from '@e3dc-react/shell/Elements/Dialog'

import UserSelect from '../User/Select'
import CustomerSelect from '../Customer/Select/Select'
import Slider from './Editor/Slider'
import { getFullUserName } from '../../Libs/user.service'
import { useUsers } from '../../Hooks/useUsers'
import { useSnackbar } from '@e3dc-react/shell/Hooks/Snackbar'
import useModals from 'src/Hooks/useModals'
import { LinkButton } from '@e3dc-react/shell/Elements/Buttons'
import { EditorSkeleton } from '@e3dc-react/shell/Elements/Skeletons'
import { ErrorSkeleton } from '@e3dc-react/shell/Elements/Error'
import useFetch from '@e3dc-react/shell/Hooks/useFetch'
import { useTypedSelector } from 'src/State/RootReducer'
import { CurrentTodoModel, TodoModel } from '../Customer/Todos/TodoModel'
import moment from 'moment'
import { useCustomTranslation } from '@e3dc-react/shell/Hooks/useCustomTranslation'
import { shallowEqual } from 'fast-equals'

const stateSchema = Joi.object().keys({
  user_id: Joi.number().required(),
  sapCustomerId: Joi.string().allow(null),
  text: Joi.string().min(1).required(),
  priority: Joi.number().required(),
  status: Joi.string().valid('offen', 'erledigt').required(),
  remind_at: Joi.date().allow(null),
  created_user_id: Joi.number().allow(null),
})

const useStyles = makeStyles()(theme => ({
  belonging: {
    marginTop: theme.spacing(2),
    width: '100%',
    textAlign: 'center',
  },
  gridOwner: {
    marginTop: theme.spacing(2),
  },
  gridCustomer: {
    marginTop: theme.spacing(2),
  },
}))

interface TodoEditorProps {
  todoId?: number
  sapCustomerId?: string
  customerReportId?: number
  callId?: number
  salesNoteId?: number
  customerNoteId?: number
  projectId?: number
  place: number
  onClose: () => void
}

const TodoEditor: React.VFC<TodoEditorProps> = ({
  todoId,
  salesNoteId,
  customerNoteId,
  callId,
  sapCustomerId,
  customerReportId,
  projectId,
  place,
  onClose,
}) => {
  const { t } = useCustomTranslation()
  const dispatch = useDispatch()
  const { classes } = useStyles()
  const userId = useTypedSelector(state => state.auth.decoded?.userId)
  const [isSaving, setIsSaving] = useState(false)
  const [users, usersLoading, usersError] = useUsers()
  const [todo, todoLoading, todoError] = useFetch<TodoModel>({ url: `todos/${todoId}`, dependencies: [todoId], doNotFetch: todoId === undefined })
  const { enqueueErrorStack, enqueueSuccessStack } = useSnackbar()
  const { openCallEditor, openNoteEditor, openCustomerNoteEditor, openProjectEditor, openCustomerReportEditor } = useModals()

  const authFetch = useAuthFetchWithResult()
  const initialState: CurrentTodoModel = {
    priority: 3,
    status: 'offen',
  }

  const [currentTodo, setCurrentTodo] = useState<CurrentTodoModel>({ ...initialState })
  const [validationActive, setValidationActive] = useState(false)
  const validationObject = useValidation(currentTodo, stateSchema)
  const canEdit = currentTodo.user_id === userId || currentTodo.created_user_id === userId

  const handleInput = (name: string, value: unknown): void => {
    setCurrentTodo({ ...currentTodo, ...{ [name]: value } })
  }

  const saveTodo = async (): Promise<void> => {
    setIsSaving(true)
    // if save button clicked for the first time activate the validation
    if (!validationActive) setValidationActive(true)
    // if validation fails do not save
    if (Object.keys(validationObject).length > 0) {
      setIsSaving(false)

      return
    }

    try {
      if (todoId) {
        await authFetch({ path: `todos/${todoId}?sendMailInfo=1`, method: 'PUT', payload: currentTodo })
        enqueueSuccessStack('Todo gespeichert')
      } else {
        await authFetch({ path: 'todos?sendMailInfo=1', method: 'POST', payload: currentTodo })
        enqueueSuccessStack('Todo hinzugefügt')
      }
    } catch (err) {
      setIsSaving(false)
      enqueueErrorStack(t('todos.error.add'))
    } finally {
      setIsSaving(false)
    }

    dispatch(refreshApiView())
    onClose()
  }

  useEffect(() => {
    if (todoId && todo) {
      const { created_at, remind_at, ...restTodo } = todo
      setCurrentTodo({
        ...restTodo,
        created_at: created_at ? new Date(created_at) : undefined,
        remind_at: remind_at ? new Date(remind_at) : undefined,
      })
    } else {
      setCurrentTodo(state => ({
        ...state,
        call_id: callId,
        sales_note_id: salesNoteId,
        customer_note_id: customerNoteId,
        created_user_id: userId,
        project_id: projectId,
        sapCustomerId: sapCustomerId,
        customer_report_id: customerReportId,
      }))
    }
  }, [userId, todoId, callId, salesNoteId, customerNoteId, sapCustomerId, customerReportId, projectId, todo]) // eslint-disable-line

  const belonging = (() => {
    if (currentTodo.call_id) return <LinkButton content={t('todos.belonging.call')} onClick={() => openCallEditor({ callId: currentTodo.call_id })} />
    if (currentTodo.sales_note_id)
      return <LinkButton content={t('todos.belonging.saleNote')} onClick={() => openNoteEditor({ noteId: currentTodo.sales_note_id })} />
    if (currentTodo.customer_note_id)
      return (
        <LinkButton content={t('todos.belonging.customerNote')} onClick={() => openCustomerNoteEditor({ noteId: currentTodo.customer_note_id })} />
      )
    if (currentTodo.customer_report_id)
      return (
        <LinkButton
          content={t('todos.belonging.report')}
          onClick={() => openCustomerReportEditor({ customerReportId: currentTodo.customer_report_id })}
        />
      )
    if (currentTodo.project_id)
      return <LinkButton content={t('todos.belonging.project')} onClick={() => openProjectEditor({ projectId: currentTodo.project_id })} />
  })()

  const validation = validationActive ? validationObject : {}

  return (
    <Dialog
      open={true}
      onClose={() => onClose()}
      confirmClose={!shallowEqual(currentTodo, todoId ? todo : initialState)}
      title={todoId ? t('todos.edit') : t('todos.add')}
      place={place}
      actions={{
        primary: {
          disabled: !canEdit || (validationActive && Object.keys(validationObject).length > 0),
          onClick: () => saveTodo(),
          name: t('common.save'),
          isLoading: isSaving,
        },
        secondary: [
          {
            name: t('common.cancel'),
          },
        ],
      }}
    >
      {usersLoading || todoLoading || !users ? (
        <EditorSkeleton />
      ) : usersError ? (
        <ErrorSkeleton title={t('errors.usersError', { message: usersError.message })} type={'editor'} />
      ) : todoError && todoId ? (
        <ErrorSkeleton title={t('errors.todoError', { message: todoError.message })} type={'editor'} />
      ) : (
        <Grid container alignItems="center" spacing={2}>
          {todoId && belonging && (
            <>
              <div className={classes.belonging}>
                <Typography variant="body2" align="center">
                  {currentTodo.customer_note_id || currentTodo.sales_note_id
                    ? 'Dieses Todo gehört zur folgenden '
                    : 'Dieses Todo gehört zu folgendem '}
                  {belonging}
                </Typography>
              </div>
              <Grid item xs={12}>
                <Divider />
              </Grid>
            </>
          )}
          <Grid className={classes.gridOwner} item xs={12}>
            <TextField
              label={t('common.owner')}
              value={todoId ? getFullUserName(users, currentTodo.created_user_id) : getFullUserName(users, userId)}
              clearable={false}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomerSelect
              error={validation['sapCustomerId']}
              onSelect={customerId => handleInput('sapCustomerId', customerId)}
              value={currentTodo.sapCustomerId}
              withRedirect={true}
              disabled={!canEdit}
            />
          </Grid>
          <Grid item xs={12} sm={6} className={classes.gridCustomer}>
            <UserSelect
              label={t('common.assignedUser')}
              error={validation['user_id']}
              onSelect={value => handleInput('user_id', value)}
              value={currentTodo.user_id}
              disabled={!canEdit}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              helperText={validation['text'] ? validation['text'].message : false}
              label={t('todos.description')}
              multiline
              value={currentTodo.text || ''}
              onChange={(value: string) => handleInput('text', value)}
              disabled={!canEdit}
            />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Slider priority={currentTodo.priority || 0} handleInput={handleInput} disabled={!canEdit}></Slider>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <FormControl component="fieldset">
              <Typography variant={'subtitle2'}>{t('todos.status')}</Typography>
              <RadioGroup name="gender1" value={currentTodo.status} onChange={(event, value) => handleInput('status', value)}>
                <FormControlLabel value="offen" control={<Radio color={'primary'} />} label="Offen" disabled={!canEdit} />
                <FormControlLabel value="erledigt" control={<Radio color={'primary'} />} label="Erledigt" disabled={!canEdit} />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Typography variant={'subtitle2'}>{t('todos.reminder')}</Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={currentTodo.remind_at !== null}
                    onChange={() => handleInput('remind_at', currentTodo.remind_at ? null : moment().add(7, 'days').toDate())}
                    color="primary"
                    disabled={!canEdit}
                  />
                }
                label={t('todos.addReminder')}
              />
            </FormGroup>
          </Grid>
          <Grid item xs={12} sm={8}>
            <DateTimePicker
              label={t('todos.reminderDate')}
              helperText={validation['remind_at'] ? validation['remind_at'].message : false}
              disabled={currentTodo.remind_at === null || !canEdit}
              value={moment(currentTodo.remind_at)}
              onChange={(date: Moment | null) => handleInput('remind_at', date ? date.toDate() : '')}
            />
          </Grid>
        </Grid>
      )}
    </Dialog>
  )
}

export default TodoEditor
