import { useDispatch } from 'react-redux'
import { ExpenseModel } from 'src/Routes/Expenses/ExpensesModel'
import { addModal } from '@e3dc-react/shell/State/Modals/ModalActions'
import { CustomerDetailsModel } from 'src/Routes/CustomerData/CustomerModel'

export interface TodoEditorProps {
  todoId?: number
  sapCustomerId?: string
  customerReportId?: number
  callId?: number
  customerNoteId?: number
  salesNoteId?: number
  projectId?: number
}

export interface UserEditorProps {
  userId?: number
}

export interface CallEditorProps {
  callId?: number
  sapCustomerId?: string
  sapContactId?: string
}
export interface NoteEditorProps {
  noteId?: number
  sapCustomerId?: string
}
export interface CustomerNoteEditorProps {
  noteId?: number
  sapCustomerId?: string
}

export interface ExpenseEditorProps {
  expense?: ExpenseModel
}

export interface ProspectiveCustomerEditorProps {
  prospectiveCustomerId?: number
}

export interface CustomerViewProps {
  customerId: string
}

export interface CustomerEditorProps {
  customerId: string
  keyToEdit: keyof CustomerDetailsModel
}

export interface CustomerReportEditorProps {
  customerReportId?: number
  sapCustomerId?: string
}

export interface ProspectiveCustomerContactEditorProps {
  contactId?: number
  prospectiveCustomerId?: string
}

export interface ProjectEditorProps {
  projectId?: number
}

export interface ConfirmationModalProps {
  text: string
  title: string
  buttonText: [string, string]
  onConfirm: (onClose: () => void) => Promise<void>
}

export interface NoteEditorProps {
  noteId?: number
}

interface Modals {
  openTodoEditor: (props: TodoEditorProps) => void
  openUserEditor: (props: UserEditorProps) => void
  openCallEditor: (props: CallEditorProps) => void
  openCustomerNoteEditor: (props: CustomerNoteEditorProps) => void
  openProspectiveCustomerEditor: (props: ProspectiveCustomerEditorProps) => void
  openCustomerDataDisplay: (props: CustomerViewProps) => void
  openCustomerEditor: (props: CustomerEditorProps) => void
  openCustomerReportEditor: (props: CustomerReportEditorProps) => void
  openProspectiveCustomerContactEditor: (props: ProspectiveCustomerContactEditorProps) => void
  openProjectEditor: (props: ProjectEditorProps) => void
  openConfirmationModal: (props: ConfirmationModalProps) => void
  openExpenseEditor: (props: ExpenseEditorProps) => void
  openTranslationEditor: () => void
  openNoteEditor: (props: NoteEditorProps) => void
}

/**
 * custom hook to show modals
 */
const useModals = (): Modals => {
  const dispatch = useDispatch()

  const openTodoEditor = ({ todoId, callId, customerNoteId, salesNoteId, sapCustomerId, customerReportId, projectId }: TodoEditorProps): void => {
    dispatch(
      addModal({
        type: 'todoEditor',
        todoId,
        callId,
        customerNoteId,
        salesNoteId,
        sapCustomerId,
        customerReportId,
        projectId,
      })
    )
  }

  const openUserEditor = ({ userId }: UserEditorProps): void => {
    dispatch(addModal({ type: 'userEditor', userId: userId }))
  }

  const openTranslationEditor = (): void => {
    dispatch(addModal({ type: 'translationEditor' }))
  }

  const openCallEditor = ({ callId, sapCustomerId, sapContactId }: CallEditorProps): void => {
    dispatch(addModal({ type: 'callEditor', callId, sapCustomerId, sapContactId }))
  }

  const openNoteEditor = ({ noteId, sapCustomerId }: NoteEditorProps): void => {
    dispatch(addModal({ type: 'noteEditor', noteId, sapCustomerId }))
  }

  const openCustomerNoteEditor = ({ noteId, sapCustomerId }: CustomerNoteEditorProps): void => {
    dispatch(addModal({ type: 'customerNoteEditor', noteId, sapCustomerId }))
  }

  const openExpenseEditor = ({ expense }: ExpenseEditorProps): void => {
    dispatch(addModal({ type: 'expenseEditor', expense }))
  }

  const openProspectiveCustomerEditor = ({ prospectiveCustomerId }: ProspectiveCustomerEditorProps): void => {
    dispatch(addModal({ type: 'prospectiveCustomerEditor', prospectiveCustomerId }))
  }

  const openCustomerDataDisplay = ({ customerId }: CustomerViewProps): void => {
    dispatch(addModal({ type: 'customerDataDisplay', customerId }))
  }

  const openCustomerEditor = ({ customerId, keyToEdit }: CustomerEditorProps): void => {
    dispatch(addModal({ type: 'customerEditor', customerId, keyToEdit }))
  }

  const openCustomerReportEditor = ({ customerReportId, sapCustomerId }: CustomerReportEditorProps): void => {
    dispatch(addModal({ type: 'customerReportEditor', customerReportId, sapCustomerId }))
  }

  const openProspectiveCustomerContactEditor = ({ contactId, prospectiveCustomerId }: ProspectiveCustomerContactEditorProps): void => {
    dispatch(addModal({ type: 'prospectiveCustomerContactEditor', contactId, prospectiveCustomerId }))
  }

  const openProjectEditor = ({ projectId }: ProjectEditorProps): void => {
    dispatch(addModal({ type: 'projectEditor', projectId }))
  }

  const openConfirmationModal = ({ text, buttonText, onConfirm, title }: ConfirmationModalProps): void => {
    dispatch(addModal({ type: 'confirmationModal', text, buttonText, onConfirm, title }))
  }

  return {
    openTodoEditor,
    openUserEditor,
    openCallEditor,
    openNoteEditor,
    openCustomerNoteEditor,
    openProspectiveCustomerEditor,
    openCustomerDataDisplay,
    openCustomerEditor,
    openCustomerReportEditor,
    openProspectiveCustomerContactEditor,
    openProjectEditor,
    openConfirmationModal,
    openExpenseEditor,
    openTranslationEditor,
  }
}

export default useModals
