import React, { memo } from 'react'
import { Grid } from '@mui/material'
import { CheckboxField, MemoizedTextField } from '@e3dc-react/shell/Elements/InputFields'
import { shallowEqual } from 'fast-equals'
import { ProjectModel } from '../ProjectModel'
import { ValidationResult } from 'src/Types/globalTypes'

interface DetailsProps {
  details?: Partial<ProjectModel>
  validation: ValidationResult
  onChange: (name: string, value: unknown) => void
}

const Details: React.VFC<DetailsProps> = ({ details, validation, onChange }) => {
  if (!details) return null

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <MemoizedTextField
              helperText={validation['reference'] ? validation['reference'].message : undefined}
              label="Projektreferenz"
              value={details.reference || ''}
              onChange={(value: string) => onChange('reference', value)}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <MemoizedTextField
              helperText={validation['location'] ? validation['location'].message : undefined}
              label="Projektstandort"
              value={details.location || ''}
              onChange={(value: string) => onChange('location', value)}
              required
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <MemoizedTextField
              helperText={validation['company_name'] ? validation['company_name'].message : undefined}
              label="Firmenname"
              value={details.company_name || ''}
              onChange={(value: string) => onChange('company_name', value)}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <CheckboxField
              label="E3/DC-zertifiziert"
              value={details.certified || 0}
              onChange={value => onChange('certified', value)}
              mapping={[0, 1]}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <MemoizedTextField
          helperText={validation['company_contact'] ? validation['company_contact'].message : undefined}
          label="Ansprechpartner"
          value={details.company_contact || ''}
          onChange={(value: string) => onChange('company_contact', value)}
          required
        />
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <MemoizedTextField
              helperText={validation['company_phone'] ? validation['company_phone'].message : undefined}
              label="Telefon"
              value={details.company_phone || ''}
              onChange={(value: string) => onChange('company_phone', value)}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <MemoizedTextField
              helperText={validation['company_mail'] ? validation['company_mail'].message : undefined}
              label="E-Mail"
              value={details.company_mail || ''}
              onChange={(value: string) => onChange('company_mail', value)}
              required
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default memo(
  Details,
  (prevProps, nextProp) => shallowEqual(prevProps.details, nextProp.details) && shallowEqual(prevProps.validation, nextProp.validation)
)
