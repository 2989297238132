import React from 'react'
import { PRIORITY_COLORS } from '../Customer/Todos/TodoModel'
import { J2Product, J2Addedproduct } from '@e3dc-react/icons'

interface TodoStatusWithPriorityProps {
  priority: number
  status: string
}

const TodoStatusWithPriority: React.VFC<TodoStatusWithPriorityProps> = ({ priority, status }) => {
  const color = PRIORITY_COLORS[priority - 1] || 'black'
  return status === 'offen' ? <J2Product {...{ style: { color: color } }} /> : <J2Addedproduct {...{ style: { color: color } }} />
}

export default TodoStatusWithPriority
