import React, { useState, useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import Joi from 'joi'

import { Grid, Typography, Divider, FormGroup, FormControlLabel } from '@mui/material'

import { useValidation } from '@e3dc-react/shell/Hooks/useValidation/useValidation'
import { objectApiResult, parseNullableDate } from '@e3dc-react/shell/Libs/ApiHelpers'
import { useAuthFetchWithResult } from '@e3dc-react/shell/Hooks/AuthFetch'

import VisitDetails from './Editor/VisitDetails'
import Customer from './Editor/Customer'
import { Dialog } from '@e3dc-react/shell/Elements/Dialog'
import { refreshApiView } from '@e3dc-react/shell/State/ApiView/ApiViewActions'
import { getFullUserName } from '../../Libs/user.service'
import { useUsers } from '../../Hooks/useUsers'
import { CheckboxField, TextField } from '@e3dc-react/shell/Elements/InputFields'
import { useSnackbar } from '@e3dc-react/shell/Hooks/Snackbar'
import AddTodoButton from '../Todo/AddTodoButton'
import AddCallButton from '../Call/AddCallButton'
import useModals from '../../Hooks/useModals'
import { makeStyles } from 'tss-react/mui'
import { useFetchApiObject } from '@e3dc-react/shell/Hooks/useFetch'
import { ErrorMessage } from '@e3dc-react/shell/Elements/Error'
import { ListSkeleton } from '@e3dc-react/shell/Elements/Skeletons'
import { FileTransfer } from '@e3dc-react/shell/Elements/FileTransfer'
import {
  CurrentCustomerReportModel,
  CustomerReportsFetchModel,
  CustomerReportLocation,
  CustomerReportModel,
  CustomerReportReason,
  Fields,
  FieldsValue,
} from 'src/Routes/CustomerReport/CustomerReportModel'
import { useTypedSelector } from 'src/State/RootReducer'
import { ValidationResult } from 'src/Types/globalTypes'
import { useCustomTranslation } from '@e3dc-react/shell/Hooks/useCustomTranslation'
import { Select } from '@e3dc-react/shell/Elements/Select'
import NotesFields from './Editor/NotesFields'
import useCustomers from 'src/Hooks/useCustomers'
import moment from 'moment'
import { pdf, Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer'
import Logo from 'src/Resources/Images/E3DC_Logo_438px_sRGB_grey.png'
import { J2Download } from '@e3dc-react/icons'
import { saveAs } from 'file-saver'
import { ButtonWithLoading } from '@e3dc-react/shell/Elements/Buttons/Buttons'
import { AuthFetchError } from '@e3dc-react/shell/Types/api'

const stateSchema = Joi.object().keys({
  sapCustomerId: Joi.string().required(),
  sapContactId: Joi.string().required(),
  potential: Joi.number().min(1).max(5).required(),
  reason_id: Joi.number().required(),
  date: Joi.date().required(),
  location_id: Joi.number().required(),
  competitors: Joi.array().items(
    Joi.object().keys({
      amount: Joi.number().min(1),
      competitor_id: Joi.number().min(1),
      reason_id: Joi.number().min(1),
      estimated: Joi.number().min(0).max(1),
    })
  ),
  participantCustomer: Joi.object().keys({
    value: Joi.string().required(),
    isDisabled: Joi.boolean(),
  }),
  summary: Joi.object().keys({
    value: Joi.string().required(),
    isDisabled: Joi.boolean(),
  }),
  products: Joi.object().keys({
    value: Joi.string().required(),
    isDisabled: Joi.boolean(),
  }),
})

const useStyles = makeStyles()(theme => ({
  leftAction: {
    paddingLeft: '12px',
  },
}))

interface CustomerReportEditorProps {
  customerReportId?: number
  sapCustomerId?: string
  place: number
  onClose: () => void
}

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    padding: 10,
    fontSize: 14,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  header: {
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: 50,
    width: '100%',
  },
  mainData: {
    flexWrap: 'wrap',
  },
  dataItem: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 5,
  },
  boldTitle: {
    fontWeight: 'medium',
  },
  sectionTitle: {
    textAlign: 'center',
    fontSize: 18,
    fontWeight: 'bold',
    padding: 15,
  },
  grey: {
    backgroundColor: '#e3e3e3',
  },
  headerText: {
    fontSize: 12,
    color: '#333333',
  },
  noteTitle: {
    textAlign: 'center',
    fontSize: 16,
    fontWeight: 'bold',
  },
  note: {
    // backgroundColor: '#e3e3e3',
    padding: 5,
    margin: 10,
    border: '2px solid #e3e3e3',
    borderRadius: 12,
  },
})

/**
 * Dialog content for the customer report editor dialog.
 */
const CustomerReportEditor: React.VFC<CustomerReportEditorProps> = ({ customerReportId, sapCustomerId, place, onClose }) => {
  const { t } = useCustomTranslation()
  const authFetch = useAuthFetchWithResult()
  const dispatch = useDispatch()
  const { openTodoEditor } = useModals()
  const { classes } = useStyles()
  const { enqueueErrorStack, enqueueSuccessStack } = useSnackbar()
  const [users, usersLoading, usersError] = useUsers()
  const [reasons, reasonsLoading, reasonsError] = useFetchApiObject<CustomerReportReason[]>({
    url: 'customer-reports/editor/reasons',
  })
  const [locations, locationsLoading, locationsError] = useFetchApiObject<CustomerReportLocation[]>({
    url: 'customer-reports/editor/locations',
  })
  const [customers] = useCustomers()
  const [pdfLoading, setPdfLoading] = useState(false)
  const userId = useTypedSelector(state => state.auth.decoded?.userId)

  const [loading, setLoading] = useState(false)
  const [withTodo, setWithTodo] = useState(false)

  const defaultState: CurrentCustomerReportModel = {
    competitors: [],
    date: new Date(),
    potential: 3,
    reason_id: 0,
    sapCustomerId: sapCustomerId,
    user_id: userId,
    participantCustomer: { value: undefined, isDisabled: true },
    generalTopics: { value: '', isDisabled: true },
    products: { value: undefined, isDisabled: true },
    summary: { value: undefined, isDisabled: true },
    note: { value: '', isDisabled: true },
    // temporarily hidden
    participantE3dc: { value: '', isDisabled: true, isHidden: true },
    marketingAndCourses: { value: '', isDisabled: true, isHidden: true },
    serviceAndSupport: { value: '', isDisabled: true, isHidden: true },
    marketAndCompetition: { value: '', isDisabled: true, isHidden: true },
  }

  const [customerReport, setCustomerReport] = useState(defaultState)
  const [validationActive, setValidationActive] = useState(false)
  const validationObject = useValidation(customerReport, stateSchema)
  const canEdit = userId === customerReport.user_id

  const [fields, setFields] = useState<Fields>({
    participantCustomer: customerReport.participantCustomer,
    generalTopics: customerReport.generalTopics,
    products: customerReport.products,
    summary: customerReport.summary,
    note: customerReport.note,
    /* temporarily hidden, maybe deleted later */
    participantE3dc: customerReport.participantE3dc,
    marketingAndCourses: customerReport.marketAndCompetition,
    serviceAndSupport: customerReport.serviceAndSupport,
    marketAndCompetition: customerReport.marketAndCompetition,
  })

  useEffect(() => {
    const fields = {
      participantCustomer: { ...customerReport.participantCustomer, isDisabled: !canEdit },
      generalTopics: { ...customerReport.generalTopics, isDisabled: !canEdit },
      products: { ...customerReport.products, isDisabled: !canEdit },
      summary: { ...customerReport.summary, isDisabled: !canEdit },
      note: customerReport.note,
      /* temporarily hidden, maybe deleted later */
      participantE3dc: customerReport.participantE3dc,
      marketingAndCourses: customerReport.marketAndCompetition,
      serviceAndSupport: customerReport.serviceAndSupport,
      marketAndCompetition: customerReport.marketAndCompetition,
    }

    setFields(fields)
  }, [canEdit, customerReport])

  const Pdf = useMemo(() => {
    const { user_id, type, potential, date, reason_id, id, sapContactId, sapCustomerId, location_id } = customerReport
    const customer = customers?.find(customer => customer.Kundennr === sapCustomerId)

    return (
      <Document>
        <Page break wrap size="A4" style={styles.page}>
          <View fixed style={styles.header}>
            <Image src={Logo}></Image>
            <Text style={styles.headerText}>{id}</Text>
          </View>
          <Text style={styles.sectionTitle}>Bericht</Text>
          <View style={styles.mainData}>
            <View style={[styles.dataItem, styles.grey]}>
              <Text style={styles.boldTitle}>{t('common.owner')}</Text>
              <Text>{getFullUserName(users, user_id)}</Text>
            </View>
            <View style={styles.dataItem}>
              <Text style={styles.boldTitle}>{t('customerReport.type')}</Text>
              <Text>{t('customerReport.types.' + (type ?? 'visit'))}</Text>
            </View>
            <View style={[styles.dataItem, styles.grey]}>
              <Text style={styles.boldTitle}>{t('common.customer')}</Text>
              <Text>{customer?.Kundenname}</Text>
            </View>
            <View style={styles.dataItem}>
              <Text style={styles.boldTitle}>{t('customers.selectContact.contactPerson')}</Text>
              <Text>{customer?.Kontakte.find(contact => contact.id === sapContactId)?.Name}</Text>
            </View>
            <View style={[styles.dataItem, styles.grey]}>
              <Text style={styles.boldTitle}>{t('customerReport.potential')}</Text>
              <Text>{t(`customerReport.sliderMarks.${potential - 1}`)}</Text>
            </View>
          </View>

          <Text style={styles.sectionTitle}>Allgemeine Daten</Text>
          <View style={styles.mainData}>
            <View style={[styles.dataItem, styles.grey]}>
              <Text>{t('customerReport.reason')}</Text>
              <Text>{reasons?.find(reason => reason.id === reason_id)?.reason}</Text>
            </View>
            <View style={styles.dataItem}>
              <Text>{t('common.location')}</Text>
              <Text>{locations?.find(location => location.id === location_id)?.location}</Text>
            </View>
            <View style={[styles.dataItem, styles.grey]}>
              <Text style={styles.boldTitle}>{t('customerReport.dayOfVisit')}</Text>
              <Text>{moment(date).format('DD.MM.YYYY')}</Text>
            </View>
          </View>
          <Text style={styles.sectionTitle}>Gesprächsnotizen</Text>
          <View style={styles.mainData}>
            {Object.keys(fields).map(item => {
              const mappedItem = item as keyof CurrentCustomerReportModel
              if (!customerReport[mappedItem]) return null
              return (
                <View key={mappedItem}>
                  <Text style={[styles.boldTitle, styles.noteTitle]}>{t(`customerReport.notesFields.${mappedItem}`)}</Text>
                  <Text style={styles.note}>
                    {typeof customerReport[mappedItem] === 'object' ? (customerReport[mappedItem] as FieldsValue)?.value : customerReport[mappedItem]}
                  </Text>
                </View>
              )
            })}
          </View>
        </Page>
      </Document>
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerReport, customers, fields, locations, reasons, users])

  const generatePdfDocument = async (): Promise<void> => {
    setPdfLoading(true)
    try {
      const blob = await pdf(Pdf).toBlob()
      saveAs(blob, `${t('customerReport.title')}_${customerReportId}.pdf`)
    } catch (err) {
      console.error(err)
    } finally {
      setPdfLoading(false)
    }
  }

  const handleInput = (name: keyof CustomerReportModel, value: unknown): void => {
    let updateObj = { [name]: value }
    // reset sapContactId if Customer changed
    if (name === 'sapCustomerId') {
      updateObj = { ...updateObj, sapContactId: '' }
    }

    setCustomerReport(s => ({ ...s, ...updateObj }))
  }
  const handleNotesInput = (name: keyof Fields, valueObject: FieldsValue): void => {
    setCustomerReport(s => ({ ...s, [name]: valueObject }))
  }

  useEffect(() => {
    /**
     * get last customerReport statistics
     */
    const getLastCustomerReport = async (): Promise<CustomerReportModel | undefined> => {
      const customerReports = objectApiResult<CustomerReportsFetchModel, CustomerReportModel[]>(
        await authFetch<CustomerReportsFetchModel>({
          path: 'customer-reports',
          method: undefined,
          payload: {
            filter: `sapCustomerId=${customerReport.sapCustomerId}`,
            sort: '-date',
            limit: 1,
            fields: 'id',
          },
        })
      )

      if (customerReports.length === 0) return undefined
      return await authFetch({ path: `customer-reports/${customerReports[0].id}` })
    }

    if (!customerReportId && customerReport.sapCustomerId) {
      getLastCustomerReport().then(lastCustomerReport => {
        if (!lastCustomerReport) return
        // set the last statistics values for the new customer report
        setCustomerReport(customerReport => ({
          ...customerReport,
          competitors: lastCustomerReport.competitors,
        }))
      })
    }
  }, [customerReport.sapCustomerId]) //eslint-disable-line

  const saveCustomerReport = async (): Promise<void> => {
    setLoading(true)
    // if save button clicked for the first time activate the validation
    if (!validationActive) setValidationActive(true)
    // if validation fails do not save
    if (Object.keys(validationObject).length > 0) {
      enqueueErrorStack(t('errors.fillAllFields'))
      setLoading(false)
      return
    }

    let crId: number | undefined

    const convertedCustomerReport = {
      ...customerReport,
      participantCustomer: customerReport.participantCustomer.value,
      generalTopics: customerReport.generalTopics.value,
      products: customerReport.products.value,
      summary: customerReport.summary.value,
      note: customerReport.note.value,
      /* temporarily hidden, maybe deleted later */
      participantE3dc: customerReport.participantE3dc.value,
      marketingAndCourses: customerReport.marketAndCompetition.value,
      serviceAndSupport: customerReport.serviceAndSupport.value,
      marketAndCompetition: customerReport.marketAndCompetition.value,
    }

    try {
      if (customerReportId) {
        const { id, expense_application_id, created_at, deleted_at, ...customerReportToPut } = convertedCustomerReport
        crId = id
        await authFetch({ path: 'customer-reports/' + customerReportId, method: 'PUT', payload: { customerReport: customerReportToPut } })
        enqueueSuccessStack(t('customerReport.success.save'))
      } else {
        crId = await authFetch({ path: 'customer-reports', method: 'POST', payload: { customerReport: convertedCustomerReport } })
        enqueueSuccessStack(t('customerReport.success.add'))
        handleInput('id', crId)
      }
    } catch (error) {
      const err = error as AuthFetchError

      enqueueErrorStack(t('customerReport.error.add', { errorCode: err.code, message: err.message }))
    }
    setLoading(false)
    onClose()

    if (withTodo) openTodoEditor({ customerReportId: crId, sapCustomerId: customerReport.sapCustomerId })
    dispatch(refreshApiView())
  }

  useEffect(() => {
    const fetchCustomerReport = async (): Promise<void> => {
      if (reasons && locations) {
        setCustomerReport(customerReport => ({
          ...customerReport,
          ...{ reason_id: reasons[0].id, location_id: locations[0].id },
          sapCustomerId: sapCustomerId,
        }))
      }
      if (customerReportId) {
        const fetchedCustomerReport = await authFetch<CustomerReportModel>({ path: 'customer-reports/' + customerReportId })
        const { created_at, date, deleted_at, ...restFetchedCustomerReport } = fetchedCustomerReport

        setCustomerReport({
          ...customerReport,
          ...restFetchedCustomerReport,
          created_at: new Date(created_at + 'Z'),
          date: parseNullableDate(date),
          note: { value: fetchedCustomerReport.note, isDisabled: true },
          participantCustomer: { value: fetchedCustomerReport.participantCustomer, isDisabled: true },
          generalTopics: { value: fetchedCustomerReport.generalTopics, isDisabled: true },
          products: { value: fetchedCustomerReport.products, isDisabled: true },
          summary: { value: fetchedCustomerReport.summary, isDisabled: true },
          participantE3dc: { value: fetchedCustomerReport.participantE3dc, isDisabled: true },
          marketAndCompetition: { value: fetchedCustomerReport.marketAndCompetition, isDisabled: true },
          marketingAndCourses: { value: fetchedCustomerReport.marketingAndCourses, isDisabled: true },
          serviceAndSupport: { value: fetchedCustomerReport.serviceAndSupport, isDisabled: true },
        })
      }
    }

    fetchCustomerReport()
  }, [customerReportId, sapCustomerId]) //eslint-disable-line

  const validation: ValidationResult = validationActive ? validationObject : {}
  return (
    <Dialog
      open={true}
      confirmClose={true}
      onClose={() => onClose()}
      title={'Berichtsübersicht ' + (customerReportId ? 'bearbeiten' : 'einfügen')}
      place={place}
      actions={{
        primary: {
          name: t('common.save'),
          disabled:
            !customerReport.type ||
            (validationActive && Object.keys(validationObject).length > 0) ||
            (Boolean(customerReportId) && customerReport.user_id !== userId),
          onClick: () => saveCustomerReport(),
          isLoading: loading,
        },
        secondary: [
          {
            name: t('common.cancel'),
          },
        ],
      }}
      additionalActionsLeft={
        <div className={classes.leftAction}>
          {customerReportId ? (
            <Grid item xs={12}>
              <Grid container spacing={2} alignItems="center">
                <Grid item>
                  <AddTodoButton customerReportId={customerReport.id} sapCustomerId={customerReport.sapCustomerId} disabled={!canEdit} />
                </Grid>
                <Grid item>
                  <AddCallButton sapCustomerId={customerReport.sapCustomerId} disabled={!canEdit} />
                </Grid>
                <Grid item>
                  <ButtonWithLoading
                    onClick={() => generatePdfDocument()}
                    icon={<J2Download />}
                    label={'PDF'}
                    isLoading={pdfLoading}
                    variant={'outlined'}
                  />
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <FormGroup row>
              <FormControlLabel
                control={
                  <CheckboxField
                    value={withTodo}
                    onChange={() => setWithTodo(!withTodo)}
                    color="primary"
                    disabled={!canEdit || !customerReport.type}
                  />
                }
                label={t('customerReports.addTodo')}
              />
            </FormGroup>
          )}
        </div>
      }
    >
      {usersLoading ? (
        <ListSkeleton />
      ) : usersError ? (
        <ErrorMessage title={t('errors.usersError')} />
      ) : (
        <>
          <Grid container alignItems="center" marginTop={1} spacing={2}>
            <Grid item xs={12}>
              <TextField
                label={t('common.owner')}
                value={customerReport ? getFullUserName(users, customerReport.user_id) : getFullUserName(users, userId)}
                disabled
              />
            </Grid>
            <Grid item xs={12}>
              <Select
                onChange={(value: string) => handleInput('type', value)}
                options={[
                  { label: t('customerReport.types.report'), value: 'visit' },
                  { label: t('customerReport.types.call'), value: 'call' },
                ]}
                label={t('customerReport.type')}
                value={customerReport.type ?? 'visit'}
                disabled={!canEdit}
              />
            </Grid>
            {(customerReportId || (!customerReportId && customerReport.type)) && (
              <>
                <Grid item xs={12}>
                  <Typography variant="subtitle2">{t('common.customer')}</Typography>
                </Grid>
                <Customer
                  validation={validation}
                  customer={{
                    sapCustomerId: customerReport.sapCustomerId,
                    sapContactId: customerReport.sapContactId,
                    potential: customerReport.potential,
                  }}
                  handleInput={handleInput}
                  disabled={!canEdit}
                />
                <Grid item xs={12}>
                  <Divider />
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="subtitle2">{t('common.visitKeyData')}</Typography>
                </Grid>
                {reasonsLoading || locationsLoading ? (
                  <ListSkeleton length={3} />
                ) : reasonsError || locationsError ? (
                  <ErrorMessage title={t('errors.visitDataError')} />
                ) : (
                  <VisitDetails
                    validation={validation}
                    visitDetails={{
                      reasons: reasons ?? [],
                      locations: locations ?? [],
                      reason_id: customerReport.reason_id ?? 0,
                      date: customerReport.date ?? undefined,
                      location_id: customerReport.location_id ?? 0,
                    }}
                    handleInput={handleInput}
                    disabled={!canEdit}
                  />
                )}
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <NotesFields handleInput={handleNotesInput} fields={fields} validation={validation} />
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  {customerReport.id ? (
                    <FileTransfer
                      uploadUrl={'customer-reports/' + customerReport.id + '/upload'}
                      downloadListUrl={'customer-reports/' + customerReport.id + '/download/list'}
                      downloadGenerateUrl={(filename: string) => 'customer-reports/' + customerReport.id + '/download/' + filename}
                      deleteGenerateUrl={(filename: string) => 'customer-reports/' + customerReport.id + '/delete/' + filename}
                      apiEndpoint={process.env.REACT_APP_API_ENDPOINT ?? ''}
                    />
                  ) : (
                    <Typography variant="subtitle2" color="error">
                      {t('customerReport.error.saveForUpload')}
                    </Typography>
                  )}
                </Grid>
              </>
            )}
          </Grid>
        </>
      )}
    </Dialog>
  )
}

export default CustomerReportEditor
