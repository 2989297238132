import { UserModel } from 'src/Routes/Users/UserModel'

export const getUsername = (users: UserModel[] | undefined, userId?: number): string | undefined => {
  const user = users?.find(user => user.id === userId)
  return user ? user.ldapmail.replace(/@e3dc.com/, '') : undefined
}

export const getFullUserName = (users: UserModel[] | undefined, userId?: number): string | undefined => {
  const user = users?.find(user => user.id === userId)
  return user ? `${user.first_name} ${user.last_name}` : undefined
}
