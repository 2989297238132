import React from 'react'
import { FileTransfer } from '@e3dc-react/shell/Elements/FileTransfer'
const ProspectiveCustomerDetails: React.VFC<{ customerId: string }> = ({ customerId }) => {
  customerId = customerId.replace('I', '')
  return (
    <FileTransfer
      uploadUrl={'prospective-customers/' + customerId + '/upload'}
      downloadListUrl={'prospective-customers/' + customerId + '/download/list'}
      downloadGenerateUrl={(filename: string) => 'prospective-customers/' + customerId + '/download/' + filename}
      deleteGenerateUrl={(filename: string) => 'prospective-customers/' + customerId + '/delete/' + filename}
      apiEndpoint={process.env.REACT_APP_API_ENDPOINT ?? ''}
    />
  )
}

export default ProspectiveCustomerDetails
