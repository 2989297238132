import React, { memo } from 'react'
import { Grid } from '@mui/material'
import { CheckboxField } from '@e3dc-react/shell/Elements/InputFields'
import { shallowEqual } from 'fast-equals'
import { ProjectModel } from '../ProjectModel'
import { ValidationResult } from 'src/Types/globalTypes'
interface QuestionsProps {
  questions?: Partial<ProjectModel>
  validation: ValidationResult
  onChange: (name: string, value: unknown) => void
}

const Questions: React.VFC<QuestionsProps> = ({ questions, validation, onChange }) => {
  if (!questions) return null

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={6}>
            <CheckboxField
              label="Allgemeine Projektberatung"
              value={questions.project_consulting || 0}
              onChange={value => onChange('project_consulting', value)}
              mapping={[0, 1]}
            />
          </Grid>
          <Grid item xs={6}>
            <CheckboxField
              label="Unterstützung Produktauswahl"
              value={questions.project_selection_support || 0}
              onChange={value => onChange('project_selection_support', value)}
              mapping={[0, 1]}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={6}>
            <CheckboxField
              label="Technische Produktberatung"
              value={questions.technical_consulting || 0}
              onChange={value => onChange('technical_consulting', value)}
              mapping={[0, 1]}
            />
          </Grid>
          <Grid item xs={6}>
            <CheckboxField
              label="Wechselrichterauslegung"
              value={questions.inverter_design || 0}
              onChange={value => onChange('inverter_design', value)}
              mapping={[0, 1]}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={6}>
            <CheckboxField
              label="Angebotserstellung"
              value={questions.offer_preparation || 0}
              onChange={value => onChange('offer_preparation', value)}
              mapping={[0, 1]}
            />
          </Grid>
          <Grid item xs={6}>
            <CheckboxField
              label="Speicherkapazität berechnen"
              value={questions.storage_calculation ?? 0}
              onChange={value => onChange('storage_calculation', value)}
              mapping={[0, 1]}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={6}>
            <CheckboxField
              label="Vermittlung eines Installationspartner"
              value={questions.installator_procurement ?? 0}
              onChange={value => onChange('installator_procurement', value)}
              mapping={[0, 1]}
            />
          </Grid>
          <Grid item xs={6}>
            <CheckboxField
              label="Zählerkonzept"
              value={questions.counter_concept ?? 0}
              onChange={value => onChange('counter_concept', value)}
              mapping={[0, 1]}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default memo(
  Questions,
  (prevProps, nextProp) => shallowEqual(prevProps.questions, nextProp.questions) && shallowEqual(prevProps.validation, nextProp.validation)
)
