import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { TCSystemAction, TCSystemReducserStateModel } from './TCSystemModel'

const initialState: TCSystemReducserStateModel = {
  dnd: false,
  phoneCallsMaxSize: 10,
  phoneCalls: [],
}

function fetchReducer(state: TCSystemReducserStateModel = initialState, action: TCSystemAction): TCSystemReducserStateModel {
  switch (action.type) {
    case 'SET_DND':
      return { ...state, dnd: action.dnd ?? state.dnd }
    case 'SET_PHONECALLS':
      return { ...state, phoneCalls: action.phoneCalls ?? state.phoneCalls }
    default:
      return state
  }
}

const persistConfig = {
  key: 'phoneCalls',
  storage: storage,
  whitelist: ['phoneCalls'],
}

export default persistReducer(persistConfig, fetchReducer)
