import React from 'react'
import { useDispatch } from 'react-redux'

import CallEditor from './Call/Editor'
import UserEditor from './User/Editor'
import CustomerEditor from './Customer/Editor'
import ProspectiveCustomerEditor from './ProspectiveCustomer/Editor'
import CustomerReportEditor from './CustomerReport/Editor'
import ContactEditor from './ProspectiveCustomer/Contact/Editor'
import CustomerDisplay from './Customer/Display'
import TodoEditor from './Todo/Editor'
import ProspectiveCustomerDisplay from './ProspectiveCustomer/Display'
import ProjectsEditor from '../Routes/Projects/Editor'
import ExpenseEditor from '../Routes/Expenses/Editor'
import { useTypedSelector } from 'src/State/RootReducer'
import { removeModal } from '@e3dc-react/shell/State/Modals/ModalActions'
import { Modal } from '@e3dc-react/shell/State/Modals/ModalModels'
import TranslationEditor, { TranslationEditorProps } from '@e3dc-react/shell/Elements/TranslationEditor/TranslationEditor'

import {
  CallEditorProps,
  NoteEditorProps,
  CustomerNoteEditorProps,
  ConfirmationModalProps,
  CustomerEditorProps,
  CustomerReportEditorProps,
  ExpenseEditorProps,
  ProjectEditorProps,
  ProspectiveCustomerContactEditorProps,
  ProspectiveCustomerEditorProps,
  TodoEditorProps,
  UserEditorProps,
} from 'src/Hooks/useModals'
import { ConfirmationDialog } from '@e3dc-react/shell/Elements/Dialog'
import NoteEditor from './Notes/Editor'
import CustomerNoteEditor from './CustomerNotes/Editor'

export type ModalType =
  | Modal<TodoEditorProps, 'todoEditor'>
  | Modal<UserEditorProps, 'userEditor'>
  | Modal<CallEditorProps, 'callEditor'>
  | Modal<NoteEditorProps, 'noteEditor'>
  | Modal<CustomerNoteEditorProps, 'customerNoteEditor'>
  | Modal<ProspectiveCustomerEditorProps, 'prospectiveCustomerEditor'>
  | Modal<CustomerEditorProps, 'customerDataDisplay'>
  | Modal<CustomerEditorProps, 'customerEditor'>
  | Modal<CustomerReportEditorProps, 'customerReportEditor'>
  | Modal<ProspectiveCustomerContactEditorProps, 'prospectiveCustomerContactEditor'>
  | Modal<ProjectEditorProps, 'projectEditor'>
  | Modal<ExpenseEditorProps, 'expenseEditor'>
  | Modal<ConfirmationModalProps, 'confirmationModal'>
  | Modal<TranslationEditorProps, 'translationEditor'>
  | Modal<NoteEditorProps, 'noteEditor'>

export type ModalState = {
  readonly modals: ModalType[]
}

const ModalRoot = (): React.ReactElement => {
  const modals = useTypedSelector<ModalType[]>(state => state.modal.modals)

  return (
    <>
      {modals.map((modal, index) => (
        <Dialog key={index} place={index} modal={modal}></Dialog>
      ))}
    </>
  )
}

export default ModalRoot

interface DialogProps {
  place: number
  modal: ModalType
}

const Dialog: React.VFC<DialogProps> = ({ place, modal }) => {
  const dispatch = useDispatch()

  switch (modal.type) {
    case 'translationEditor':
      return <TranslationEditor {...modal} onClose={() => dispatch(removeModal())} place={place} />
    case 'todoEditor': {
      //const todoEditorProps: TodoEditorProps = modal
      return <TodoEditor onClose={() => dispatch(removeModal())} place={place} {...modal} />
    }
    case 'userEditor':
      return <UserEditor onClose={() => dispatch(removeModal())} place={place} {...modal} />
    case 'callEditor':
      return <CallEditor onClose={() => dispatch(removeModal())} place={place} {...modal} />
    case 'customerNoteEditor':
      return <CustomerNoteEditor onClose={() => dispatch(removeModal())} place={place} {...modal} />
    case 'expenseEditor':
      return <ExpenseEditor onClose={() => dispatch(removeModal())} place={place} {...modal} />
    case 'customerDataDisplay':
      if (modal.customerId && modal.customerId.startsWith('I'))
        return <ProspectiveCustomerDisplay onClose={() => dispatch(removeModal())} place={place} {...modal} />
      return <CustomerDisplay onClose={() => dispatch(removeModal())} place={place} {...modal} />
    case 'prospectiveCustomerEditor':
      return <ProspectiveCustomerEditor onClose={() => dispatch(removeModal())} place={place} {...modal} />
    case 'prospectiveCustomerContactEditor':
      return <ContactEditor onClose={() => dispatch(removeModal())} place={place} {...modal} />
    case 'customerEditor':
      return <CustomerEditor onClose={() => dispatch(removeModal())} place={place} {...modal} />
    case 'customerReportEditor':
      return <CustomerReportEditor onClose={() => dispatch(removeModal())} place={place} {...modal} />
    case 'projectEditor':
      return <ProjectsEditor onClose={() => dispatch(removeModal())} place={place} {...modal} />
    case 'noteEditor':
      return <NoteEditor onClose={() => dispatch(removeModal())} place={place} {...modal} />
    case 'confirmationModal':
      if (modal.text && modal.title) {
        return <ConfirmationDialog onClose={() => dispatch(removeModal())} {...modal} />
      } else {
        return null
      }

    default:
      return null
  }
}
