import React, { useState, useEffect, ReactElement } from 'react'

import Typography from '@mui/material/Typography'
import { Grid, IconButton } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import { Loading } from '@e3dc-react/shell/Elements/Loading'
import { objectApiResult } from '@e3dc-react/shell/Libs/ApiHelpers'
import { useAuthFetchWithResult } from '@e3dc-react/shell/Hooks/AuthFetch'
import { compareFunctionByAttribute } from '@e3dc-react/shell/Libs/SortAlgorithms'

import { Select } from '@e3dc-react/shell/Elements/Select'
import useModals from '../../Hooks/useModals'
import { LabeledOption } from 'src/Types/globalTypes'
import { UserModel, UserFetchModel } from 'src/Routes/Users/UserModel'
import { assertIsNumber } from 'src/Libs/assertions'
import { useCustomTranslation } from '@e3dc-react/shell/Hooks/useCustomTranslation'
import { J2Launchapp } from '@e3dc-react/icons'
const useStyles = makeStyles()(theme => ({
  select: {
    flex: 1,
  },
}))

interface UserSelectProps<B, T> {
  error?: string
  onSelect: (user: T) => void
  value?: T
  label?: string
  disabled?: boolean
  withRedirect?: boolean
  userShorthand?: B
  clearable?: boolean
}

/**
 * if userShorthand is true, type of value is string.
 * if userShorthand is false, type of value is number.
 */
function UserSelect(props: UserSelectProps<true, string>): ReactElement
function UserSelect(props: UserSelectProps<false, number>): ReactElement
function UserSelect({
  onSelect,
  error,
  value,
  label,
  disabled,
  clearable = true,
  withRedirect = true,
  userShorthand = false,
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
UserSelectProps<boolean, any>): ReactElement {
  const { classes } = useStyles()
  const [users, setUsers] = useState<LabeledOption<string | number>[]>([])
  const [selectedUser, setSelectedUser] = useState<string | number>()
  const [loading, setLoading] = useState(true)
  const authFetch = useAuthFetchWithResult()
  const { openUserEditor } = useModals()
  const { t } = useCustomTranslation()

  useEffect((): (() => void) | undefined => {
    let isMounted = true
    const fetchUsers = async (): Promise<void> => {
      const users: UserModel[] = objectApiResult(await authFetch<UserFetchModel>({ path: 'user-e3crm' }))
      if (isMounted) {
        const filteredUsers = users.filter(user => user.deleted_at === null).sort((userA, userB) => userA.first_name.localeCompare(userB.first_name))
        let userOptions
        if (userShorthand) {
          userOptions = filteredUsers
            .filter(user => user.sap_sales_staff !== '$ALL')
            .map(user => ({ label: user.first_name + ' ' + user.last_name, value: user.sap_sales_staff }))
          userOptions = [{ label: t('user.select.all'), value: '$All' }].concat(userOptions)
        } else {
          userOptions = filteredUsers.map(user => ({ label: user.first_name + ' ' + user.last_name, value: user.id }))
        }
        setUsers(userOptions)
        setLoading(false)
      }
    }

    fetchUsers()
    return () => (isMounted = false)
  }, []) // eslint-disable-line

  const getUser = (): number => {
    if (selectedUser) assertIsNumber(selectedUser)
    if (value) assertIsNumber(value)
    return selectedUser || value
  }

  const onUserChange = (user: string | number): void => {
    onSelect(user)
    setSelectedUser(user)
  }

  /**
   * returns sorted users list
   */
  const getSortedUsers = (): LabeledOption<string | number>[] => {
    return users.sort(compareFunctionByAttribute('ldapmail'))
  }

  return (
    <>
      {loading ? (
        <Loading text={t('user.select.loading')} />
      ) : users.length === 0 ? (
        <Typography variant="h6">{t('user.select.noUsers')}</Typography>
      ) : (
        <Grid container alignItems="center">
          <div className={classes.select}>
            <Select
              label={label}
              value={value}
              onChange={onUserChange}
              error={error}
              options={getSortedUsers()}
              disabled={disabled}
              menuShouldBlockScroll={false}
            />
          </div>
          {withRedirect && (
            <IconButton onClick={() => (getUser() ? openUserEditor({ userId: getUser() }) : {})} size="large">
              <J2Launchapp />
            </IconButton>
          )}
        </Grid>
      )}
    </>
  )
}

export default UserSelect
