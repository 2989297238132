import React, { useState, useEffect, useMemo } from 'react'
import Joi from 'joi'

import { DateTimePicker } from '@e3dc-react/shell/Elements/InputFields'

import { Grid, Typography } from '@mui/material'
import { Dialog } from '@e3dc-react/shell/Elements/Dialog'

import Button from '@mui/material/Button'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'

import ExpensesTotal from './ExpensesTotal'
import { expenseCountryToName } from '../../Libs/ExpenseCountry'
import { Loading } from '@e3dc-react/shell/Elements/Loading'
import { useValidation } from '@e3dc-react/shell/Hooks/useValidation/useValidation'
import { useAuthFetchWithResult } from '@e3dc-react/shell/Hooks/AuthFetch'
import { parseDate } from '@e3dc-react/shell/Libs/ApiHelpers'
import { CheckboxField } from '@e3dc-react/shell/Elements/InputFields'
import useFetch, { useFetchApiObject } from '@e3dc-react/shell/Hooks/useFetch'
import { ErrorSkeleton } from '@e3dc-react/shell/Elements/Error'
import { Select } from '@e3dc-react/shell/Elements/Select'
import useCustomers from 'src/Hooks/useCustomers'
import { ExpenseFetchedModel, ExpenseModel } from './ExpensesModel'
import { ExpenseCountryModel } from './ExpensesModel'
import { assertIsDate, assertIsString } from 'src/Libs/assertions'
import moment, { Moment } from 'moment'
import { CustomerReportModel } from '../CustomerReport/CustomerReportModel'
import { LabeledOption } from 'src/Types/globalTypes'
import { useCustomTranslation } from '@e3dc-react/shell/Hooks/useCustomTranslation'
import { J2Add, J2Subtract } from '@e3dc-react/icons'

const stateSchema = Joi.object().keys({
  customer_reports_id: Joi.array().items(Joi.number().min(1)).min(1).required(),
  start: Joi.date().required(),
  end: Joi.date().required(),
  expense_country_id: Joi.string().required(),
  hasDined: Joi.required()
    .when('has_breakfast', { is: 1, then: Joi.any().optional() })
    .when('has_lunch', { is: 1, then: Joi.any().optional() })
    .when('has_supper', { is: 1, then: Joi.any().optional() })
    .when('without_meals', { is: 1, then: Joi.any().optional() }),
})

interface ExpensesEditorProps {
  expense?: ExpenseModel
  onClose: () => void
  place: number
}

const Editor = ({ onClose, expense, place }: ExpensesEditorProps): React.ReactElement => {
  const { t } = useCustomTranslation()
  const [selectedCountry, setSelectedCountry] = useState('DE')
  const [selectedCustomerReport, setSelectedCustomerReport] = useState(0)
  const [customers, customersLoading, customersError] = useCustomers()

  // Todo: extra Typ "EditableExpenseModel" erstellen - ExpenseModel wird aktuell sowieso schon konvertiert, dann existiert das Problem mit Date nicht mehr
  const [selectedExpense, setSelectedExpense] = useState<ExpenseModel>({
    start: new Date(),
    end: new Date(),
    customer_reports_id: [],
    expense_country_id: 'DE08',
    has_breakfast: 0,
    has_lunch: 0,
    has_supper: 0,
    without_meals: 0,
  })

  const [countries, countriesLoading, countriesError] = useFetchApiObject<ExpenseCountryModel[]>({
    url: 'expenses/countries',
  })
  const [count, countLoading, countError] = useFetch<{ count: number }>({ url: 'customer-reports/stats' })
  const payload = useMemo(
    () => ({
      limit: count?.count,
      fields: 'id,date,sapCustomerId,expense_application_id',
      sort: '-date',
    }),
    [count]
  )
  const [customerReports, customerReportsLoading, customerReportsError] = useFetchApiObject<CustomerReportModel[]>({
    url: 'customer-reports',
    payload,
  })

  const validation = useValidation(selectedExpense, stateSchema)
  const authFetch = useAuthFetchWithResult()

  /**
   * Get label/name for customer id
   */
  const getCustomerLabel = (customerId: number): string => {
    const sapCustomerId = customerReports?.find(c => c.id === customerId)?.sapCustomerId
    const customer = customers?.find(customer => customer.Kundennr === sapCustomerId)

    return customer ? customer.Kundenname : sapCustomerId ? sapCustomerId : ''
  }

  const reportsOptions = useMemo(
    () =>
      customerReports
        ?.filter(c => c.expense_application_id === null)
        .filter(c => selectedExpense.customer_reports_id?.indexOf(c.id) === -1)
        .map(c => ({
          value: c.id,
          label: c.date ? parseDate(c.date)!.toLocaleDateString() : c.date + ' - ' + getCustomerLabel(c.id),
        })),
    [customerReports, selectedExpense.customer_reports_id] //eslint-disable-line
  )

  const countryOptions: LabeledOption<string>[] | undefined = useMemo(
    () =>
      countries
        ?.map(c => c.country)
        .filter((c, i) => countries.map(c => c.country).indexOf(c) === i)
        .sort()
        .reverse()
        .map(c => ({
          label: expenseCountryToName(c),
          value: c,
        })),
    [countries]
  )

  const addCustomerReport = (id: number): void => {
    if (!selectedExpense.customer_reports_id || !customerReports) return
    const customerReportIds = selectedExpense.customer_reports_id.concat(id)
    const customerReportsUsed = customerReports
      .filter(c => c.id && customerReportIds.includes(c.id))
      .map(c => c.date)
      .sort()
    setSelectedCustomerReport(0)
    setSelectedExpense({
      ...selectedExpense,
      ...{
        customer_reports_id: selectedExpense.customer_reports_id.concat(id),
        start: parseDate(customerReportsUsed[0])!,
        end: parseDate(customerReportsUsed[customerReportsUsed.length - 1])!,
      },
    })
  }

  const removeCustomerReport = (id: number): void => {
    const customer_reports_id = selectedExpense.customer_reports_id.slice(0)
    customer_reports_id.splice(customer_reports_id.indexOf(id), 1)
    handleInput('customer_reports_id', customer_reports_id)
  }

  const save = async (): Promise<void> => {
    if (expense && expense.id) {
      await authFetch({ path: 'expenses/applications/' + expense.id, method: 'PUT', payload: selectedExpense })
    } else {
      await authFetch({ path: 'expenses/applications', method: 'POST', payload: selectedExpense })
    }
    onClose()
  }

  const confirm = async (): Promise<void> => {
    if (!expense) return
    await authFetch({ path: 'expenses/applications/' + expense.id + '/confirm', method: 'PUT' })
    onClose()
  }

  const handleInput = (name: string, val: string | number | number[] | Date): void => {
    if (name === 'expense_country_id') {
      assertIsString(val)
      setSelectedCountry(val)
      setSelectedExpense({
        ...selectedExpense,
        ...{
          [name]:
            Math.abs(selectedExpense.start.getUTCMilliseconds() - selectedExpense.end.getUTCMilliseconds()) > 86400000 - 61000
              ? val + '24'
              : val + '08',
        },
      })
    } else if (name === 'start' || name === 'end') {
      assertIsDate(val)
      const startDate = name === 'start' ? val : selectedExpense.start
      const endDate = name === 'end' ? val : selectedExpense.end
      setSelectedExpense({
        ...selectedExpense,
        ...{ [name]: val },
        ...{
          expense_country_id: Math.abs(startDate.getTime() - endDate.getTime()) > 28800000 ? selectedCountry + '24' : selectedCountry + '08',
        },
      })
    } else {
      if (name === 'without_meals' && val === 1) {
        setSelectedExpense({ ...selectedExpense, ...{ [name]: val }, ...{ has_breakfast: 0 }, ...{ has_lunch: 0 }, ...{ has_supper: 0 } })
      } else {
        setSelectedExpense({ ...selectedExpense, ...{ [name]: val }, ...{ without_meals: 0 } })
      }
    }
  }

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      if (expense?.id) {
        const fetchedExpense = await authFetch<ExpenseFetchedModel>({ path: 'expenses/applications/' + expense.id + '?connect=customer_reports_id' })
        setSelectedCountry(fetchedExpense.expense_country_id.substr(0, 2))
        setSelectedExpense({
          ...fetchedExpense,
          start: parseDate(fetchedExpense.start)!,
          end: parseDate(fetchedExpense.end)!,
        })
      }
    }
    fetchData()
  }, [expense]) // eslint-disable-line

  const isLoading = customerReportsLoading || countriesLoading || customersLoading || countLoading || !customerReports
  const hasError = customerReportsError || countError || customersError
  return (
    <Dialog
      open={true}
      onClose={() => onClose()}
      confirmClose={true}
      title={'Spesenantrag ' + (!expense?.id ? 'anlegen' : 'bearbeiten')}
      place={place}
      actions={{
        primary: {
          name: t('common.save'),
          disabled: Boolean(hasError) || Object.keys(validation).length > 0,
          onClick: () => save(),
          isLoading: false, //todo,
        },
        secondary: [
          {
            name: t('common.cancel'),
          },
        ],
      }}
      additionalActionsLeft={
        <Button style={{ textAlign: 'left' }} onClick={confirm}>
          {t('expenses.approve')}
        </Button>
      }
    >
      {isLoading ? (
        <Loading />
      ) : hasError ? (
        <ErrorSkeleton title={t('errors.expenseError', { message: hasError.message })} type={'editor'} />
      ) : (
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6">{t('expenses.title.clientReports')}</Typography>
          </Grid>

          <Grid item xs={10}>
            <Select
              helperText={validation.customer_reports_id ? validation.customer_reports_id.message : undefined}
              label={t('expenses.clientReports')}
              value={selectedCustomerReport}
              onChange={(value: number) => setSelectedCustomerReport(value)}
              options={reportsOptions}
              noOptionsMessage={({ inputValue }: { inputValue: string }) =>
                customerReportsError ? t('errors.expenseError') : t('errors.noClientReports')
              }
            />
          </Grid>

          <Grid item xs={2}>
            <IconButton color="primary" size="small" disabled={!selectedCustomerReport} onClick={e => addCustomerReport(selectedCustomerReport)}>
              <J2Add />
            </IconButton>
          </Grid>

          {selectedExpense.customer_reports_id?.map(crId => (
            <React.Fragment key={crId}>
              <Grid item xs={10}>
                <Typography variant="body1">
                  {parseDate(customerReports?.find(c => c.id === crId)?.date ?? '')?.toLocaleDateString() ?? ''}
                  <br />
                  {getCustomerLabel(crId)}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <IconButton size="small" onClick={e => removeCustomerReport(crId)}>
                  <J2Subtract />
                </IconButton>
              </Grid>
            </React.Fragment>
          ))}

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6">{t('expenses.title.visitData')}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Select
              options={countryOptions}
              error={validation.expense_country_id}
              helperText={validation.expense_country_id ? validation.expense_country_id.message : false}
              label={t('common.country')}
              value={selectedCountry}
              onChange={(value: string) => handleInput('expense_country_id', value)}
              noOptionsMessage={({ inputValue }: { inputValue: string }) =>
                countriesError ? t('errors.noCountries', { message: countriesError.message }) : t('errors.countryNotFound')
              }
            />
          </Grid>
          <Grid item xs={6}>
            <DateTimePicker
              label={t('expenses.start')}
              value={moment(selectedExpense.start)}
              onChange={(date: Moment | null) => handleInput('start', date ? date.toDate() : '')}
              clearable={false}
            />
          </Grid>
          <Grid item xs={6}>
            <DateTimePicker
              label={t('expenses.end')}
              value={moment(selectedExpense.end)}
              onChange={(date: Moment | null) => handleInput('end', date ? date.toDate() : '')}
              clearable={false}
            />
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6">{t('expenses.catering')}</Typography>
            {validation.hasDined && (
              <Typography variant="caption" color="error">
                {t('expenses.warning.notEmpty')}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            <FormControl component="fieldset" style={{ marginTop: 0 }}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <CheckboxField
                      value={selectedExpense.has_breakfast === 1}
                      onChange={() => handleInput('has_breakfast', selectedExpense.has_breakfast === 1 ? 0 : 1)}
                    />
                  }
                  label={t('expenses.breakfast')}
                />
                <FormControlLabel
                  control={
                    <CheckboxField
                      value={selectedExpense.has_lunch === 1}
                      onChange={() => handleInput('has_lunch', selectedExpense.has_lunch === 1 ? 0 : 1)}
                    />
                  }
                  label={t('expenses.lunch')}
                />
                <FormControlLabel
                  control={
                    <CheckboxField
                      value={selectedExpense.has_supper === 1}
                      onChange={() => handleInput('has_supper', selectedExpense.has_supper === 1 ? 0 : 1)}
                    />
                  }
                  label={t('expenses.breakfast')}
                />
                <FormControlLabel
                  control={
                    <CheckboxField
                      value={selectedExpense.without_meals === 1}
                      onChange={() => handleInput('without_meals', selectedExpense.without_meals === 1 ? 0 : 1)}
                    />
                  }
                  label={t('expenses.noCatering')}
                />
              </FormGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6">{t('expenses.expenseAmount')}</Typography>
          </Grid>
          {countries && (
            <Grid item xs={12}>
              <Typography variant="body1" gutterBottom>
                {!countries.find(c => c.id === selectedExpense.expense_country_id) ? (
                  t('expenses.chooseCountryTime')
                ) : (
                  <span>
                    <ExpensesTotal country={countries.find(c => c.id === selectedExpense.expense_country_id)} expense={selectedExpense} />
                    &nbsp;&euro;
                  </span>
                )}
              </Typography>
            </Grid>
          )}
        </Grid>
      )}
    </Dialog>
  )
}

export default Editor
