// TODO: Disable type-check in production?

export function assertIsString(val: unknown): asserts val is string {
  if (typeof val !== 'string') {
    throw new TypeError('Not a string!')
  }
}

export function assertIsNumber(val: unknown): asserts val is number {
  if (typeof val !== 'number') {
    throw new TypeError('Not a number!')
  }
}

export function assertIsDate(val: unknown): asserts val is Date {
  if (!(val instanceof Date)) {
    throw new TypeError('Not a Date!')
  }
}

export function assertIsArray<T>(val: unknown, name: string): asserts val is T[] {
  if (!Array.isArray(val)) {
    throw new TypeError(`"${name}" should be an Array!`)
  }
}
