import React, { memo } from 'react'
import { Grid, Divider, Typography, Hidden } from '@mui/material'
import { MemoizedNumberField, MemoizedTextField } from '@e3dc-react/shell/Elements/InputFields'
import { AddButton, RemoveButton } from '@e3dc-react/shell/Elements/Buttons'
import { Select } from '@e3dc-react/shell/Elements/Select'
import { getArrayValidation } from '../../../Libs/validation.service'
import moment from 'moment'
import { deepEqual } from 'fast-equals'
import { makeStyles } from 'tss-react/mui'
import { PhotovoltaicSystemModel, ProjectModel } from '../ProjectModel'
import { LabeledOption, ValidationResult } from 'src/Types/globalTypes'

const useStyles = makeStyles()(theme => ({
  removeButton: {
    '& button': {
      marginTop: 0,
      marginBottom: theme.spacing(1),
    },
  },
  divider: {
    marginBottom: theme.spacing(2),
  },
}))

interface GenerationsProps {
  generation?: Partial<ProjectModel>
  validation: ValidationResult
  onGeneratorsChange: (name: string, value: unknown, index: number) => void
  onPVChange: (name: string, value: unknown, index: number) => void
  addPhotovoltaicSystem: (value: Partial<PhotovoltaicSystemModel>) => void
  removePhotovoltaicSystem: (index: number) => void
  addGenerator: () => void
  removeGenerator: (index: number) => void
  producerList: string[]
}

const Generation: React.VFC<GenerationsProps> = ({
  generation,
  validation,
  onGeneratorsChange,
  onPVChange,
  addPhotovoltaicSystem,
  removePhotovoltaicSystem,
  addGenerator,
  removeGenerator,
  producerList,
}) => {
  const { classes } = useStyles()

  if (!generation) return null

  const getPvSystemValidation = (index: number, type: string): string | undefined =>
    getArrayValidation(validation, 'photovoltaic_systems', index, type)
  const getGeneratorValidation = (index: number, type: string): string | undefined => getArrayValidation(validation, 'generators', index, type)

  const defaultPhotovoltaicSystemValues: Partial<PhotovoltaicSystemModel> = {
    orientation: 'Süd',
    commissioning_year: moment().year(),
    module_manufacturer: producerList[0],
    status: 'Neu',
  }

  if (!producerList) return null

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography>Photovoltaikanlagen</Typography>
      </Grid>
      <Grid item xs={12}>
        {generation.photovoltaic_systems?.map((pvSystem, index) => {
          return (
            <Grid container spacing={2} key={index}>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={3}>
                    <MemoizedTextField
                      helperText={getPvSystemValidation(index, 'type')}
                      label="Art der Erzeugung"
                      value={pvSystem.type || ''}
                      onChange={(value: string) => onPVChange('type', value, index)}
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <MemoizedNumberField
                      helperText={getPvSystemValidation(index, 'power')}
                      label="Leistung"
                      value={pvSystem.power}
                      onChange={value => onPVChange('power', value, index)}
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <MemoizedNumberField
                      helperText={getPvSystemValidation(index, 'yeld')}
                      label="Ertrag"
                      value={pvSystem.yeld}
                      onChange={value => onPVChange('yeld', value, index)}
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <Select
                      options={[
                        { label: 'Süd', value: 'Süd' },
                        { label: 'Süd-Ost', value: 'Süd-Ost' },
                        { label: 'Süd-West', value: 'Süd-West' },
                        { label: 'Ost', value: 'Ost' },
                        { label: 'Ost-West', value: 'Ost-West' },
                        { label: 'Nord', value: 'Nord' },
                        { label: 'Nord-Ost', value: 'Nord-Ost' },
                        { label: 'Nord-West', value: 'Nord-West' },
                      ]}
                      value={pvSystem.orientation ? pvSystem.orientation : 'Süd'}
                      onChange={(value: string) => onPVChange('orientation', value, index)}
                      label="Ausrichtung"
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <Select
                      options={[
                        { label: 'Neu', value: 'Neu' },
                        { label: 'Bestand', value: 'Bestand' },
                      ]}
                      value={pvSystem.status ? pvSystem.status : 'Neu'}
                      onChange={(value: string) => onPVChange('status', value, index)}
                      label="Status"
                    />
                  </Grid>
                  <Hidden mdDown>
                    <Grid item xs={12} md={1} className={classes.removeButton}>
                      <RemoveButton onClick={() => removePhotovoltaicSystem(index)}></RemoveButton>
                    </Grid>
                  </Hidden>
                  {pvSystem.status === 'Bestand' && (
                    <Grid item xs={12}>
                      <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12} md={4}>
                          <MemoizedNumberField
                            helperText={getPvSystemValidation(index, 'commissioning_year')}
                            label="Jahr der Inbetriebnahme"
                            value={pvSystem.commissioning_year || moment().year()}
                            onChange={value => onPVChange('commissioning_year', value, index)}
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Select
                            options={producerList.map<LabeledOption<string>>(option => ({ value: option, label: option }))}
                            onChange={(option: string) => onPVChange('module_manufacturer', option, index)}
                            value={pvSystem.module_manufacturer ? pvSystem.module_manufacturer : undefined}
                            label="Modulhersteller / Typ"
                            placeholder="Modulhersteller / Typ"
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <MemoizedTextField
                            helperText={getPvSystemValidation(index, 'inverter_manufacturer')}
                            label="Wechselrichterhersteller / Typ"
                            value={pvSystem.inverter_manufacturer || ''}
                            onChange={(value: string) => onPVChange('inverter_manufacturer', value, index)}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  )}

                  <Hidden mdUp>
                    <Grid item xs={12} className={classes.removeButton}>
                      <RemoveButton onClick={() => removePhotovoltaicSystem(index)}></RemoveButton>
                    </Grid>
                  </Hidden>
                </Grid>
              </Grid>
              {generation.photovoltaic_systems && index < generation.photovoltaic_systems.length - 1 && (
                <Grid item xs={12}>
                  <Divider variant="fullWidth" className={classes.divider} />
                </Grid>
              )}
            </Grid>
          )
        })}
      </Grid>
      <Grid item xs={12} style={{ textAlign: 'center' }}>
        <AddButton onClick={() => addPhotovoltaicSystem({ ...defaultPhotovoltaicSystemValues })}></AddButton>
      </Grid>
      <Grid item xs={12}>
        <Divider variant="fullWidth" />
      </Grid>

      <Grid item xs={12}>
        <Typography>Weitere Anlagen</Typography>
      </Grid>
      <Grid item xs={12}>
        {generation.generators?.map((generator, index) => {
          return (
            <Grid key={index} container spacing={2}>
              <Grid item xs={12} md={4}>
                <MemoizedTextField
                  helperText={getGeneratorValidation(index, 'name')}
                  label="Name"
                  value={generator.name || ''}
                  onChange={(value: string) => onGeneratorsChange('name', value, index)}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <MemoizedTextField
                  helperText={getGeneratorValidation(index, 'type')}
                  label="Typ"
                  value={generator.type || ''}
                  onChange={(value: string) => onGeneratorsChange('type', value, index)}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <MemoizedTextField
                  helperText={getGeneratorValidation(index, 'yeld')}
                  label="Ertrag/Jahr in kWh"
                  value={`${generator.yeld}` || ''}
                  onChange={(value: string) => onGeneratorsChange('yeld', value, index)}
                />
              </Grid>
              <Hidden mdDown>
                <Grid item md={1} className={classes.removeButton}>
                  <RemoveButton onClick={() => removeGenerator(index)}></RemoveButton>
                </Grid>
              </Hidden>
              <Grid item xs={12} md={6}>
                <Select
                  options={[
                    { label: 'Stromgeführt', value: 'Stromgeführt' },
                    { label: 'Wärmegeführt', value: 'Wärmegeführt' },
                  ]}
                  value={generator.operating_mode ? generator.operating_mode : undefined}
                  onChange={(value: string) => onGeneratorsChange('operating_mode', value, index)}
                  label="Betriebsart"
                  clearable={true}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Select
                  options={[
                    { label: 'Neu', value: 'Neu' },
                    { label: 'Bestand', value: 'Bestand' },
                  ]}
                  value={generator.status ? generator.status : 'Neu'}
                  onChange={(value: string) => onGeneratorsChange('status', value, index)}
                  label="Status"
                />
              </Grid>
              <Hidden mdUp>
                <Grid item xs={12} className={classes.removeButton}>
                  <RemoveButton onClick={() => removeGenerator(index)}></RemoveButton>
                </Grid>
              </Hidden>
              {generation.generators && index < generation.generators.length - 1 && (
                <Grid item xs={12}>
                  <Divider variant="fullWidth" className={classes.divider} />
                </Grid>
              )}
            </Grid>
          )
        })}
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <AddButton onClick={addGenerator}></AddButton>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default memo(
  Generation,
  (prevProps, nextProp) => deepEqual(prevProps.generation, nextProp.generation) && deepEqual(prevProps.validation, nextProp.validation)
)
