export const expenseCountryToName = (country: string): string => {
  switch (country) {
    case 'AT':
      return 'Österreich'
    case 'CH':
      return 'Schweiz'
    case 'CHG':
      return 'Schweiz ( Genf )'
    case 'DE':
      return 'Deutschland'
    case 'FR':
      return 'Frankreich'
    default:
      return 'Unbekanntes Land'
  }
}
