import { TextField } from '@e3dc-react/shell/Elements/InputFields'
import { useCustomTranslation } from '@e3dc-react/shell/Hooks/useCustomTranslation'
import { Grid } from '@mui/material'
import React from 'react'
import { Fields, FieldsValue } from 'src/Routes/CustomerReport/CustomerReportModel'
import { ValidationResult } from 'src/Types/globalTypes'

export interface NotesFieldsProps {
  fields: Fields
  handleInput: (name: keyof Fields, valueObject: FieldsValue) => void
  validation: ValidationResult
}

/**
 * Component to display fields for different topics of notes inside customer Reports
 *
 * @param fields - List of fields to be shown
 * @param handleInput - function to handle changes of input fields
 * @param validation - Validation object for input validation
 */
const NotesFields: React.VFC<NotesFieldsProps> = ({ fields, handleInput, validation }) => {
  const { t } = useCustomTranslation()

  return (
    <Grid container>
      {Object.keys(fields).map(item => {
        if (fields[item as keyof Fields]?.isHidden) return <></>

        return (
          <Grid key={item} item xs={12}>
            <TextField
              label={t(`customerReport.notesFields.${item}`)}
              value={fields[item as keyof Fields]?.value}
              multiline
              onChange={value => handleInput(item as keyof Fields, { ...fields[item as keyof Fields], value: value })}
              disabled={fields[item as keyof Fields]?.isDisabled}
              helperText={validation[`${item}.value`] ? validation[`${item}.value`].message : undefined}
            />
          </Grid>
        )
      })}
    </Grid>
  )
}

export default NotesFields
