import React from 'react'
import { AddButton } from '@e3dc-react/shell/Elements/Buttons'
import useModals from '../../../Hooks/useModals'
import { useCustomTranslation } from '@e3dc-react/shell/Hooks/useCustomTranslation'
const AddContactButton: React.VFC<{ prospectiveCustomerId: string }> = ({ prospectiveCustomerId }) => {
  const { openProspectiveCustomerContactEditor } = useModals()
  const { t } = useCustomTranslation()

  return (
    <AddButton onClick={() => openProspectiveCustomerContactEditor({ prospectiveCustomerId })} label={t('prospectiveCustomer.addContactButton')} />
  )
}

export default AddContactButton
