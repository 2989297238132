import React, { memo } from 'react'
import { Slider } from '@e3dc-react/shell/Elements/Slider'
import { useCustomTranslation } from '@e3dc-react/shell/Hooks/useCustomTranslation'
interface PotentialSliderProps {
  potential: number
  handleInput: (value?: number | number[]) => void
  disabled: boolean
}

/**
 * Slider component to set the potential.
 */
const PotentialSlider: React.VFC<PotentialSliderProps> = ({ potential, handleInput, disabled }) => {
  const { t } = useCustomTranslation()
  const sliderMarks = [
    t('customerReport.sliderMarks.0'),
    t('customerReport.sliderMarks.1'),
    t('customerReport.sliderMarks.2'),
    t('customerReport.sliderMarks.3'),
    t('customerReport.sliderMarks.4'),
  ]

  return (
    <Slider
      getAriaValueText={(v: number, index: number) => `${v}`}
      defaultValue={3}
      aria-labelledby="discrete-slider-always"
      step={1}
      min={1}
      max={5}
      marks={[{ value: 1 }, { value: 2 }, { value: 3 }, { value: 4 }, { value: 5 }]}
      valueLabelDisplay="on"
      onChange={(value?: number | number[]) => handleInput(value as number)}
      value={potential}
      valueLabelFormat={(value: number) => sliderMarks[value - 1]}
      minLabel={sliderMarks[0]}
      maxLabel={sliderMarks[4]}
      disabled={disabled}
      tooltipPosition={'top'}
      isOnModal={true}
    />
  )
}

export default memo(PotentialSlider, (prevProps, nextProp) => prevProps.potential === nextProp.potential)
