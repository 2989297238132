import { ProspectiveCustomerContactModel } from 'src/Elements/Customer/Contacts/CustomerContactModel'
import { CustomerModel } from 'src/Routes/CustomerData/CustomerModel'

export const getCustomerName = (customers: CustomerModel[] | undefined, customerId?: string): string => {
  const customer = getCustomer(customers, customerId)
  return customer ? customer.Kundenname : ''
}

export const getCustomerId = (customers: CustomerModel[], customerName: string): string | undefined => {
  const customer = customers.find(customer => customer.Kundenname === customerName)
  return customer ? customer.Kundennr : undefined
}

export const getCustomerContacts = (customers: CustomerModel[] | undefined, customerId?: string): ProspectiveCustomerContactModel[] | undefined => {
  const customer = getCustomer(customers, customerId)
  return customer ? customer.Kontakte : undefined
}

export const getCustomer = (customers: CustomerModel[] | undefined, customerId?: string): CustomerModel | undefined => {
  if (!customers || !customerId) return undefined
  return customers.find(customer => customer.Kundennr === customerId)
}
