import React, { memo } from 'react'
import { Grid } from '@mui/material'
import { MemoizedTextField } from '@e3dc-react/shell/Elements/InputFields'
import { shallowEqual } from 'fast-equals'
import { ProjectModel } from '../ProjectModel'
import { ValidationResult } from 'src/Types/globalTypes'

interface NotesProps {
  notes?: Partial<ProjectModel>
  validation: ValidationResult
  onChange: (name: string, value: unknown) => void
}

const Notes: React.VFC<NotesProps> = ({ notes, validation, onChange }) => {
  if (!notes) return null

  return (
    <Grid item xs={12}>
      <MemoizedTextField
        label="Anmerkungen"
        value={notes.notes}
        helperText={validation['notes'] ? validation['notes'].message : undefined}
        onChange={(value: string) => onChange('notes', value)}
        multiline={true}
      />
    </Grid>
  )
}

export default memo(
  Notes,
  (prevProps, nextProp) => shallowEqual(prevProps.notes, nextProp.notes) && shallowEqual(prevProps.validation, nextProp.validation)
)
