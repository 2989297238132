import React, { useState, useEffect, Context } from 'react'
import { useDispatch } from 'react-redux'

import { useAuthFetchWithResult } from '@e3dc-react/shell/Hooks/AuthFetch'
import { setPhoneCalls } from '../../State/TCSystem/TCSystemActions'
import { useAuthToken } from '@e3dc-react/shell/Hooks/AuthFetch'
import { useTypedSelector } from 'src/State/RootReducer'
import { TSEvent } from 'src/State/TCSystem/TCSystemModel'
import { CustomerDetailsModel, ProspectiveCustomerModel } from 'src/Routes/CustomerData/CustomerModel'
import { ProspectiveCustomerContact, CustomerContactModel } from '../Customer/Contacts/CustomerContactModel'

const defaultContext = {
  connected: false,
}
const TSConnectionContext = React.createContext(defaultContext)

interface SocketStateModel {
  ws?: WebSocket
  connected: boolean
  lastError?: string
  lastEvent?: TSEvent
}

const TSConnectionProvider: React.FC = ({ children }) => {
  const [getAuthToken] = useAuthToken()
  const dispatch = useDispatch()
  const authFetch = useAuthFetchWithResult()
  const phoneCalls = useTypedSelector(state => state.tcSystem.phoneCalls)
  const [socketState, setSocketState] = useState<SocketStateModel>({ connected: false })
  const decoded = useTypedSelector(state => state.user.userData)
  let ws: WebSocket | undefined = undefined

  const connectWebSocket = (): void => {
    ws = new WebSocket(process.env.REACT_APP_WS_ENDPOINT + '/tc-systems/tc-system', getAuthToken())

    ws.onmessage = async e => {
      const eventObj = JSON.parse(e.data)
      if (eventObj.ready) {
        setSocketState(s => ({ ...s, ws: ws, connected: true }))
        ws?.send(JSON.stringify({ getLine: decoded?.internal_phone_number }))
      } else {
        if (eventObj.state === 'OFFERING' && eventObj.customer) {
          let customer = eventObj.customer
          customer.phone = eventObj.callerId
          if (customer.prospectiveContactId) {
            const fetchedCustomer = await authFetch<ProspectiveCustomerModel>({ path: 'prospective-customers/' + customer.prospectiveCustomerId })
            const fetchedContact = await authFetch<ProspectiveCustomerContact>({
              path: 'prospective-customers/contacts/' + customer.prospectiveContactId,
            })
            customer = {
              ...customer,
              phone: fetchedContact.phone,
              company: fetchedCustomer.company_name,
              contact: fetchedContact.first_name + ' ' + fetchedContact.last_name,
            }
          } else if (customer.prospectiveCustomerId) {
            const fetchedCustomer = await authFetch<ProspectiveCustomerModel>({ path: 'prospective-customers/' + customer.prospectiveCustomerId })
            customer = {
              ...customer,
              phone: fetchedCustomer.phone,
              company: fetchedCustomer.company_name,
              contact: undefined,
            }
          } else if (customer.sapContactId) {
            const fetchedContact = await authFetch<CustomerContactModel>({ path: 'customer-e3crm/customers/contact/' + customer.sapContactId })
            customer = {
              ...customer,
              phone: fetchedContact.Tel1,
              company: fetchedContact.CardName,
              contact: fetchedContact.Name,
            }
          } else if (customer.sapCustomerId) {
            const fetchedCustomer = await authFetch<CustomerDetailsModel>({ path: 'customer-e3crm/customers/details/' + customer.sapCustomerId })
            customer = {
              ...customer,
              phone: fetchedCustomer.Telefon,
              company: fetchedCustomer.Kundenname,
              contact: undefined,
            }
          }
          eventObj.customer = customer
        } else if (eventObj.state === 'OFFERING' && !eventObj.customer) {
          eventObj.customer = {}
        }
        if (eventObj.callerId && eventObj.callerId.length > 3 && eventObj.state === 'OFFERING') setSocketState(s => ({ ...s, lastEvent: eventObj }))
      }
    }

    ws.onclose = function (e) {
      console.log('Socket is closed. Reconnect will be attempted in 1 second.', e)
      setSocketState(s => ({ ...s, ...{ connected: false, lastError: e.code + ' - ' + (e.reason || 'no reason message') } }))
      setTimeout(() => connectWebSocket(), 1000)
    }

    ws.onerror = function (e) {
      console.error('Socket encountered error: ', e, 'Closing socket')
      ws?.close()
    }
  }

  useEffect(() => {
    if (decoded?.internal_phone_number) connectWebSocket()
  }, [decoded?.internal_phone_number]) // eslint-disable-line

  useEffect(() => {
    if (socketState.lastEvent?.line) {
      dispatch(setPhoneCalls(phoneCalls.concat([{ ...socketState.lastEvent, ...{ time: new Date() } }]).slice(Math.max(phoneCalls.length - 10))))
    }
  }, [socketState.lastEvent]) // eslint-disable-line

  return <TSConnectionContext.Provider value={socketState}>{children}</TSConnectionContext.Provider>
}

const useTSConnectionContext = (): Context<SocketStateModel> => {
  if (TSConnectionContext === undefined) {
    throw new Error('useCountDispatch must be used within a CountProvider')
  }

  return TSConnectionContext
}

export { TSConnectionProvider, useTSConnectionContext }
