import React, { useState, useEffect, ReactElement } from 'react'
import {
  TableHead,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Hidden,
  IconButton,
  Grid,
  Divider,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
} from '@mui/material'
import { stringSorting } from '@e3dc-react/shell/Libs/SortAlgorithms'
import { CallLink } from '@e3dc-react/shell/Elements/Links'
import { EditIconButton } from '@e3dc-react/shell/Elements/Buttons'
import { AccordionSkeleton } from '@e3dc-react/shell/Elements/Skeletons'
import useModals from '../../../Hooks/useModals'
import { objectApiResult } from '@e3dc-react/shell/Libs/ApiHelpers'
import { useAuthFetchWithResult } from '@e3dc-react/shell/Hooks/AuthFetch'
import { getCustomerContacts } from '../../../Libs/customer.service'
import { CollapsedDetailsList } from '@e3dc-react/shell/Elements/List'
import useCustomers from 'src/Hooks/useCustomers'
import { MailLink } from '@e3dc-react/shell/Elements/Links'
import { ErrorMessage, ErrorSkeleton } from '@e3dc-react/shell/Elements/Error'
import { ProspectiveCustomerContactModel } from './CustomerContactModel'
import { ProspectiveCustomerFetchModel, ProspectiveCustomerModel } from 'src/Routes/CustomerData/CustomerModel'
import { ListItem } from '@e3dc-react/shell/Elements/List/List'
import { useCustomTranslation } from '@e3dc-react/shell/Hooks/useCustomTranslation'
import { useTypedSelector } from 'src/State/RootReducer'
import { H1Mail, H1Phonecall, J1DownarrowD } from '@e3dc-react/icons'
import { AuthFetchError } from '@e3dc-react/shell/Types/api'

const Contacts: React.VFC<{ customerId: string }> = ({ customerId }) => {
  const { t } = useCustomTranslation()
  const { openProspectiveCustomerContactEditor, openCallEditor } = useModals()
  const [contacts, setContacts] = useState<ProspectiveCustomerContactModel[]>()
  const [customers, customersLoading, customersError] = useCustomers()
  const [contactsLoading, setContactsLoading] = useState(false)
  const [contactsError, setContactsError] = useState<{ message: string }>()
  const authFetch = useAuthFetchWithResult()
  const isProspectiveCustomer = customerId.startsWith('I')
  const refresh = useTypedSelector(state => state.apiView.refresh)

  const handleCall = ({ number, contactId }: { number?: string; contactId?: number }): void => {
    if (!number) return
    window.open('tel:' + number, '_self')
    openCallEditor({ sapCustomerId: customerId, sapContactId: `${contactId}` })
  }

  const getMappedContacts = (contacts?: ProspectiveCustomerContactModel[]): ListItem[] | null => {
    return contacts
      ? contacts.map(contact => ({
          primary: contact.Name,
          icon: (
            <Grid container spacing={2}>
              <Grid item>
                <IconButton
                  edge="end"
                  aria-label="call"
                  onClick={() => handleCall({ number: contact.Tel || contact.Mobil, contactId: contact.Kontaktnr })}
                  style={{ paddingRight: 6 }}
                  size="large"
                >
                  <H1Phonecall />
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton edge="end" aria-label="send email" onClick={() => window.open(`mailto:${contact.EMail}`)} size="large">
                  <H1Mail />
                </IconButton>
              </Grid>
            </Grid>
          ),
        }))
      : null
  }

  useEffect((): (() => void) => {
    let isMounted = true
    const loadContacts = async (): Promise<void> => {
      if (isProspectiveCustomer) {
        try {
          setContactsLoading(true)
          const contacts: ProspectiveCustomerModel[] = objectApiResult<ProspectiveCustomerFetchModel, ProspectiveCustomerModel[]>(
            await authFetch<ProspectiveCustomerFetchModel>({
              path: `prospective-customers/contacts?filter=prospective_customer_id=${customerId.replace('I', '')}`,
            })
          )

          if (isMounted)
            setContacts(
              contacts.map(contact => ({
                id: contact.id,
                Name: `${contact.first_name} ${contact.last_name}`,
                EMail: contact.email,
                Tel: contact.phone,
              }))
            )
        } catch (error) {
          const err = error as AuthFetchError
          setContactsError(err)
        } finally {
          setContactsLoading(false)
        }
      } else {
        if (isMounted && !customersError && customers) setContacts(getCustomerContacts(customers, customerId))
      }
    }

    loadContacts()
    return () => (isMounted = false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerId, customers, refresh])

  /**
   * returns error, no contacts list or normal contacts list
   */
  const getContactsBody = (): ReactElement => {
    if (contactsError) {
      return (
        <TableRow hover={true}>
          <TableCell colSpan={isProspectiveCustomer ? 5 : 4} align="center">
            <ErrorMessage title={t('errors.contactsError', { message: contactsError.message })} />
          </TableCell>
        </TableRow>
      )
    } else if (!contacts || contacts.length === 0) {
      return (
        <TableRow hover={true}>
          <TableCell colSpan={isProspectiveCustomer ? 5 : 4} align="center">
            {t('customers.contactsEmpty')}
          </TableCell>
        </TableRow>
      )
    }

    return (
      <>
        {contacts
          .filter(c => !/^[0-9]{4}/g.test(c.Name))
          .sort(stringSorting('desc', 'Name'))
          .map((contact, index) => (
            <TableRow key={index} hover={true}>
              <TableCell>{contact.Name}</TableCell>
              <TableCell>{contact.EMail ? <MailLink mailAddress={contact.EMail} /> : '-'}</TableCell>
              <TableCell>
                {contact.Tel ? (
                  <CallLink telNumber={contact.Tel} handleClick={number => handleCall({ number, contactId: contact.Kontaktnr })} />
                ) : (
                  '-'
                )}
              </TableCell>
              <TableCell>
                {contact.Mobil ? (
                  <CallLink telNumber={contact.Mobil} handleClick={number => handleCall({ number, contactId: contact.Kontaktnr })} />
                ) : (
                  '-'
                )}
              </TableCell>
              {isProspectiveCustomer && contact.id && (
                <TableCell align="right">
                  <EditIconButton onClick={() => openProspectiveCustomerContactEditor({ contactId: contact.id })} size="small" />
                </TableCell>
              )}
            </TableRow>
          ))}
      </>
    )
  }

  /**
   * returns error, no contacts list or normal contacts list
   */
  const getDocSendBody = (): React.ReactElement => {
    if (contactsError) {
      return (
        <TableRow hover={true}>
          <TableCell colSpan={isProspectiveCustomer ? 5 : 4} align="center">
            <ErrorMessage title={t('errors.contactsError', { message: contactsError.message })} />
          </TableCell>
        </TableRow>
      )
    } else if (!contacts || contacts.length === 0) {
      return (
        <TableRow hover={true}>
          <TableCell colSpan={isProspectiveCustomer ? 5 : 4} align="center">
            {t('customers.fileAdressEmpty')}
          </TableCell>
        </TableRow>
      )
    }

    return (
      <>
        {contacts
          .filter(c => /^[0-9]{4}/g.test(c.Name))
          .sort(stringSorting('desc', 'Name'))
          .map((contact, index) => (
            <TableRow key={index} hover={true}>
              <TableCell>
                {contact.Name.substr(contact.Name.indexOf(' ') + 1)} ({contact.Name.substr(0, contact.Name.indexOf(' '))})
              </TableCell>
              <TableCell>{contact.EMail ? <MailLink mailAddress={contact.EMail} /> : '-'}</TableCell>
            </TableRow>
          ))}
      </>
    )
  }

  if (contactsError) {
    return <ErrorSkeleton title={t('errors.contactsError', { message: contactsError?.message })} type={'accordionClosed'} />
  }

  if (customersError) {
    return <ErrorSkeleton title={t('errors.customersError', { message: customersError.message })} type={'accordionClosed'} />
  }

  if (contactsLoading || customersLoading) {
    return (
      <Hidden mdDown>
        <AccordionSkeleton open={false} />
      </Hidden>
    )
  }

  return (
    <>
      <Hidden mdDown>
        <Accordion square disabled={!contacts || contacts.length <= 0}>
          <AccordionSummary expandIcon={<J1DownarrowD />}>
            <Typography>{t('customers.title.documentDispatch')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container>
              <Grid item xs={12}>
                <Table size="small">
                  <TableHead>
                    <TableRow hover={true}>
                      <TableCell>{t('common.fullName')}</TableCell>
                      <TableCell>{t('common.mail')}</TableCell>
                      <TableCell>{t('common.phone')}</TableCell>
                      <TableCell>{t('common.mobile')}</TableCell>
                      {isProspectiveCustomer && <TableCell align="right"></TableCell>}
                    </TableRow>
                  </TableHead>
                  <TableBody>{getContactsBody()}</TableBody>
                </Table>
              </Grid>
              <Grid item xs={12} style={{ padding: '24px 0px' }}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Table size="small">
                  <TableHead>
                    <TableRow hover={true}>
                      <TableCell>{t('common.fullName')}</TableCell>
                      <TableCell>{t('common.mail')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>{getDocSendBody()}</TableBody>
                </Table>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Hidden>
      <Hidden mdUp>
        {contactsError ? (
          <ErrorSkeleton title={t('errors.contactsError')} type={'accordionClosed'} />
        ) : (
          <CollapsedDetailsList title="Kontakte" data={getMappedContacts(contacts)} />
        )}
      </Hidden>
    </>
  )
}

export default Contacts
