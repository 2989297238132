import { ErrorSkeleton } from '@e3dc-react/shell/Elements/Error'
import { CollapsedDetailsList } from '@e3dc-react/shell/Elements/List'
import { ListItem } from '@e3dc-react/shell/Elements/List/List'
import { AccordionSkeleton } from '@e3dc-react/shell/Elements/Skeletons'
import { useCustomTranslation } from '@e3dc-react/shell/Hooks/useCustomTranslation'
import { useFetchApiObject } from '@e3dc-react/shell/Hooks/useFetch'
import { parseDate } from '@e3dc-react/shell/Libs/ApiHelpers'
import React, { useMemo } from 'react'
import { CustomerNoteModel } from 'src/Elements/CustomerNotes/CustomerNoteModel'
import useModals from 'src/Hooks/useModals'
import { useUsers } from 'src/Hooks/useUsers'
import { getLocaleDateNoTime, getPrimaryText } from 'src/Libs/text.service'
import { getFullUserName } from 'src/Libs/user.service'
import { useTypedSelector } from 'src/State/RootReducer'

const CustomerNotes: React.VFC<{ customerId: string }> = ({ customerId }) => {
  const { t } = useCustomTranslation()
  const [users, usersLoading, usersError] = useUsers()
  const payload = useMemo(() => {
    return {
      sort: '-createdAt',
      filter: 'sapCustomerId=' + customerId,
    }
  }, [customerId])
  const refresh = useTypedSelector(state => state.apiView.refresh)
  const { openCustomerNoteEditor } = useModals()
  const [notes, notesLoading, notesError] = useFetchApiObject<CustomerNoteModel[]>({
    url: 'e3crm-notes/customers',
    payload: payload,
    dependencies: [customerId, refresh],
  })

  const getMappedNotes = (notes?: CustomerNoteModel[]): ListItem[] | null => {
    return notes
      ? notes.map(note => ({
          primary: getPrimaryText(note.title, 'Note', 50),
          secondary: `${getLocaleDateNoTime(parseDate(note.updatedAt))}  |  ${getFullUserName(users, note.userId)}`,
          onSelect: () => openCustomerNoteEditor({ noteId: note.id }),
        }))
      : null
  }

  if (usersLoading || (notesLoading && notes !== undefined)) return <AccordionSkeleton open={false} />
  if (usersError) return <ErrorSkeleton title={t('errors.usersError', { message: usersError.message })} type={'accordionClosed'} />
  if (notesError) return <ErrorSkeleton title={t('errors.userNotesError', { message: notesError.message })} type={'accordionClosed'} />

  return <CollapsedDetailsList title={t('customers.title.notes')} data={getMappedNotes(notes)} />
}

export default CustomerNotes
