import React, { useEffect } from 'react'
import { Grid } from '@mui/material'
import useFetch from '@e3dc-react/shell/Hooks/useFetch'

import CustomerReports from './CustomerReports/CustomerReports'
import CustomerTodos from './Todos/Todos'
import CustomerDetails from './Details/Details'
import Contacts from './Contacts/Contacts'
import { ApiListSkeleton } from '@e3dc-react/shell/Elements/Skeletons'
import CustomerNotes from './Notes/Notes'
import { useTypedSelector } from 'src/State/RootReducer'
import { CustomerDetailsModel } from 'src/Routes/CustomerData/CustomerModel'

interface CustomerProps {
  customerId?: string
  disabled?: boolean
  setCustomerAsInactive?: (isInactive: boolean) => void
}

const Customer: React.VFC<CustomerProps> = ({ customerId, setCustomerAsInactive }) => {
  const refresh = useTypedSelector(state => state.apiView.refresh)

  const [customer, loading, error] = useFetch<CustomerDetailsModel>({
    url: 'customer-e3crm/customers/details/' + customerId,
    dependencies: [customerId, refresh],
    doNotFetch: customerId === undefined,
  })

  useEffect(() => {
    if (customer && !customer.isActive) {
      /** Disable actions for inactive customer. */
      setCustomerAsInactive?.(true)
    }
  }, [customer, setCustomerAsInactive])

  if (!customerId) return <ApiListSkeleton />

  return (
    <Grid item xs={12}>
      <Grid container alignItems="center" spacing={1}>
        <Grid item xs={12}>
          <CustomerDetails customer={customer!} customerLoading={loading} customerError={error} />
        </Grid>
        <Grid item xs={12}>
          <Contacts customerId={customerId} />
        </Grid>
        <Grid item xs={12}>
          <CustomerNotes customerId={customerId} />
        </Grid>
        <Grid item xs={12}>
          <CustomerReports customerId={customerId} />
        </Grid>
        <Grid item xs={12}>
          <CustomerTodos customerId={customerId} />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Customer
