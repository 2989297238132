/**
 * returns a sliced text used as a list items primary text
 */
export const getPrimaryText = (text: string | undefined | null, alternativeText: string, length: number): string => {
  if (text) {
    return text.length > length ? text.slice(0, length) + '...' : text
  }
  return alternativeText
}

/**
 * returns a stringified date
 */
export const getLocaleDate = (date: Date | undefined): string | undefined => {
  if (date === undefined) return undefined
  return `${date.toLocaleDateString()} - ${date.toLocaleTimeString()}`
}

/**
 * returns a stringified date without time
 */
export const getLocaleDateNoTime = (date: Date | undefined): string | undefined => {
  if (date === undefined) return undefined
  return `${date.toLocaleDateString()}`
}
