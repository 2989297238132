import React from 'react'
import moment from 'moment'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'

import { EditButton } from '@e3dc-react/shell/Elements/Buttons'
import { CallLink } from '@e3dc-react/shell/Elements/Links'
import useModals from '../../../Hooks/useModals'
import { MailLink, ExternalLink } from '@e3dc-react/shell/Elements/Links'
import { ProspectiveCustomerModel } from 'src/Routes/CustomerData/CustomerModel'
import { useCustomTranslation } from '@e3dc-react/shell/Hooks/useCustomTranslation'
import { Table, TableBody, TableCell, TableRow } from '@mui/material'
import { ProspectiveCustomerPartnerQalified } from 'src/Libs/customer-data/types'
import { UserModel } from 'src/Routes/Users/UserModel'

interface ProspectiveCustomerDetailsProps {
  prospectiveCustomer: ProspectiveCustomerModel
  users: UserModel[] | undefined
  handleEditButton: () => void
}

const ProspectiveCustomerDetails: React.VFC<ProspectiveCustomerDetailsProps> = ({ prospectiveCustomer, users, handleEditButton }) => {
  const { openCallEditor } = useModals()
  const { t } = useCustomTranslation()

  const handleCall = (telNumber: string): void => {
    openCallEditor({ sapCustomerId: `I${prospectiveCustomer.sapCustomerId}` })
  }

  const getPartnerQalifiedOptionToDisplay = (option: ProspectiveCustomerPartnerQalified): string => {
    switch (option) {
      case 'yes': {
        return t('common.yes')
      }
      case 'no - to many products': {
        return t('prospectiveCustomer.qualifiesAsPotentialPartnerOptions.manyProducts')
      }
      case 'no - prices to high': {
        return t('prospectiveCustomer.qualifiesAsPotentialPartnerOptions.pricesToHigh')
      }
      case 'no - quality not assured': {
        return t('prospectiveCustomer.qualifiesAsPotentialPartnerOptions.qualityNotAssured')
      }
      case 'no - architect, planning office, etc.': {
        return t('prospectiveCustomer.qualifiesAsPotentialPartnerOptions.other')
      }
    }
  }

  const getSalesName = (key: number | null | undefined): string => {
    if (users) {
      const currentUser = users.find(user => user.id === key)

      if (currentUser) {
        return `${currentUser.first_name} ${currentUser.last_name}`.trim()
      }

      return ''
    }

    return ''
  }

  const getContent = (prospectiveCustomer: ProspectiveCustomerModel): { title: string; content: React.ReactNode }[] => [
    {
      title: t('common.phone'),
      content: prospectiveCustomer.phone ? <CallLink telNumber={prospectiveCustomer.phone} handleClick={handleCall} /> : '-',
    },
    { title: t('common.mail'), content: prospectiveCustomer.email ? <MailLink mailAddress={prospectiveCustomer.email} /> : '-' },
    { title: t('common.street'), content: prospectiveCustomer.company_address_street },
    { title: t('common.houseNumber'), content: prospectiveCustomer.company_address_num },
    { title: t('common.city'), content: prospectiveCustomer.company_address_place },
    { title: t('common.zip'), content: prospectiveCustomer.zipcode },
    { title: t('common.country'), content: prospectiveCustomer.country },
    {
      title: t('customerReport.employeeCount'),
      content: prospectiveCustomer.ProspectiveCustomersData?.employeeCount,
    },
    {
      title: t('common.salesRepresentative'),
      content: getSalesName(prospectiveCustomer.ProspectiveCustomersData?.externalSalesId),
    },
    {
      title: t('common.insideSalesRepresentative'),
      content: getSalesName(prospectiveCustomer.ProspectiveCustomersData?.internalSalesId),
    },
    { title: t('common.website'), content: prospectiveCustomer.website ? <ExternalLink target={prospectiveCustomer.website} /> : '-' },
    {
      title: t('prospectiveCustomer.letterSendAt'),
      content:
        prospectiveCustomer.ProspectiveCustomersData?.letterSendAt &&
        moment(prospectiveCustomer.ProspectiveCustomersData.letterSendAt).format(t('common.format.date')),
    },
    {
      title: t('prospectiveCustomer.talkedToAt'),
      content:
        prospectiveCustomer.ProspectiveCustomersData?.talkedAt &&
        moment(prospectiveCustomer.ProspectiveCustomersData.talkedAt).format(t('common.format.date')),
    },
    {
      title: t('prospectiveCustomer.contactMethod'),
      content:
        prospectiveCustomer.ProspectiveCustomersData?.contactMethod && t(`common.${prospectiveCustomer.ProspectiveCustomersData?.contactMethod}`),
    },
    {
      title: t('prospectiveCustomer.salesPotential'),
      content:
        (prospectiveCustomer.ProspectiveCustomersData?.potential || prospectiveCustomer.ProspectiveCustomersData?.potential === 0) &&
        t(`prospectiveCustomer.potential.${prospectiveCustomer.ProspectiveCustomersData.potential}`),
    },
    {
      title: t('prospectiveCustomer.qualifiesAsPotentialPartner'),
      content:
        prospectiveCustomer.ProspectiveCustomersData?.partnerQualified &&
        getPartnerQalifiedOptionToDisplay(prospectiveCustomer.ProspectiveCustomersData.partnerQualified),
    },
    {
      title: t('prospectiveCustomer.onboardingStatus'),
      content:
        prospectiveCustomer.ProspectiveCustomersData?.onboardStatus &&
        t(`prospectiveCustomer.onboardingStatuses.${prospectiveCustomer.ProspectiveCustomersData.onboardStatus}`),
    },
    { title: t('common.info'), content: prospectiveCustomer.note },
    {
      title: t('prospectiveCustomer.interestedInCertificationTraining'),
      content: prospectiveCustomer.ProspectiveCustomersData?.interestedInCertification ? t('common.yes') : t('common.no'),
    },
    {
      title: t('prospectiveCustomer.awareReason'),
      content: prospectiveCustomer.becomeAwareReason
        ? t(`prospectiveCustomer.awareReasons.${prospectiveCustomer.becomeAwareReason}`) + (prospectiveCustomer.becomeAwareText ?? '')
        : '-',
    },
    {
      title: t('prospectiveCustomer.registrationCertification'),
      content: prospectiveCustomer.registrationCertification ? t('common.yes') : t('common.no'),
    },
  ]

  return (
    <Grid container alignItems="center" spacing={1}>
      <Grid item xs={12}>
        <Grid container spacing={1} alignItems="center">
          {/* empty grid item to center the second item */}
          <Grid item xs={2} md={1} />
          <Grid item xs={8} md={10}>
            <Typography variant="h6">{prospectiveCustomer.company_name}</Typography>
          </Grid>
          <Grid item xs={2} md={1}>
            <EditButton onClick={handleEditButton} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      {prospectiveCustomer && (
        <>
          <Grid item xs={12}>
            <Typography variant="subtitle1">{t('prospectiveCustomer.detailsTitle')}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Table size="small">
              <TableBody>
                {getContent(prospectiveCustomer).map(item => (
                  <TableRow key={item.title} hover={true}>
                    <TableCell align="left">{item.title}</TableCell>
                    <TableCell align="right">{item.content}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
        </>
      )}
    </Grid>
  )
}

export default ProspectiveCustomerDetails
