import React from 'react'

import { PRIORITY_COLORS } from '../Customer/Todos/TodoModel'
import { makeStyles } from 'tss-react/mui'

interface StyleProps {
  color: string
}

const useStyles = makeStyles<StyleProps>()((theme, props) => ({
  statusIcon: {
    width: 16,
    height: 16,
    borderRadius: '100%',
    backgroundColor: props.color,
  },
}))

interface TodoPriorityProps {
  priority: number
  status?: string
}

const TodoPriority: React.VFC<TodoPriorityProps> = ({ priority, status }) => {
  const color = PRIORITY_COLORS[priority - 1] || 'black'
  const { classes } = useStyles({ color })
  return <div className={classes.statusIcon} />
}

export default TodoPriority
