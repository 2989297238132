import React from 'react'
import { Grid } from '@mui/material'
import ProspectiveCustomerDetails from './Details/Details'
import ProspectiveCustomerFiles from './Files/Files'

import CustomerReports from '../Customer/CustomerReports/CustomerReports'
import CustomerTodos from '../Customer/Todos/Todos'
import Contacts from '../Customer/Contacts/Contacts'
import CustomerNotes from '../Customer/Notes/Notes'

const ProspectiveCustomer: React.VFC<{ customerId: string }> = ({ customerId }) => {
  return (
    <Grid item xs={12} alignItems="center">
      <Grid container alignItems="center" spacing={1}>
        <Grid item xs={12}>
          <ProspectiveCustomerDetails customerId={customerId} />
        </Grid>
        <Grid item xs={12}>
          <ProspectiveCustomerFiles customerId={customerId} />
        </Grid>
        <Grid item xs={12}>
          <Contacts customerId={customerId} />
        </Grid>
        <Grid item xs={12}>
          <CustomerNotes customerId={customerId} />
        </Grid>
        <Grid item xs={12}>
          <CustomerReports customerId={customerId} />
        </Grid>
        <Grid item xs={12}>
          <CustomerTodos customerId={customerId} />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ProspectiveCustomer
