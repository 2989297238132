import React, { useEffect, useState } from 'react'

import { compareFunctionByAttribute } from '@e3dc-react/shell/Libs/SortAlgorithms'
import { Select } from '@e3dc-react/shell/Elements/Select'
import { useAuthFetchWithResult } from '@e3dc-react/shell/Hooks/AuthFetch'
import { objectApiResult } from '@e3dc-react/shell/Libs/ApiHelpers'
import { useTypedSelector } from 'src/State/RootReducer'
import { LabeledOption } from 'src/Types/globalTypes'
import { ProspectiveCustomerContact, ProspectiveCustomerContactFetched } from '../Contacts/CustomerContactModel'
import { useCustomTranslation } from '@e3dc-react/shell/Hooks/useCustomTranslation'
interface CustomerSelectContactProps {
  onSelect: (value: number) => void
  sapCustomerId: string
  value?: string
  disabled?: boolean
  error?: string
}

/**
 * Customer select component
 */
const CustomerSelectContact: React.VFC<CustomerSelectContactProps> = ({ onSelect, error, sapCustomerId, value, disabled }) => {
  const [contacts, setContacts] = useState<LabeledOption<number>[]>()
  const [selectedContact, setSelectedContact] = useState<number>()
  const { t } = useCustomTranslation()

  const authFetch = useAuthFetchWithResult()
  const customerContacts = useTypedSelector(state => state.customers.customers)
  useEffect(() => {
    /**
     * returns contact list of the customer
     */
    const getCustomerContacts = (): void => {
      const contacts =
        customerContacts
          ?.filter(customer => customer.Kundennr === sapCustomerId && customer)[0]
          ?.Kontakte.filter(contact => contact.Kontaktnr)
          .sort(compareFunctionByAttribute('Name'))
          .map(contact => ({ label: contact.Name, value: contact.Kontaktnr! })) ?? []
      setContacts(contacts)
    }

    /**
     * returns contact list of the prospective customer
     */
    const getProspectiveCustomerContacts = async (): Promise<void> => {
      const fetchedContacts = await authFetch<ProspectiveCustomerContactFetched>({
        path: 'prospective-customers/contacts?filter=prospective_customer_id=' + sapCustomerId.replace('I', ''),
      })
      const contacts: LabeledOption<number>[] = objectApiResult<ProspectiveCustomerContactFetched, ProspectiveCustomerContact[]>(fetchedContacts)
        .filter(contact => contact.id)
        .map((contact: ProspectiveCustomerContact) => ({
          label: `${contact.first_name} ${contact.last_name}`,
          value: contact.id!,
        }))
      setContacts(contacts)
    }

    if (sapCustomerId.startsWith('I')) {
      getProspectiveCustomerContacts()
    } else {
      if (customerContacts) {
        getCustomerContacts()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sapCustomerId, customerContacts])

  useEffect(() => {
    if (contacts && value) {
      setSelectedContact(contacts.find(contact => contact.value === parseInt(value, 0))?.value)
    }
  }, [contacts, value])

  return (
    <Select
      noOptionsMessage={({ inputValue }: { inputValue: string }) => 'Keine Kontakte vorhanden'}
      error={error}
      options={contacts}
      label={t('customers.selectContact.contactPerson')}
      onChange={(value: number) => onSelect(value)}
      value={selectedContact}
      disabled={disabled}
      isSearchable
    />
  )
}

export default CustomerSelectContact
