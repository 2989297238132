import { AvailableTheme, getDarkTheme, getLightTheme, ThemeObject } from '@e3dc-react/shell/Libs/muiTheme'

export function getThemes(): ThemeObject {
  return {
    themes: {
      light: getLightTheme({
        palette: {
          background: {
            default: '#eaeff1',
          },
        },
      }),
      dark: getDarkTheme(),
    },
    defaultTheme: (process.env.REACT_APP_THEME as AvailableTheme) || ('light' as AvailableTheme),
  }
}
