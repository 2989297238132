import React, { useState, useEffect } from 'react'
import { stringSorting } from '@e3dc-react/shell/Libs/SortAlgorithms'
import { Select } from '@e3dc-react/shell/Elements/Select'
import { IconButton } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import useModals from '../../../Hooks/useModals'
import useCustomers from 'src/Hooks/useCustomers'
import { FetchError, useFetchApiObject } from '@e3dc-react/shell/Hooks/useFetch'
import { useTypedSelector } from 'src/State/RootReducer'
import { CustomerModel, ProspectiveCustomerModel } from 'src/Routes/CustomerData/CustomerModel'
import { LabeledOption } from 'src/Types/globalTypes'
import { useCustomTranslation } from '@e3dc-react/shell/Hooks/useCustomTranslation'
import { J2Launchapp } from '@e3dc-react/icons'

const useStyles = makeStyles()(theme => ({
  selectWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  select: {
    flex: 1,
  },
}))

interface CustomerSelectProps {
  onSelect: (value: string) => void
  error?: string
  value?: string
  onlyProspectiveCustomers?: boolean
  onlyMyProspectiveCustomers?: boolean
  withRedirect?: boolean
  disabled?: boolean
  placeholder?: string
}
// Hook
/**
 * Customer select component
 */
const CustomerSelect: React.VFC<CustomerSelectProps> = ({
  onSelect,
  error,
  value,
  onlyProspectiveCustomers,
  onlyMyProspectiveCustomers,
  withRedirect,
  disabled,
  placeholder,
}) => {
  const { classes } = useStyles()
  const [customers, customersLoading, customersError] = useCustomers()
  const userData = useTypedSelector(state => state.user.userData)
  const { openCustomerDataDisplay } = useModals()
  const [prospectiveCustomers, prospectiveCustomersLoading, prospectiveCustomersError] = useFetchApiObject<ProspectiveCustomerModel[]>({
    url: 'prospective-customers',
    payload: undefined,
    dependencies: [value],
  })
  const [customerSelectList, setCustomerSelectList] = useState<LabeledOption<string>[]>([])
  const [selectedCustomer, setSelectedCustomer] = useState<string>()
  const { t } = useCustomTranslation()

  useEffect((): (() => void) | undefined => {
    if (!customers) return
    let isMounted = true
    if (value) {
      const selectedCustomer = customers.find(customer => customer.Kundennr === value.toString()) // todos, customer_contacts, and others pass sapCusomterId as string while notes pass it as integer
      if (isMounted && selectedCustomer) {
        setSelectedCustomer(selectedCustomer.Kundennr as string)
      }
    }
    let customerList =
      customers
        .filter(c => userData?.sap_sales_staff === '$ALL' || c.EmployeeShorthand === userData?.sap_sales_staff)
        .sort(stringSorting('desc', 'Kundenname')) ?? []

    // filter only prospective customers
    if (onlyProspectiveCustomers || onlyMyProspectiveCustomers) {
      customerList = customerList.filter(customer =>
        prospectiveCustomers?.find((prospectiveCustomer: ProspectiveCustomerModel) => {
          const isProspectiveCustomer = `I${prospectiveCustomer.id}` === customer.Kundennr
          if (!isProspectiveCustomer) return false
          if (onlyMyProspectiveCustomers) return prospectiveCustomer.user_id === userData?.id
          return true
        })
      )
    }

    const customerSelectList = customerList.map(customer => {
      const isProspectiveCustomer = prospectiveCustomers?.find((prospectiveCustomer: ProspectiveCustomerModel) => {
        const isSame = `I${prospectiveCustomer.id}` === customer.Kundennr
        return isSame
      })

      const label = isProspectiveCustomer ? t('customers.prospectiveCustomerName', { name: customer.Kundenname }) : customer.Kundenname
      return { label: label, value: customer.Kundennr }
    })
    if (isMounted) setCustomerSelectList(customerSelectList)

    return () => (isMounted = false)
  }, [customers, prospectiveCustomers, onlyProspectiveCustomers, onlyMyProspectiveCustomers, userData, value]) //eslint-disable-line

  const getNoOptionsMessage = (
    customersLoading: boolean,
    prospectiveCustomersLoading: boolean,
    customers?: CustomerModel[],
    customersError?: FetchError,
    prospectiveCustomersError?: FetchError
  ): string | null => {
    if (customersLoading || prospectiveCustomersLoading) return t('customers.select.loading')
    if (customersError || prospectiveCustomersError) return t('customers.select.error')
    if (customers && customers.length === 0) return t('customers.select.empty')
    return null
  }

  return (
    <div className={classes.selectWrapper}>
      <div className={classes.select}>
        <Select
          noOptionsMessage={({ inputValue }: { inputValue: string }) =>
            getNoOptionsMessage(customersLoading, prospectiveCustomersLoading, customers, customersError, prospectiveCustomersError)
          }
          error={error}
          options={customerSelectList}
          placeholder={placeholder ?? t('customers.select.placeholder')}
          onChange={(value: string) => onSelect(value)}
          value={selectedCustomer}
          disabled={disabled}
          isSearchable
          menuShouldBlockScroll={false}
        />
      </div>
      {withRedirect && (
        <IconButton
          onClick={() => (selectedCustomer ? openCustomerDataDisplay({ customerId: selectedCustomer }) : {})}
          disabled={!selectedCustomer}
          size="large"
        >
          <J2Launchapp />
        </IconButton>
      )}
    </div>
  )
}

export default CustomerSelect
