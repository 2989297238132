import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Grid } from '@mui/material'
import { useAuthFetchWithResult } from '@e3dc-react/shell/Hooks/AuthFetch'
import Joi from 'joi'
import { useValidation } from '@e3dc-react/shell/Hooks/useValidation/useValidation'
import { MemoizedNumberField, MemoizedTextField } from '@e3dc-react/shell/Elements/InputFields'
import { Dialog } from '@e3dc-react/shell/Elements/Dialog'
import { refreshApiView } from '@e3dc-react/shell/State/ApiView/ApiViewActions'
import useFetch from '@e3dc-react/shell/Hooks/useFetch'
import { EditorSkeleton } from '@e3dc-react/shell/Elements/Skeletons'
import { ErrorSkeleton } from '@e3dc-react/shell/Elements/Error'
import { useTypedSelector } from 'src/State/RootReducer'
import { CurrentProspectiveCustomerContact, ProspectiveCustomerContact } from 'src/Elements/Customer/Contacts/CustomerContactModel'
import { useCustomTranslation } from '@e3dc-react/shell/Hooks/useCustomTranslation'
import { shallowEqual } from 'fast-equals'
const prospectiveCustomerContactSchema = Joi.object().keys({
  id: Joi.number().allow(null),
  prospective_customer_id: Joi.number(),
  user_id: Joi.number(),
  sapCustomerId: Joi.string().allow(null, ''),
  street: Joi.string().allow(null, ''),
  num: Joi.alternatives(Joi.string(), Joi.number()).allow(null),
  place: Joi.string().allow(null, ''),
  zipcode: Joi.number().allow(null),
  country: Joi.string().required(),
  first_name: Joi.string(),
  last_name: Joi.string(),
  email: Joi.string().email({ minDomainSegments: 2, tlds: { allow: false } }),
  phone: Joi.string(),
  fax: Joi.string().allow(null, ''),
  note: Joi.string().allow(null, ''),
  created_user_id: Joi.number().allow(null),
  created_at: Joi.date().allow(null, ''),
})

interface ContactEditorProps {
  contactId?: number
  prospectiveCustomerId?: string
  place: number
  onClose: () => void
}
const ContactEditor: React.VFC<ContactEditorProps> = ({ contactId, prospectiveCustomerId, onClose, place }) => {
  const { t } = useCustomTranslation()
  const userId = useTypedSelector(state => state.auth.decoded?.userId)
  const dispatch = useDispatch()
  const defaultState: CurrentProspectiveCustomerContact = {
    prospective_customer_id: prospectiveCustomerId ? Number(prospectiveCustomerId.substring(1)) : undefined,
    user_id: userId,
    sapCustomerId: '',
    street: '',
    place: '',
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    fax: '',
    note: '',
    created_user_id: userId,
    country: '',
  }
  const [contact, setContact] = useState<CurrentProspectiveCustomerContact>(defaultState)
  const [isSaving, setIsSaving] = useState(false)
  const authFetch = useAuthFetchWithResult()
  const [validationActive, setValidationActive] = useState(false)
  const [fetchedContact, contactLoading, contactError] = useFetch<ProspectiveCustomerContact>({
    url: 'prospective-customers/contacts/' + contactId,
    dependencies: [contactId],
    doNotFetch: contactId === undefined,
  })
  const validationObject = useValidation(contact, prospectiveCustomerContactSchema)

  const saveProspectiveCustomerContact = async (): Promise<void> => {
    setIsSaving(true)
    // if save button clicked for the first time activate the validation
    if (!validationActive) setValidationActive(true)
    // if validation fails do not save
    if (Object.keys(validationObject).length > 0) return

    const payload = {
      ...contact,
      zipcode: `${contact.zipcode ?? ''}`,
    }

    if (contactId) {
      await authFetch({ path: 'prospective-customers/contacts/' + contactId, method: 'PUT', payload })
    } else {
      await authFetch({ path: 'prospective-customers/contacts', method: 'POST', payload })
    }

    setIsSaving(false)
    onClose()
    dispatch(refreshApiView())
  }

  useEffect(() => {
    if (fetchedContact) {
      const { created_at, zipcode, ...restFetchedContact } = fetchedContact

      setContact({ ...restFetchedContact, created_at: created_at ? new Date(created_at) : undefined, zipcode: Number(zipcode) || undefined })
    }
  }, [fetchedContact])

  /**
   * update contact id
   */
  useEffect(() => {
    if (prospectiveCustomerId) {
      setContact(contact => ({ ...contact, prospective_customer_id: Number(prospectiveCustomerId.substring(1)) }))
    }
  }, [prospectiveCustomerId])

  const handleInput = (name: string, value: unknown): void => {
    setContact({ ...contact, ...{ [name]: value } })
  }

  const validation = validationActive ? validationObject : {}

  return (
    <Dialog
      open={true}
      onClose={() => onClose()}
      confirmClose={!shallowEqual(contact, contactId ? fetchedContact : defaultState)}
      title={'Interessenten Ansprechpartner ' + (contactId ? 'bearbeiten' : 'einfügen')}
      place={place}
      actions={{
        primary: {
          name: t('common.save'),
          disabled: validationActive && Object.keys(validationObject).length > 0,
          onClick: () => saveProspectiveCustomerContact(),
          isLoading: isSaving,
        },
        secondary: [
          {
            name: t('common.cancel'),
          },
        ],
      }}
    >
      {contactId && contactError ? (
        <ErrorSkeleton title={t('errors.contactsError')} type={'editor'} />
      ) : contactLoading ? (
        <EditorSkeleton />
      ) : (
        <Grid container alignItems="center" style={{ marginTop: '0' }} spacing={2}>
          <Grid item xs={12} sm={6}>
            <MemoizedTextField
              helperText={validation.first_name ? validation.first_name.message : false}
              label={t('common.name')}
              value={contact.first_name}
              required
              onChange={(value: string) => handleInput('first_name', value)}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <MemoizedTextField
              helperText={validation.last_name ? validation.last_name.message : false}
              label={t('common.lastName')}
              value={contact.last_name}
              required
              onChange={(value: string) => handleInput('last_name', value)}
            />
          </Grid>
          <Grid item sm={8} xs={12}>
            <MemoizedTextField
              helperText={validation.street ? validation.street.message : false}
              label={t('common.street')}
              value={contact.street}
              onChange={(value: string) => handleInput('street', value)}
            />
          </Grid>
          <Grid item sm={4} xs={12}>
            <MemoizedTextField
              helperText={validation.num ? validation.num.message : false}
              label={t('common.houseNumber')}
              value={contact.num}
              onChange={(value: string) => handleInput('num', value)}
            />
          </Grid>
          <Grid item sm={8} xs={12}>
            <MemoizedTextField
              helperText={validation.place ? validation.place.message : false}
              label={t('common.city')}
              value={contact.place}
              onChange={(value: string) => handleInput('place', value)}
            />
          </Grid>
          <Grid item sm={4} xs={12}>
            <MemoizedNumberField
              helperText={validation.zipcode ? validation.zipcode.message : false}
              label={t('common.zip')}
              value={contact.zipcode}
              onChange={value => handleInput('zipcode', value)}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <MemoizedTextField
              helperText={validation.country ? validation.country.message : false}
              label={t('common.country')}
              value={contact.country}
              onChange={(value: string) => handleInput('country', value)}
              required
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <MemoizedTextField
              helperText={validation.email ? validation.email.message : false}
              label={t('common.mail')}
              value={contact.email}
              required
              onChange={(value: string) => handleInput('email', value)}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <MemoizedTextField
              helperText={validation.phone ? validation.phone.message : false}
              label={t('common.phone')}
              value={contact.phone}
              required
              onChange={(value: string) => handleInput('phone', value)}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <MemoizedTextField
              helperText={validation.fax ? validation.fax.message : false}
              label={t('common.fax')}
              value={contact.fax}
              onChange={(value: string) => handleInput('fax', value)}
            />
          </Grid>
          <Grid item xs={12}>
            <MemoizedTextField
              helperText={validation.note ? validation.note.message : false}
              label={t('common.info')}
              value={contact.note}
              multiline={true}
              onChange={(value: string) => handleInput('note', value)}
            />
          </Grid>
        </Grid>
      )}
    </Dialog>
  )
}

export default ContactEditor
