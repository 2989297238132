import { useEffect, useState } from 'react'

import { useAuthFetchWithResult } from '@e3dc-react/shell/Hooks/AuthFetch'
import { customers_set } from '../State/Customers/CustomersActions'

import { useDispatch } from 'react-redux'
import { deepEqual } from 'fast-equals'
import { FetchError } from '@e3dc-react/shell/Hooks/useFetch'
import { useTypedSelector } from 'src/State/RootReducer'
import { CustomerModel } from 'src/Routes/CustomerData/CustomerModel'

/**
 *
 */
const useCustomers = (): [CustomerModel[] | undefined, boolean, FetchError | undefined] => {
  //TOdo Type is wrong?!
  const dispatch = useDispatch()
  const customers = useTypedSelector(state => state.customers.customers)
  const [isLoading, setIsLoading] = useState(Boolean(!customers))
  const [error, setError] = useState<FetchError>()
  const authFetch = useAuthFetchWithResult()

  useEffect(() => {
    const getCustomers = async (): Promise<void> => {
      // @todo check only if expired
      try {
        if (!customers) setIsLoading(true)
        const fetchedCustomers = await authFetch<CustomerModel[]>({ path: 'customer-e3crm/customers/all' })

        if (!deepEqual(customers, fetchedCustomers)) {
          dispatch(customers_set(fetchedCustomers))
        }
      } catch (error) {
        const err = error as FetchError
        console.error(err)
        setError(err)
      } finally {
        setIsLoading(false)
      }
    }

    getCustomers()
  }, []) //eslint-disable-line

  return [customers, isLoading, error]
}

export default useCustomers
