import React from 'react'
import moment from 'moment'
import { ExpenseCountryModel, ExpenseModel } from './ExpensesModel'
import { ErrorMessage } from '@e3dc-react/shell/Elements/Error'
import { useCustomTranslation } from '@e3dc-react/shell/Hooks/useCustomTranslation'

interface ExpensesTotalProps {
  country?: ExpenseCountryModel
  expense?: ExpenseModel
}

/**
 * get expense money value
 */
const ExpensesTotal = ({ country, expense }: ExpensesTotalProps): React.ReactElement => {
  const { t } = useCustomTranslation()

  if (!country) return <ErrorMessage title={t('expenses.warning.noCountry')} />
  if (!expense) return <ErrorMessage title={t('expenses.warning.noExpense')} />
  const startDate = moment(expense.start).endOf('day')
  const endDate = moment(expense.end).startOf('day')
  const days = endDate.diff(startDate, 'days') + 1
  const perDayExpense =
    country.complete +
    (expense.has_breakfast ? country.breakfast : 0) +
    (expense.has_lunch ? country.lunch : 0) +
    (expense.has_supper ? country.supper : 0)
  return <>{(perDayExpense * days).toFixed(2)}</>
}

export default ExpensesTotal
