import React from 'react'
import { AddButton } from '@e3dc-react/shell/Elements/Buttons'
import useModals from '../../Hooks/useModals'
import { useCustomTranslation } from '@e3dc-react/shell/Hooks/useCustomTranslation'
interface AddCustomerReportProps {
  sapCustomerId: string
  className?: string
  disabled?: boolean
}

const AddCustomerReportButton: React.VFC<AddCustomerReportProps> = ({ sapCustomerId, className, disabled }) => {
  const { openCustomerReportEditor } = useModals()
  const { t } = useCustomTranslation()

  return (
    <AddButton
      onClick={() => openCustomerReportEditor({ sapCustomerId: sapCustomerId })}
      label={t('customerReport.addReportButton')}
      className={className}
      disabled={disabled}
    />
  )
}

export default AddCustomerReportButton
