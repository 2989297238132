import React, { ReactElement, useEffect, useState } from 'react'
import { FormControlLabel, FormGroup, Grid } from '@mui/material'
import { Dialog } from '@e3dc-react/shell/Elements/Dialog'
import { useCustomTranslation } from '@e3dc-react/shell/Hooks/useCustomTranslation'
import { CheckboxField, DateTimePicker, TextField } from '@e3dc-react/shell/Elements/InputFields'
import { CurrentNoteModel, NoteModel } from './NoteModel'
import { getFullUserName } from '../../Libs/user.service'
import { useTypedSelector } from 'src/State/RootReducer'
import { useUsers } from 'src/Hooks/useUsers'
import moment, { Moment } from 'moment'
import { useValidation } from '@e3dc-react/shell/Hooks/useValidation/useValidation'
import Joi from 'joi'
import { EditorSkeleton } from '@e3dc-react/shell/Elements/Skeletons'
import useFetch from '@e3dc-react/shell/Hooks/useFetch'
import CustomerSelect from '../Customer/Select/Select'
import { useAuthFetchWithResult } from '@e3dc-react/shell/Hooks/AuthFetch'
import { shallowEqual, useDispatch } from 'react-redux'
import { useSnackbar } from '@e3dc-react/shell/Hooks/Snackbar'
import { refreshApiView } from '@e3dc-react/shell/State/ApiView/ApiViewActions'
import useModals from 'src/Hooks/useModals'
import AddTodoButton from '../Todo/AddTodoButton'
import { parseDate } from '@e3dc-react/shell/Libs/ApiHelpers'
import { ErrorSkeleton } from '@e3dc-react/shell/Elements/Error'

const stateSchema = Joi.object().keys({
  userId: Joi.number(),
  sapCustomerId: Joi.string(),
  subject: Joi.string().allow(''),
  info: Joi.string().allow(''),
  date: Joi.date(),
})

interface NoteEditorProps {
  noteId?: number
  sapCustomerId?: string | null
  soldToId?: string
  place: number
  onClose: () => void
}

interface NoteFetchResultProps {
  id: number
  content: string
  createdAt: string
  sapCustomerId: number
  title: string
  updatedAt: string
  userId: number
}

const NoteEditor: React.VFC<NoteEditorProps> = ({ noteId, sapCustomerId, soldToId, place, onClose }) => {
  const { t } = useCustomTranslation()

  const userId = useTypedSelector(s => s.auth.decoded?.userId)
  const defaultState: CurrentNoteModel = {
    userId: userId,
    sapCustomerId: sapCustomerId || '',
    soldToId: sapCustomerId || '-1',
    subject: '',
    info: '',
    date: new Date(),
  }
  const [users, usersLoading, usersError] = useUsers()
  const [currentNote, setCurrentNote] = useState<CurrentNoteModel>(defaultState)
  const [note, noteLoading, noteError] = useFetch<NoteModel>({
    url: 'e3crm-notes/sales/' + noteId,
    dependencies: [noteId, sapCustomerId],
    doNotFetch: noteId === undefined,
  })
  const [withTodo, setWithTodo] = useState(false)

  useEffect(() => {
    if (note) {
      const { createdAt, id, deletedAt, ...currNote } = note
      setCurrentNote({ ...currNote, date: parseDate(currNote.date) })
    }
  }, [note])

  /**
   * updated value of the Note object
   */
  const handleInput = (name: string, value: unknown): void => {
    setCurrentNote({ ...currentNote, ...{ [name]: value } })
  }

  const [validationActive, setValidationActive] = useState(false)
  const validationObject = useValidation(currentNote, stateSchema)
  const validation = validationActive ? validationObject : {}
  const canEdit = userId === currentNote?.userId

  const [saving, setSaving] = useState(false)
  const authFetch = useAuthFetchWithResult()
  const dispatch = useDispatch()
  const { enqueueErrorStack, enqueueSuccessStack } = useSnackbar()
  const { openTodoEditor } = useModals()

  const saveNote = async (): Promise<void> => {
    // if save button clicked for the first time activate the validation
    if (!validationActive) setValidationActive(true)
    // if validation fails do not save
    if (Object.keys(validationObject).length > 0) return

    let noteResult

    if (noteId) {
      const { id, createdAt, userId, ...tmpNote } = currentNote
      try {
        setSaving(true)
        await authFetch({ path: 'e3crm-notes/sales/' + noteId, method: 'PUT', payload: tmpNote })
        enqueueSuccessStack(t('notes.success.saveNote'))
      } catch (err) {
        const error = err as Error
        enqueueErrorStack(t('notes.error.saveNote', { message: error.message }))
      } finally {
        setSaving(false)
      }
    } else {
      try {
        setSaving(true)
        noteResult = await authFetch<NoteFetchResultProps>({ path: 'e3crm-notes/sales', method: 'POST', payload: currentNote })
        enqueueSuccessStack(t('notes.success.addNote'))
      } catch (err) {
        const error = err as Error
        enqueueErrorStack(t('notes.error.addNote', { message: error.message }))
      } finally {
        setSaving(false)
      }
    }

    dispatch(refreshApiView())
    onClose()
    if (withTodo) openTodoEditor({ salesNoteId: noteResult?.id, sapCustomerId: currentNote.sapCustomerId })
  }

  const getContent = (): ReactElement => {
    if (usersLoading || (noteId && noteLoading)) return <EditorSkeleton />
    else if (usersError) return <ErrorSkeleton title={t('errors.usersError', { message: usersError.message })} type={'editor'} />
    else if (noteId && noteError) return <ErrorSkeleton title={t('errors.noteError', { message: noteError.message })} type={'editor'} />
    return (
      <>
        <Grid container alignItems="center" style={{ marginTop: '0' }} spacing={2}>
          <Grid item xs={12}>
            <TextField
              label={t('common.owner')}
              value={currentNote ? getFullUserName(users, currentNote.userId) : getFullUserName(users, userId)}
              disabled
            />
          </Grid>
          <Grid item xs={12}>
            <DateTimePicker
              label={t('common.date')}
              helperText={validation['date'] ? validation['date'].message : false}
              value={moment(currentNote.date)}
              onChange={(date: Moment | null) => handleInput('date', date ? date.toDate() : '')}
              inputFormat="DD.MM.YYYY"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              helperText={validation['subject'] ? validation['subject'].message : false}
              label={t('notes.subject')}
              value={currentNote.subject || ''}
              onChange={(value: string) => handleInput('subject', value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              helperText={validation['info'] ? validation['info'].message : false}
              label={t('notes.info')}
              multiline
              value={currentNote.info || ''}
              onChange={(value: string) => handleInput('info', value)}
            />
          </Grid>
          <Grid item xs={12}>
            <CustomerSelect
              value={currentNote.sapCustomerId}
              withRedirect={true}
              error={validation.sapCustomerId}
              onSelect={(customer: string) => handleInput('sapCustomerId', customer)}
              placeholder={t('notes.select.placeholder')}
            />
          </Grid>
          {!noteId && (
            <Grid item xs={12}>
              <FormGroup row>
                <FormControlLabel
                  control={<CheckboxField value={withTodo} onChange={() => setWithTodo(!withTodo)} color="primary" />}
                  label={t('notes.addTodo')}
                />
              </FormGroup>
            </Grid>
          )}
        </Grid>
      </>
    )
  }
  return (
    <Dialog
      title={noteId ? t('notes.title.edit') : t('notes.title.add')}
      open={true}
      onClose={() => onClose()}
      confirmClose={!shallowEqual(currentNote, noteId ? note : defaultState)}
      place={place}
      actions={{
        primary: {
          name: t('common.save'),
          onClick: () => saveNote(),
          isLoading: saving,
          disabled:
            (validationActive && Object.keys(validationObject).length > 0) ||
            !canEdit ||
            !currentNote ||
            noteLoading ||
            usersLoading ||
            (noteId && Boolean(noteError)) ||
            Boolean(usersError),
        },
        secondary: [
          {
            name: t('common.cancel'),
          },
        ],
      }}
      additionalActionsLeft={
        noteId && !noteError && currentNote ? <AddTodoButton salesNoteId={noteId} sapCustomerId={currentNote.sapCustomerId.toString()} /> : null
      }
    >
      {getContent()}
    </Dialog>
  )
}

export default NoteEditor
