import React, { useState, useEffect } from 'react'
import { useAuthFetchWithResult } from '@e3dc-react/shell/Hooks/AuthFetch'
import { objectApiResult, parseNullableDate } from '@e3dc-react/shell/Libs/ApiHelpers'
import { CollapsedDetailsList } from '@e3dc-react/shell/Elements/List'
import { useUsers } from '../../../Hooks/useUsers'
import { getLocaleDate } from '../../../Libs/text.service'
import useModals from '../../../Hooks/useModals'
import { AccordionSkeleton } from '@e3dc-react/shell/Elements/Skeletons'
import { ErrorSkeleton } from '@e3dc-react/shell/Elements/Error'
import { useTypedSelector } from 'src/State/RootReducer'
import { CustomerReportModel, CustomerReportsFetchModel, CurrentCustomerReportModel } from 'src/Routes/CustomerReport/CustomerReportModel'
import { ListItem } from '@e3dc-react/shell/Elements/List/List'
import { useCustomTranslation } from '@e3dc-react/shell/Hooks/useCustomTranslation'
import { AuthFetchError } from '@e3dc-react/shell/Types/api'

const CustomerReports: React.VFC<{ customerId: string }> = ({ customerId }) => {
  const [customerReports, setCustomerReports] = useState<CurrentCustomerReportModel[]>([])
  const { t } = useCustomTranslation()
  const [customerReportsLoading, setCustomerReportsLoading] = useState(false)
  const [customerReportsError, setCustomerReportsError] = useState<{ message: string }>()
  const authFetch = useAuthFetchWithResult()
  const [, usersLoading, usersError] = useUsers()
  const refresh = useTypedSelector(state => state.apiView.refresh)
  const { openCustomerReportEditor } = useModals()

  useEffect((): (() => void) => {
    let isMounted = true
    const loadCustomerData = async (): Promise<void> => {
      try {
        setCustomerReportsLoading(true)
        const customerReports: CurrentCustomerReportModel[] = objectApiResult<CustomerReportsFetchModel, CustomerReportModel[]>(
          await authFetch<CustomerReportsFetchModel>({
            path: 'customer-reports',
            method: 'GET',
            payload: { filter: 'sapCustomerId=' + customerId, sort: '-date' },
          })
        ).map((customerReport: CustomerReportModel) => ({
          ...customerReport,
          deleted_at: customerReport.deleted_at ? new Date(customerReport.deleted_at) : null,
          created_at: customerReport.created_at ? new Date(customerReport.created_at) : null,
          date: parseNullableDate(customerReport.date),
          note: { value: customerReport.note, isDisabled: true },
          participantCustomer: { value: customerReport.participantCustomer, isDisabled: true },
          generalTopics: { value: customerReport.generalTopics, isDisabled: true },
          products: { value: customerReport.products, isDisabled: true },
          summary: { value: customerReport.summary, isDisabled: true },
          participantE3dc: { value: customerReport.participantE3dc, isDisabled: true },
          marketAndCompetition: { value: customerReport.marketAndCompetition, isDisabled: true },
          marketingAndCourses: { value: customerReport.marketingAndCourses, isDisabled: true },
          serviceAndSupport: { value: customerReport.serviceAndSupport, isDisabled: true },
        }))

        if (isMounted) setCustomerReports(customerReports || null)
      } catch (error) {
        const err = error as AuthFetchError
        setCustomerReportsError(err)
      } finally {
        setCustomerReportsLoading(false)
      }
    }

    loadCustomerData()
    return () => (isMounted = false)
  }, [customerId, refresh]) // eslint-disable-line

  const getMappedCustomerReports = (customerReports: CurrentCustomerReportModel[]): ListItem[] | null => {
    return customerReports
      ? customerReports.map(customerReport => {
          return {
            secondary: customerReport.date ? `${getLocaleDate(new Date(customerReport.date))}` : '',
            primary: t(`customerReport.types.${customerReport.type ?? 'visit'}`),
            onSelect: () => openCustomerReportEditor({ customerReportId: customerReport.id }),
          }
        })
      : null
  }

  if (customerReportsLoading || usersLoading) return <AccordionSkeleton open={false} />
  if (customerReportsError)
    return <ErrorSkeleton title={t('errors.customerReportsError', { message: customerReportsError.message })} type={'accordionClosed'} />
  if (usersError) return <ErrorSkeleton title={t('errors.usersReportsError', { message: usersError.message })} type={'accordionClosed'} />
  return <CollapsedDetailsList title={t('customers.title.reports')} data={getMappedCustomerReports(customerReports)} />
}

export default CustomerReports
