import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import Joi from 'joi'
import moment from 'moment'

import { Grid, Typography, Button, Divider } from '@mui/material'

import { useValidation } from '@e3dc-react/shell/Hooks/useValidation/useValidation'
import { useAuthFetchWithResult } from '@e3dc-react/shell/Hooks/AuthFetch'
import { Dialog } from '@e3dc-react/shell/Elements/Dialog'
import { refreshApiView } from '@e3dc-react/shell/State/ApiView/ApiViewActions'
import { CheckboxField, DatePicker, MemoizedNumberField, MemoizedTextField } from '@e3dc-react/shell/Elements/InputFields'
import { useSnackbar } from '@e3dc-react/shell/Hooks/Snackbar'
import UserSelect from '../User/Select'
import CustomerSelect from '../Customer/Select/Select'
import Customer from '../Customer/Customer'
import useFetch from '@e3dc-react/shell/Hooks/useFetch'
import { ErrorSkeleton } from '@e3dc-react/shell/Elements/Error'
import { EditorSkeleton } from '@e3dc-react/shell/Elements/Skeletons'
import { useTypedSelector } from 'src/State/RootReducer'
import { AdditionalProspectiveCustomerData, ProspectiveCustomerModel } from 'src/Routes/CustomerData/CustomerModel'
import { useCustomTranslation } from '@e3dc-react/shell/Hooks/useCustomTranslation'
import { FileTransfer } from '@e3dc-react/shell/Elements/FileTransfer'
import { Select } from '@e3dc-react/shell/Elements/Select'

import { shallowEqual } from 'fast-equals'
import { AuthFetchError } from '@e3dc-react/shell/Types/api'
import { ProspectiveCustomerPartnerQalifiedOptions } from 'src/Libs/customer-data/constants'
import { useUsers } from 'src/Hooks/useUsers'

const prospectiveCustomerSchema = Joi.object().keys({
  user_id: Joi.number(),
  company_name: Joi.string().required(),
  phone: Joi.string().required(),
  fax: Joi.string().allow(null, ''),
  email: Joi.string()
    .email({ minDomainSegments: 2, tlds: { allow: false } })
    .required(),
  website: Joi.string().allow(null, ''),
  first_name: Joi.string().required(),
  last_name: Joi.string().required(),
  note: Joi.string().allow(null, ''),
  company_address_street: Joi.string().required(),
  company_address_num: Joi.string().required(),
  company_address_place: Joi.string().required(),
  zipcode: Joi.number().required(),
  country: Joi.string().required(),
})

interface ProspectiveCustomerEditorProps {
  prospectiveCustomerId?: number
  place: number
  onClose: () => void
}

const ProspectiveCustomerEditor: React.VFC<ProspectiveCustomerEditorProps> = ({ prospectiveCustomerId, place, onClose }) => {
  const userId = useTypedSelector(state => state.auth.decoded?.userId)
  const dispatch = useDispatch()
  const defaultState: Partial<ProspectiveCustomerModel> = {
    user_id: userId,
  }

  const [prospectiveCustomer, setProspectiveCustomer] = useState(defaultState)
  const { t } = useCustomTranslation()
  const authFetch = useAuthFetchWithResult()
  const [isSaving, setIsSaving] = useState(false)
  const [validationActive, setValidationActive] = useState(false)
  const validationObject = useValidation(prospectiveCustomer, prospectiveCustomerSchema)
  const { enqueueErrorStack, enqueueSuccessStack } = useSnackbar()

  const [fetchedCustomer, fetchedCustomerLoading, fetchedCustomerError] = useFetch<ProspectiveCustomerModel>({
    url: 'prospective-customers/' + prospectiveCustomerId,
    dependencies: [prospectiveCustomerId],
    doNotFetch: prospectiveCustomerId === undefined,
  })

  const [users] = useUsers()

  const saveProspectiveCustomer = async (): Promise<void> => {
    // if save button clicked for the first time activate the validation
    if (!validationActive) setValidationActive(true)
    // if validation fails do not save
    if (Object.keys(validationObject).length > 0) return

    setIsSaving(true)
    try {
      if (prospectiveCustomerId) {
        /** id is not allowed by backend PUT route */
        const { id, ...rest } = prospectiveCustomer
        const request = {
          ...rest,
          ProspectiveCustomersData: {
            ...(rest.ProspectiveCustomersData ?? {}),
            externalSalesId: rest.ProspectiveCustomersData?.externalSalesId ? +rest.ProspectiveCustomersData.externalSalesId : null,
            internalSalesId: rest.ProspectiveCustomersData?.internalSalesId ? +rest.ProspectiveCustomersData?.internalSalesId : null,
          },
        }
        await authFetch({ path: 'prospective-customers/' + prospectiveCustomerId, method: 'PUT', payload: request })
        enqueueSuccessStack(t('prospectiveCustomer.success.save'))
      } else {
        await authFetch({ path: 'prospective-customers', method: 'POST', payload: prospectiveCustomer })
        enqueueSuccessStack(t('prospectiveCustomer.success.add'))
      }
    } catch (error) {
      const err = error as AuthFetchError
      setIsSaving(false)
      enqueueErrorStack(t('prospectiveCustomer.error.save', { errorCode: err.code, message: err.message }))
    } finally {
      setIsSaving(false)
    }

    dispatch(refreshApiView())
    onClose()
  }

  useEffect(() => {
    if (prospectiveCustomerId && fetchedCustomer) {
      setProspectiveCustomer(fetchedCustomer)
    }
  }, [prospectiveCustomerId, fetchedCustomer])

  const handleInput = (name: keyof ProspectiveCustomerModel, value: unknown): void => {
    setProspectiveCustomer({ ...prospectiveCustomer, ...{ [name]: value } })
  }

  const handleAdditionalInputData = (name: keyof AdditionalProspectiveCustomerData, value: unknown): void => {
    setProspectiveCustomer(prev => ({ ...prev, ProspectiveCustomersData: { ...(prev.ProspectiveCustomersData ?? {}), [name]: value } }))
  }

  const validation = validationActive ? validationObject : {}
  return (
    <Dialog
      open={true}
      onClose={() => onClose()}
      confirmClose={!shallowEqual(prospectiveCustomer, prospectiveCustomerId ? fetchedCustomer : defaultState)}
      title={prospectiveCustomerId ? t('prospectiveCustomer.edit') : t('prospectiveCustomer.add')}
      place={place}
      actions={{
        primary: {
          name: t('common.save'),
          disabled: validationActive && Object.keys(validationObject).length > 0,
          onClick: () => saveProspectiveCustomer(),
          isLoading: isSaving,
        },
        secondary: [
          {
            name: t('common.cancel'),
          },
        ],
      }}
    >
      {prospectiveCustomerId && fetchedCustomerError ? (
        <ErrorSkeleton title={t('errors.customersError')} type={'editor'} />
      ) : fetchedCustomerLoading || !prospectiveCustomer ? (
        <EditorSkeleton />
      ) : (
        <Grid container alignItems="center" style={{ marginTop: '0' }} spacing={2}>
          <Grid item xs={12}>
            <Select
              onChange={value => handleInput('becomeAwareReason', value)}
              value={prospectiveCustomer.becomeAwareReason}
              options={['salesStaff', 'socialMedia', 'recommendation', 'other'].map(item => ({
                label: t(`prospectiveCustomer.awareReasons.${item}`),
                value: item,
              }))}
              label={t('prospectiveCustomer.awareReason')}
            />
          </Grid>
          {['recommendation', 'other'].includes(prospectiveCustomer?.becomeAwareReason ?? '') && (
            <Grid item xs={12}>
              <MemoizedTextField value={prospectiveCustomer.becomeAwareText} onChange={value => handleInput('becomeAwareText', value)} />
              <Divider />
            </Grid>
          )}
          <Grid item xs={12}>
            <Select
              onChange={value => handleInput('registrationCertification', value)}
              label={t('prospectiveCustomer.registrationCertification')}
              options={[
                { label: t('common.yes'), value: true },
                { label: t('common.no'), value: false },
              ]}
              value={prospectiveCustomer.registrationCertification}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant={'subtitle2'}>{t('common.address')}</Typography>
          </Grid>
          <Grid item xs={12}>
            <MemoizedTextField
              helperText={validation.company_name ? validation.company_name.message : false}
              label={t('common.companyName')}
              value={prospectiveCustomer.company_name}
              required
              onChange={(value: string) => handleInput('company_name', value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MemoizedTextField
              helperText={validation.first_name ? validation.first_name.message : false}
              label={t('common.name')}
              value={prospectiveCustomer.first_name}
              required
              onChange={(value: string) => handleInput('first_name', value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MemoizedTextField
              helperText={validation.last_name ? validation.last_name.message : false}
              label={t('common.lastName')}
              value={prospectiveCustomer.last_name}
              onChange={(value: string) => handleInput('last_name', value)}
              required
            />
          </Grid>
          <Grid item xs={12} sm={8}>
            <MemoizedTextField
              helperText={validation.company_address_street ? validation.company_address_street.message : false}
              label={t('common.street')}
              variant="outlined"
              value={prospectiveCustomer.company_address_street || ''}
              onChange={(value: string) => handleInput('company_address_street', value)}
              required
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <MemoizedTextField
              helperText={validation.company_address_num ? validation.company_address_num.message : false}
              label={t('common.houseNumber')}
              value={prospectiveCustomer.company_address_num || ''}
              onChange={(value: string) => handleInput('company_address_num', value)}
              required
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <MemoizedTextField
              helperText={validation.zipcode ? validation.zipcode.message : false}
              label={t('common.zip')}
              value={prospectiveCustomer.zipcode ? `${prospectiveCustomer.zipcode}` : ''}
              required
              onChange={(value: string) => handleInput('zipcode', value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MemoizedTextField
              helperText={validation.company_address_place ? validation.company_address_place.message : false}
              label={t('common.city')}
              value={prospectiveCustomer.company_address_place || ''}
              onChange={(value: string) => handleInput('company_address_place', value)}
              required
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <MemoizedTextField
              helperText={validation.country ? validation.country.message : false}
              label={t('common.country')}
              value={prospectiveCustomer.country ? `${prospectiveCustomer.country}` : ''}
              required
              onChange={(value: string) => handleInput('country', value)}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant={'subtitle2'}>{t('common.contactInfo')}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <MemoizedTextField
              helperText={validation.phone ? validation.phone.message : false}
              label={t('common.phone')}
              value={prospectiveCustomer.phone}
              required
              onChange={(value: string) => handleInput('phone', value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MemoizedTextField
              helperText={validation.fax ? validation.fax.message : false}
              label={t('common.fax')}
              value={prospectiveCustomer.fax || ''}
              onChange={(value: string) => handleInput('fax', value)}
            />
          </Grid>
          <Grid item xs={12}>
            <MemoizedTextField
              helperText={validation.email ? validation.email.message : false}
              label={t('common.mail')}
              value={prospectiveCustomer.email}
              required
              onChange={(value: string) => handleInput('email', value)}
            />
          </Grid>
          <Grid item xs={12}>
            <MemoizedTextField
              helperText={validation.website ? validation.website.message : false}
              label={t('common.website')}
              value={prospectiveCustomer.website || ''}
              onChange={(value: string) => handleInput('website', value)}
            />
          </Grid>
          <Grid item xs={12}>
            <MemoizedTextField
              helperText={validation.note ? validation.note.message : false}
              label={t('common.info')}
              value={prospectiveCustomer.note || ''}
              onChange={(value: string) => handleInput('note', value)}
              multiline
              rows={5}
              rowsMax={5}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant={'subtitle2'}>{t('common.assignedUser')}</Typography>
          </Grid>
          <Grid item xs={12}>
            <UserSelect error={validation['user_id']} onSelect={userId => handleInput('user_id', userId)} value={prospectiveCustomer.user_id} />
          </Grid>
          <Grid item xs={12}>
            {prospectiveCustomer.sapCustomerId ? (
              <>
                <Button autoFocus onClick={() => handleInput('sapCustomerId', null)} color="primary" style={{ marginBottom: '16px' }}>
                  {t('prospectiveCustomer.assignSapCustomer')}
                </Button>
                <Customer customerId={prospectiveCustomer.sapCustomerId} />
              </>
            ) : (
              <CustomerSelect onSelect={cnr => handleInput('sapCustomerId', cnr)} />
            )}
          </Grid>

          <Grid item xs={12}>
            <MemoizedNumberField
              min={0}
              clearable={false}
              label={t('customerReport.employeeCount')}
              onChange={value => handleAdditionalInputData('employeeCount', value)}
              value={prospectiveCustomer.ProspectiveCustomersData?.employeeCount || 0}
            />
          </Grid>
          <Grid item xs={12}>
            <Select
              isSearchable
              clearable
              label={t('common.salesRepresentative')}
              onChange={(value: string) => handleAdditionalInputData('externalSalesId', value)}
              value={prospectiveCustomer.ProspectiveCustomersData?.externalSalesId?.toString() || ''}
              options={users
                ?.filter(user => !!user.is_field_sales) /** indicates external sales */
                .map(user => ({ label: user.first_name + ' ' + user.last_name, value: String(user.id) }))}
            />
          </Grid>
          <Grid item xs={12}>
            <Select
              isSearchable
              clearable
              label={t('common.insideSalesRepresentative')}
              onChange={(value: string) => handleAdditionalInputData('internalSalesId', value)}
              value={prospectiveCustomer.ProspectiveCustomersData?.internalSalesId?.toString() || ''}
              options={users
                ?.filter(user => !!user.is_sales) /** indicates internal sales */
                .map(user => ({ label: user.first_name + ' ' + user.last_name, value: String(user.id) }))}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DatePicker
              label={t('prospectiveCustomer.letterSendAt')}
              maxDate={moment()}
              onChange={value => handleAdditionalInputData('letterSendAt', value)}
              value={
                prospectiveCustomer.ProspectiveCustomersData?.letterSendAt ? moment(prospectiveCustomer.ProspectiveCustomersData?.letterSendAt) : null
              }
              iconPaddingRight="16px"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DatePicker
              label={t('prospectiveCustomer.talkedToAt')}
              maxDate={moment()}
              onChange={value => handleAdditionalInputData('talkedAt', value)}
              value={prospectiveCustomer.ProspectiveCustomersData?.talkedAt ? moment(prospectiveCustomer.ProspectiveCustomersData?.talkedAt) : null}
              iconPaddingRight="16px"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Select
              label={t('prospectiveCustomer.contactMethod')}
              clearable
              onChange={(value: string) => handleAdditionalInputData('contactMethod', value)}
              value={prospectiveCustomer.ProspectiveCustomersData?.contactMethod as string}
              options={[
                { label: t('common.telephone'), value: 'telephone' },
                { label: t('common.email'), value: 'email' },
                { label: t('common.teams'), value: 'teams' },
                { label: t('common.duplicate'), value: 'duplicate' },
                { label: t('common.person'), value: 'person' },
              ]}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Select
              label={t('prospectiveCustomer.salesPotential')}
              clearable
              onChange={value => handleAdditionalInputData('potential', value)}
              value={prospectiveCustomer.ProspectiveCustomersData?.potential}
              options={[
                { label: '≤10', value: 0 },
                { label: '11-25', value: 1 },
                { label: '26-50', value: 2 },
                { label: '51-150', value: 3 },
                { label: '>150', value: 4 },
              ]}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Select
              label={t('prospectiveCustomer.qualifiesAsPotentialPartner')}
              clearable
              onChange={(value: string) => handleAdditionalInputData('partnerQualified', value)}
              value={prospectiveCustomer.ProspectiveCustomersData?.partnerQualified as string}
              options={[
                { label: t('common.yes'), value: ProspectiveCustomerPartnerQalifiedOptions.yes },
                {
                  label: t('prospectiveCustomer.qualifiesAsPotentialPartnerOptions.manyProducts'),
                  value: ProspectiveCustomerPartnerQalifiedOptions.manyProducts,
                },
                {
                  label: t('prospectiveCustomer.qualifiesAsPotentialPartnerOptions.pricesToHigh'),
                  value: ProspectiveCustomerPartnerQalifiedOptions.pricesToHigh,
                },
                {
                  label: t('prospectiveCustomer.qualifiesAsPotentialPartnerOptions.qualityNotAssured'),
                  value: ProspectiveCustomerPartnerQalifiedOptions.qualityNotAssured,
                },
                {
                  label: t('prospectiveCustomer.qualifiesAsPotentialPartnerOptions.other'),
                  value: ProspectiveCustomerPartnerQalifiedOptions.other,
                },
              ]}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Select
              label={t('prospectiveCustomer.onboardingStatus')}
              clearable
              onChange={(value: string) => handleAdditionalInputData('onboardStatus', value)}
              value={prospectiveCustomer.ProspectiveCustomersData?.onboardStatus as string}
              options={[
                { label: t('prospectiveCustomer.onboardingStatuses.initial'), value: 'initial' },
                { label: t('prospectiveCustomer.onboardingStatuses.training'), value: 'training' },
                { label: t('prospectiveCustomer.onboardingStatuses.interested'), value: 'interested' },
                { label: t('prospectiveCustomer.onboardingStatuses.failed'), value: 'failed' },
              ]}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CheckboxField
              label={t('prospectiveCustomer.interestedInCertificationTraining')}
              onChange={(value: boolean) => handleAdditionalInputData('interestedInCertification', value)}
              value={prospectiveCustomer.ProspectiveCustomersData?.interestedInCertification}
            />
          </Grid>

          <Grid item xs={12}>
            {prospectiveCustomer.id ? (
              <FileTransfer
                uploadUrl={'prospective-customers/' + prospectiveCustomer.id + '/upload'}
                downloadListUrl={'prospective-customers/' + prospectiveCustomer.id + '/download/list'}
                downloadGenerateUrl={(filename: string) => 'prospective-customers/' + prospectiveCustomer.id + '/download/' + filename}
                deleteGenerateUrl={(filename: string) => 'prospective-customers/' + prospectiveCustomer.id + '/delete/' + filename}
                apiEndpoint={process.env.REACT_APP_API_ENDPOINT ?? ''}
              />
            ) : (
              <Typography variant="subtitle2" color="error">
                {t('common.filemanagement.firstUpload')}
              </Typography>
            )}
          </Grid>
        </Grid>
      )}
    </Dialog>
  )
}

export default ProspectiveCustomerEditor
