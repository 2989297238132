import React from 'react'
import { AddButton } from '@e3dc-react/shell/Elements/Buttons'
import { useCustomTranslation } from '@e3dc-react/shell/Hooks/useCustomTranslation'
import useModals from 'src/Hooks/useModals'

interface AddNoteButtonProps {
  sapCustomerId?: string
  className?: string
  disabled?: boolean
}

const AddNoteButton: React.VFC<AddNoteButtonProps> = ({ sapCustomerId, className, disabled }) => {
  const { openCustomerNoteEditor } = useModals()
  const { t } = useCustomTranslation()

  return (
    <AddButton
      onClick={() => openCustomerNoteEditor({ sapCustomerId: sapCustomerId })}
      label={t('notes.addNoteButton')}
      className={className}
      disabled={disabled}
    />
  )
}

export default AddNoteButton
