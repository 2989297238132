import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import Joi from 'joi'

import { Grid, FormControlLabel, Button } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import { useValidation } from '@e3dc-react/shell/Hooks/useValidation/useValidation'
import { useAuthFetchWithResult } from '@e3dc-react/shell/Hooks/AuthFetch'
import { Dialog } from '@e3dc-react/shell/Elements/Dialog'
import { CheckboxField, TextField } from '@e3dc-react/shell/Elements/InputFields'

import { refreshApiView } from '@e3dc-react/shell/State/ApiView/ApiViewActions'
import { Select } from '@e3dc-react/shell/Elements/Select'
import { useSnackbar } from '@e3dc-react/shell/Hooks/Snackbar'
import useModals from 'src/Hooks/useModals'
import useFetch from '@e3dc-react/shell/Hooks/useFetch'
import { EditorSkeleton } from '@e3dc-react/shell/Elements/Skeletons'
import { ErrorSkeleton } from '@e3dc-react/shell/Elements/Error'
import { UserModel } from 'src/Routes/Users/UserModel'
import { useCustomTranslation } from '@e3dc-react/shell/Hooks/useCustomTranslation'
import { shallowEqual } from 'fast-equals'
import { AuthFetchError } from '@e3dc-react/shell/Types/api'
const stateSchema = Joi.object().keys({
  ldapmail: Joi.string()
    .lowercase()
    .options({ convert: false })
    .email({ minDomainSegments: 2, tlds: { allow: false } }),
  user_level: Joi.string(),
  sap_sales_staff: Joi.string(),
  internal_phone_number: Joi.string(),
  first_name: Joi.string(),
  last_name: Joi.string(),
  hide_in_stats: Joi.number().integer(),
  is_sales: Joi.number().integer(),
  is_field_sales: Joi.number().integer(),
})

const useStyles = makeStyles()(theme => ({
  formRoot: {
    padding: theme.spacing(3),
  },
}))

interface UserEditorProps {
  userId?: number
  onClose: () => void
  place: number
}

const UserEditor: React.VFC<UserEditorProps> = ({ userId, onClose, place }) => {
  const { t } = useCustomTranslation()
  const dispatch = useDispatch()
  const authFetch = useAuthFetchWithResult()
  const defaultState: Partial<UserModel> = {
    ldapmail: '',
    user_level: 'USER',
    sap_sales_staff: '',
    internal_phone_number: '',
    first_name: '',
    last_name: '',
    hide_in_stats: 0,
    is_sales: 0,
    is_field_sales: 0,
  }
  const [isSaving, setIsSaving] = useState(false)
  const [user, userLoading, userError] = useFetch<UserModel>({ url: 'user-e3crm/' + userId, doNotFetch: userId === undefined })
  const [currentUser, setCurrentUser] = useState<Partial<UserModel>>(defaultState)
  const { enqueueErrorStack, enqueueSuccessStack } = useSnackbar()
  const { openConfirmationModal } = useModals()
  const [validationActive, setValidationActive] = useState(false)
  const validationObject = useValidation(currentUser, stateSchema)

  const userLevelOptions = [
    { label: 'USER', value: 'USER' },
    { label: 'E3DC_ADMIN', value: 'E3DC_ADMIN' },
  ]

  const handleInput = (name: string, value: unknown): void => {
    setCurrentUser({ ...currentUser, ...{ [name]: value } })
  }

  const saveUser = async (): Promise<void> => {
    // if save button clicked for the first time activate the validation
    if (!validationActive) setValidationActive(true)
    // if validation fails do not save
    if (Object.keys(validationObject).length > 0) return

    setIsSaving(true)
    let result
    if (userId) {
      try {
        const { created_user_id, created_at, id, last_login, ...userToUpdate } = currentUser
        result = await authFetch({ path: 'user-e3crm/' + userId, method: 'PUT', payload: { user: userToUpdate } })
        enqueueSuccessStack(t('user.success.save'))
      } catch (error) {
        const err = error as AuthFetchError

        setIsSaving(false)
        enqueueErrorStack(t('user.error.save', { errorCode: err.code, message: err.message }))
      } finally {
        setIsSaving(false)
      }
    } else {
      try {
        result = await authFetch({ path: 'user-e3crm', method: 'POST', payload: currentUser })
        if (result) enqueueSuccessStack(t('user.success.add'))
      } catch (error) {
        setIsSaving(false)
        const err = error as AuthFetchError

        enqueueErrorStack(t('user.error.add', { errorCode: err.code, message: err.message }))
      } finally {
        setIsSaving(false)
      }
    }
    dispatch(refreshApiView())
    onClose()
  }

  const deleteUser = async (): Promise<void> => {
    try {
      await authFetch({ path: 'user-e3crm/' + userId, method: 'DELETE' })
      enqueueSuccessStack(t('user.success.delete'))
    } catch (error) {
      const err = error as AuthFetchError

      enqueueErrorStack(t('user.error.delete', { errorCode: err.code, message: err.message }))
    }
    onClose()
    dispatch(refreshApiView())
  }

  useEffect(() => {
    if (userId && user) {
      setCurrentUser({ ...currentUser, ...user })
    }
  }, [userId, user]) // eslint-disable-line

  const { classes } = useStyles()
  const validation = validationActive ? validationObject : {}

  return (
    <Dialog
      open={true}
      onClose={() => onClose()}
      confirmClose={!shallowEqual(currentUser, userId ? user : defaultState)}
      title={userId ? t('user.edit') : t('user.add')}
      actions={{
        primary: {
          name: t('common.save'),
          disabled: validationActive && Object.keys(validationObject).length > 0,
          onClick: () => saveUser(),
          isLoading: isSaving,
        },
        secondary: [
          {
            name: t('common.cancel'),
          },
        ],
      }}
      place={place}
      additionalActionsLeft={
        <Button
          disabled={!userId}
          onClick={() =>
            openConfirmationModal({
              text: t('user.delete.warning'),
              title: t('user.delete.title'),
              buttonText: [t('common.cancel'), t('common.delete')],
              onConfirm: async onClose => {
                await deleteUser()
                onClose()
              },
            })
          }
          color="primary"
          variant={'outlined'}
        >
          Löschen
        </Button>
      }
    >
      {userError && userId ? (
        <ErrorSkeleton title={t('errors.userError', { message: userError.message })} type={'editor'} />
      ) : userLoading ? (
        <EditorSkeleton />
      ) : (
        <Grid container alignItems="center" spacing={3} className={classes.formRoot}>
          <Grid item xs={6}>
            <TextField
              helperText={validation.ldapmail ? validation.ldapmail.message : false}
              label={t('common.ldapMail')}
              value={currentUser.ldapmail}
              onChange={(value: string) => handleInput('ldapmail', value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <Select
              options={userLevelOptions}
              value={currentUser.user_level}
              label={t('common.userLevel')}
              onChange={(value: string) => handleInput('user_level', value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              helperText={validation.first_name ? validation.first_name.message : false}
              label={t('common.name')}
              value={currentUser.first_name}
              onChange={(value: string) => handleInput('first_name', value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              helperText={validation.last_name ? validation.last_name.message : false}
              label={t('common.lastName')}
              value={currentUser.last_name}
              onChange={(value: string) => handleInput('last_name', value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              helperText={validation.sap_sales_staff ? validation.sap_sales_staff.message : false}
              label={t('user.sapSalesStaff')}
              value={currentUser.sap_sales_staff}
              onChange={(value: string) => handleInput('sap_sales_staff', value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              helperText={validation.internal_phone_number ? validation.internal_phone_number.message : false}
              label={t('user.internalPhone')}
              value={currentUser.internal_phone_number}
              onChange={(value: string) => handleInput('internal_phone_number', value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <CheckboxField value={currentUser.is_sales} onChange={value => handleInput('is_sales', value)} color={'primary'} mapping={[0, 1]} />
              }
              label={t('user.isSalesRep')}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <CheckboxField
                  value={currentUser.hide_in_stats}
                  onChange={value => handleInput('hide_in_stats', value)}
                  color={'primary'}
                  mapping={[0, 1]}
                />
              }
              label={t('user.hideInStats')}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <CheckboxField
                  value={currentUser.is_field_sales}
                  onChange={value => handleInput('is_field_sales', value)}
                  mapping={[0, 1]}
                  color={'primary'}
                />
              }
              label={t('user.isFieldRep')}
            />
          </Grid>
        </Grid>
      )}
    </Dialog>
  )
}

export default UserEditor
