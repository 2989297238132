import React, { useEffect, useState } from 'react'
import useModals from '../../../Hooks/useModals'
import { Divider, Grid, IconButton, Typography, Table, TableBody, TableCell, TableRow } from '@mui/material'
import { ListSkeleton, TextSkeleton } from '@e3dc-react/shell/Elements/Skeletons'
import Moment from 'moment'
import { ErrorSkeleton } from '@e3dc-react/shell/Elements/Error'
import { CustomerDetailsModel } from 'src/Routes/CustomerData/CustomerModel'
import { useCustomTranslation } from '@e3dc-react/shell/Hooks/useCustomTranslation'
import { J2Editacontent } from '@e3dc-react/icons'
import { CallLink, MailLink } from '@e3dc-react/shell/Elements/Links'
import { CheckboxField } from '@e3dc-react/shell/Elements/InputFields'
import { useSnackbar } from '@e3dc-react/shell/Hooks/Snackbar'
import { useAuthFetchWithResult } from '@e3dc-react/shell/Hooks/AuthFetch'
import { AuthFetchError } from '@e3dc-react/shell/Types/api'

interface CustomerDetailsProps {
  customer: CustomerDetailsModel
  customerLoading: boolean
  customerError?: AuthFetchError
}

const CustomerDetails: React.VFC<CustomerDetailsProps> = ({ customer, customerLoading, customerError }) => {
  const { t } = useCustomTranslation()
  const { openCustomerEditor } = useModals()
  const authFetch = useAuthFetchWithResult()
  const { enqueueErrorStack, enqueueSuccessStack } = useSnackbar()
  const [canReceiveLeads, setCanReceiveLeads] = useState(false)

  const handleCall = (number?: string): void => {
    if (!number) return
    window.open('tel:' + number, '_self')
  }

  useEffect(() => {
    setCanReceiveLeads(customer?.leads ?? false)
  }, [customer])

  const changeCanReceiveLeads = (customerId: string, value: boolean): void => {
    authFetch({ path: 'customer-e3crm/customers/details/' + customerId, method: 'PUT', payload: { leads: value } })
      .then(data => {
        setCanReceiveLeads(value)
        enqueueSuccessStack(t('customers.update-lead.success'))
      })
      .catch(error => {
        enqueueErrorStack(t('customers.update-lead.error'))
        console.error('Could not update value for canReceiveLeads. Error: ', error)
      })
  }

  if (customerError) return <ErrorSkeleton title={t('errors.customersError')} />

  const getContent = (customer: CustomerDetailsModel): { title: string; content: React.ReactNode }[] => [
    { title: t('common.customerNr'), content: customer.Kundennr },
    { title: t('common.phone'), content: <CallLink telNumber={customer.Telefon} handleClick={() => handleCall(customer.Telefon)} /> },
    {
      title: t('common.mail'),
      content: customer?.Mailadresse ? customer.Mailadresse.split('; ').map(mail => <MailLink mailAddress={mail} key={mail} />) : '-',
    },
    { title: t('common.address'), content: customer.Adresse },
    { title: t('common.city'), content: customer.City },
    { title: t('common.zip'), content: customer.PLZ },
    { title: t('common.country'), content: customer.Land },
    { title: t('customers.customerSince'), content: Moment(customer.CDate).format('DD.MM.YYYY') },
    { title: t('customers.segment'), content: customer.Segment ?? t('common.no') },

    {
      title: t('customerReport.employeeCount'),
      content: (
        <>
          <IconButton
            aria-label="edit"
            disabled={!customer.isActive}
            onClick={() => openCustomerEditor({ customerId: customer.Kundennr, keyToEdit: 'employeeCount' })}
          >
            <J2Editacontent />
          </IconButton>
          {customer.employeeCount}
        </>
      ),
    },
    {
      title: t('common.salesRepresentative'),
      content: customer.externalSalesUsername,
    },
    {
      title: t('common.insideSalesRepresentative'),
      content: customer.internalSalesUsername,
    },
    {
      title: t('common.website'),
      content: (
        <>
          <IconButton
            aria-label="edit"
            disabled={!customer.isActive}
            onClick={() => openCustomerEditor({ customerId: customer.Kundennr, keyToEdit: 'website' })}
          >
            <J2Editacontent />
          </IconButton>
          {customer.website}
        </>
      ),
    },
    {
      title: t('customers.info'),
      content: (
        <>
          <IconButton
            aria-label="edit"
            disabled={!customer.isActive}
            onClick={() => openCustomerEditor({ customerId: customer.Kundennr, keyToEdit: 'note' })}
          >
            <J2Editacontent />
          </IconButton>
          {customer.note}
        </>
      ),
    },
    {
      title: t('customers.may-receive-leads'),
      content: (
        <CheckboxField value={canReceiveLeads} disabled={!customer.isActive} onChange={value => changeCanReceiveLeads(customer.Kundennr, value)} />
      ),
    },
    { title: t('customers.status'), content: customer.isActive ? t('common.active') : t('common.inactive') },
    { title: t('common.installers'), content: customer.InstallerMenge },
    { title: t('common.CPI'), content: customer.CPI },
  ]

  return (
    <Grid container alignItems="center" spacing={1}>
      <Grid item xs={12}>
        <Grid container direction="row" spacing={2} alignItems="center">
          <Grid item xs={12}>
            {customerLoading ? (
              <TextSkeleton variant="h6"></TextSkeleton>
            ) : (
              <Typography variant="h6" align="center">
                {customer?.Kundenname}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle1">{t('customers.title.details')}</Typography>
      </Grid>
      {customerLoading || !customer ? (
        <ListSkeleton />
      ) : (
        <Table size="small">
          <TableBody>
            {getContent(customer).map(item => (
              <TableRow key={item.title} hover={true}>
                <TableCell align="left">{item.title}</TableCell>
                <TableCell align="right">{item.content}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </Grid>
  )
}

export default CustomerDetails
