import React, { useState } from 'react'

import { Dialog } from '@e3dc-react/shell/Elements/Dialog'

import AddCallButton from '../Call/AddCallButton'
import AddCustomerReportButton from '../CustomerReport/AddCustomerReportButton'
import AddTodoButton from '../Todo/AddTodoButton'
import Customer from './Customer'
import { makeStyles } from 'tss-react/mui'
import { Typography } from '@mui/material'
import { useCustomTranslation } from '@e3dc-react/shell/Hooks/useCustomTranslation'
import { J2Caution } from '@e3dc-react/icons'
import AddNoteButton from '../Notes/AddNoteButton'

const useStyles = makeStyles()(theme => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),

    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  iconRow: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(1),
    },
  },
  icon: {
    marginRight: theme.spacing(1),
    fontSize: 14,
  },
  button: {
    marginRight: theme.spacing(1),
  },
}))

interface CustomerDisplayProps {
  customerId?: string
  place: number
  onClose: () => void
}
const CustomerDisplay: React.VFC<CustomerDisplayProps> = ({ customerId, place, onClose }) => {
  const { classes } = useStyles()
  const { t } = useCustomTranslation()
  const [shouldDisableActions, setShouldDisableActions] = useState<boolean>(!customerId)

  return (
    <Dialog
      open={true}
      onClose={() => onClose()}
      title={t('customers.title.customer')}
      place={place}
      additionalActionsLeft={
        <div className={classes.wrapper}>
          <div>
            <AddCallButton sapCustomerId={customerId!} className={classes.button} disabled={shouldDisableActions} />
            <AddCustomerReportButton sapCustomerId={customerId!} className={classes.button} disabled={shouldDisableActions} />
            <AddTodoButton sapCustomerId={customerId} className={classes.button} disabled={shouldDisableActions} />
            <AddNoteButton sapCustomerId={customerId} className={classes.button} disabled={shouldDisableActions} />
          </div>
          <div className={classes.iconRow}>
            <J2Caution className={classes.icon} color={'error'} />
            <Typography variant={'caption'}>{t('customers.warning.deviatingValues')}</Typography>
          </div>
        </div>
      }
    >
      <Customer customerId={customerId} setCustomerAsInactive={isInactive => setShouldDisableActions(isInactive)} />
    </Dialog>
  )
}

export default CustomerDisplay
