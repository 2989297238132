import React from 'react'
import DetailsUI from './DetailsUI'
import useModals from '../../../Hooks/useModals'
import { EditorSkeleton } from '@e3dc-react/shell/Elements/Skeletons'
import useFetch from '@e3dc-react/shell/Hooks/useFetch'
import { ErrorSkeleton } from '@e3dc-react/shell/Elements/Error'
import { useTypedSelector } from 'src/State/RootReducer'
import { ProspectiveCustomerModel } from 'src/Routes/CustomerData/CustomerModel'
import { useCustomTranslation } from '@e3dc-react/shell/Hooks/useCustomTranslation'
import { useUsers } from 'src/Hooks/useUsers'

const ProspectiveCustomerDetails: React.VFC<{ customerId: string }> = ({ customerId }) => {
  const { t } = useCustomTranslation()
  /** @type {[(ProspectiveCustomer), React.Dispatch<React.SetStateAction<ProspectiveCustomer>]} */
  const refresh = useTypedSelector(state => state.apiView.refresh)
  const [prospectiveCustomer, prospectiveCustomerLoading, prospectiveCustomerError] = useFetch<ProspectiveCustomerModel>({
    url: 'prospective-customers/' + customerId.replace('I', ''),
    dependencies: [customerId, refresh],
    doNotFetch: customerId === undefined,
  })
  const [users, usersLoading, usersError] = useUsers()
  const { openProspectiveCustomerEditor } = useModals()

  if (prospectiveCustomerError || usersError) {
    return <ErrorSkeleton title={t('errors.customersError')} />
  }
  if (prospectiveCustomerLoading || usersLoading || !prospectiveCustomer) {
    return <EditorSkeleton />
  }

  return (
    <DetailsUI
      prospectiveCustomer={prospectiveCustomer}
      users={users}
      handleEditButton={() => openProspectiveCustomerEditor({ prospectiveCustomerId: prospectiveCustomer.id })}
    />
  )
}

export default ProspectiveCustomerDetails
