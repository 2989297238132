import React, { ReactElement, useMemo } from 'react'
import { useUsers } from '../../../Hooks/useUsers'
import { CollapsedDetailsList } from '@e3dc-react/shell/Elements/List'
import { getPrimaryText, getLocaleDate } from '../../../Libs/text.service'
import { getFullUserName } from '../../../Libs/user.service'
import { parseDate } from '@e3dc-react/shell/Libs/ApiHelpers'
import useModals from '../../../Hooks/useModals'
import TodoStatusWithPriority from 'src/Elements/Todo/TodoStatusWithPriority'
import { AccordionSkeleton } from '@e3dc-react/shell/Elements/Skeletons'
import { useFetchApiObject } from '@e3dc-react/shell/Hooks/useFetch'
import { ErrorSkeleton } from '@e3dc-react/shell/Elements/Error'
import { useTypedSelector } from 'src/State/RootReducer'
import { TodoModel } from './TodoModel'
import { useCustomTranslation } from '@e3dc-react/shell/Hooks/useCustomTranslation'
const CustomerTodos: React.VFC<{ customerId: string }> = ({ customerId }) => {
  const refresh = useTypedSelector(state => state.apiView.refresh)
  const { t } = useCustomTranslation()
  const todoPayload = useMemo(() => ({ filter: `sapCustomerId=${customerId}`, sort: '-created_at' }), [customerId, refresh]) //eslint-disable-line
  const [todos, todosLoading, todosError] = useFetchApiObject<TodoModel[]>({ url: 'todos', payload: todoPayload })
  const [users, usersLoading, usersError] = useUsers()
  const { openTodoEditor } = useModals()

  const getMappedTodos = (todos?: TodoModel[]): { primary: string; secondary: string; icon: ReactElement; onSelect: () => void }[] | null => {
    return todos
      ? todos.map(todo => ({
          primary: getPrimaryText(todo.text, t('todo.titleSingle'), 50),
          secondary: `${getLocaleDate(parseDate(todo.created_at || ''))}  |  ${getFullUserName(users, todo.user_id)}`,
          icon: <TodoStatusWithPriority status={todo.status} priority={todo.priority} />,
          onSelect: () => openTodoEditor({ todoId: todo.id }),
        }))
      : null
  }

  if ((usersLoading && !users) || (todosLoading && !todos)) return <AccordionSkeleton open={false} />

  if (usersError) return <ErrorSkeleton title={t('errors.usersError', { message: usersError.message })} type={'accordionClosed'} />
  if (todosError) return <ErrorSkeleton title={t('errors.todosError', { message: todosError.message })} type={'accordionClosed'} />

  return <CollapsedDetailsList title={t('todos.title')} data={getMappedTodos(todos)} />
}

export default CustomerTodos
