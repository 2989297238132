import React, { memo } from 'react'
import { Grid, Typography, Divider } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { CheckboxField, MemoizedNumberField, MemoizedTextField } from '@e3dc-react/shell/Elements/InputFields'
import { RemoveButton, AddButton } from '@e3dc-react/shell/Elements/Buttons'
import { getArrayValidation } from '../../../Libs/validation.service'
import { Loading } from '@e3dc-react/shell/Elements/Loading'
import { deepEqual } from 'fast-equals'
import { ProjectModel } from '../ProjectModel'
import { ValidationResult } from 'src/Types/globalTypes'

const useStyles = makeStyles()(theme => ({
  divider: {
    marginBottom: theme.spacing(2),
  },
  spacerTop: {
    marginTop: theme.spacing(2),
  },
}))

interface ConsumptionProps {
  consumption?: Partial<ProjectModel>
  validation: ValidationResult
  onChange: (name: string, value: unknown) => void
  onArrayChange: (name: string, value: unknown, index: number) => void
  addConsumer: () => void
  removeConsumer: (index: number) => void
}

const Consumption: React.VFC<ConsumptionProps> = ({ consumption, validation, onChange, onArrayChange, addConsumer, removeConsumer }) => {
  const { classes } = useStyles()

  if (!consumption) return <Loading />

  const getValidation = (index: number, type: string): string | undefined => getArrayValidation(validation, 'consumers', index, type)

  return (
    <Grid container spacing={2} alignContent={'flex-end'}>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <MemoizedNumberField
              helperText={validation['consumption_per_year'] ? validation['consumption_per_year'].message : undefined}
              label="Verbrauch pro Jahr in kWh"
              value={consumption.consumption_per_year}
              onChange={value => onChange('consumption_per_year', value)}
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <MemoizedNumberField
              helperText={validation['day_usage'] ? validation['day_usage'].message : undefined}
              label="Tagesverbrauch 8-18 Uhr in kWh"
              value={consumption.day_usage}
              onChange={value => onChange('day_usage', value)}
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <MemoizedNumberField
              helperText={validation['peak_load'] ? validation['peak_load'].message : undefined}
              label="Spitzenlast in kW"
              value={consumption.peak_load}
              onChange={value => onChange('peak_load', value)}
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <MemoizedNumberField
              helperText={validation['night_usage'] ? validation['night_usage'].message : undefined}
              label="Nachtverbrauch 18-8 Uhr in kWh"
              value={consumption.night_usage}
              onChange={value => onChange('night_usage', value)}
              required
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} md={6}>
        <CheckboxField
          label="Besitzt die Wärmepumpe einen eigenen Zähler?"
          value={consumption.heat_pump_own_counter || 0}
          onChange={value => onChange('heat_pump_own_counter', value)}
          mapping={[0, 1]}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <CheckboxField
          label="Existiert ein Lastprofil (Auswertng, csv, etc.)?"
          value={consumption.own_load_profile || 0}
          onChange={value => onChange('own_load_profile', value)}
          mapping={[0, 1]}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <CheckboxField
          label="Existieren spezielle Verbraucher?"
          value={consumption.special_consumers || 0}
          onChange={value => onChange('special_consumers', value)}
          mapping={[0, 1]}
        />
      </Grid>

      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography variant="h6" className={classes.spacerTop}>
            Verbraucher
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {consumption.consumers &&
            consumption.consumers.map((consumer, index) => {
              return (
                <Grid container key={index} spacing={4} alignContent={'flex-end'}>
                  <Grid item xs={12} md={3}>
                    <MemoizedTextField
                      helperText={getValidation(index, 'name')}
                      label="Verbraucher"
                      value={consumer.name || ''}
                      onChange={value => onArrayChange('name', value, index)}
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <MemoizedNumberField
                      helperText={getValidation(index, 'amount')}
                      label="Anzahl"
                      value={consumer.amount}
                      onChange={value => onArrayChange('amount', value, index)}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <MemoizedTextField
                      helperText={getValidation(index, 'type')}
                      label="Typ"
                      value={consumer.type}
                      onChange={value => onArrayChange('type', value, index)}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <MemoizedNumberField
                      helperText={getValidation(index, 'consumption')}
                      label="Verbrauch/Jahr"
                      value={consumer.consumption}
                      onChange={value => onArrayChange('consumption', value, index)}
                    />
                  </Grid>
                  <Grid item xs={12} md={1}>
                    <RemoveButton onClick={() => removeConsumer(index)}></RemoveButton>
                  </Grid>
                  {consumption?.consumers && index < consumption.consumers.length - 1 && (
                    <Grid item xs={12}>
                      <Divider variant="fullWidth" className={classes.divider} />
                    </Grid>
                  )}
                </Grid>
              )
            })}
        </Grid>

        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <AddButton onClick={addConsumer}></AddButton>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default memo(
  Consumption,
  (prevProps, nextProp) => deepEqual(prevProps.consumption, nextProp.consumption) && deepEqual(prevProps.validation, nextProp.validation)
)
