import React, { useState, useEffect, ReactElement } from 'react'
import { useDispatch } from 'react-redux'
import Joi from 'joi'

import { Grid, FormGroup, FormControlLabel, Typography } from '@mui/material'

import { useValidation } from '@e3dc-react/shell/Hooks/useValidation/useValidation'
import { useAuthFetchWithResult } from '@e3dc-react/shell/Hooks/AuthFetch'
import { Dialog } from '@e3dc-react/shell/Elements/Dialog'
import { DateTimePicker, TextField, OnOffCheckboxField, CheckboxField } from '@e3dc-react/shell/Elements/InputFields'
import { refreshApiView } from '@e3dc-react/shell/State/ApiView/ApiViewActions'

import CustomerSelect from '../Customer/Select/Select'
import CustomerSelectContact from '../Customer/Select/SelectContact'
import { useUsers } from '../../Hooks/useUsers'
import { getFullUserName } from '../../Libs/user.service'
import { useSnackbar } from '@e3dc-react/shell/Hooks/Snackbar'

import useModals from '../../Hooks/useModals'
import AddTodoButton from '../Todo/AddTodoButton'
import { EditorSkeleton } from '@e3dc-react/shell/Elements/Skeletons'
import useFetch from '@e3dc-react/shell/Hooks/useFetch'
import { ErrorSkeleton } from '@e3dc-react/shell/Elements/Error'
import { CallModel, CurrentCallModel } from './CallModel'
import { useTypedSelector } from 'src/State/RootReducer'
import parseDate from '@e3dc-react/shell/Libs/ApiHelpers/parseDate'
import moment, { Moment } from 'moment'
import { useCustomTranslation } from '@e3dc-react/shell/Hooks/useCustomTranslation'
import { J1DownleftarrowC, J1ToprightarrowC } from '@e3dc-react/icons'
import { shallowEqual } from 'fast-equals'
const stateSchema = Joi.object().keys({
  user_id: Joi.number(),
  sapCustomerId: Joi.string(),
  info: Joi.string().allow(''),
  call_duration: Joi.number(),
  call_subject: Joi.string(),
  date: Joi.date(),
  inbound: Joi.number(),
})

interface CallEditorProps {
  callId?: number
  sapCustomerId?: string | null
  sapContactId?: string
  place: number
  onClose: () => void
}

const CallEditor: React.VFC<CallEditorProps> = ({ callId, sapCustomerId, sapContactId, place, onClose }) => {
  const { t } = useCustomTranslation()
  const userId = useTypedSelector(s => s.auth.decoded?.userId)
  const defaultState: CurrentCallModel = {
    type: 'call',
    user_id: userId,
    sapCustomerId: sapCustomerId || '',
    call_duration: 0,
    call_subject: '',
    date: new Date(),
    sapContactId: sapContactId || '',
    inbound: 0,
  }
  const [users, usersLoading, usersError] = useUsers()
  const [currentCall, setCurrentCall] = useState<CurrentCallModel>(defaultState)
  const [call, callLoading, callError] = useFetch<CallModel>({
    url: 'customer-contacts/' + callId,
    dependencies: [callId, sapCustomerId],
    doNotFetch: callId === undefined,
  })
  const [withTodo, setWithTodo] = useState(false)
  const authFetch = useAuthFetchWithResult()
  const dispatch = useDispatch()
  const [validationActive, setValidationActive] = useState(false)
  const validationObject = useValidation(currentCall, stateSchema)
  const { enqueueErrorStack, enqueueSuccessStack } = useSnackbar()
  const { openTodoEditor } = useModals()
  const [saving, setSaving] = useState(false)
  const canEdit = userId === currentCall?.user_id

  useEffect(() => {
    if (call) {
      const { created_at, id, deleted_at, ...currCall } = call
      setCurrentCall({ ...currCall, date: parseDate(currCall.date) })
    }
  }, [call])

  /**
   * updated value of the Call object
   */
  const handleInput = (name: string, value: unknown): void => {
    setCurrentCall({ ...currentCall, ...{ [name]: value } })
  }

  const saveCall = async (): Promise<void> => {
    // if save button clicked for the first time activate the validation
    if (!validationActive) setValidationActive(true)
    // if validation fails do not save
    if (Object.keys(validationObject).length > 0) return

    let id
    if (callId) {
      const { id, created_at, ...tmpCall } = currentCall
      try {
        setSaving(true)
        await authFetch({ path: 'customer-contacts/' + callId, method: 'PUT', payload: tmpCall })
        enqueueSuccessStack(t('calls.success.saveCall'))
      } catch (err) {
        const error = err as Error
        enqueueErrorStack(t('calls.error.saveCall', { message: error.message }))
      } finally {
        setSaving(false)
      }
    } else {
      try {
        setSaving(true)
        id = await authFetch<number>({ path: 'customer-contacts', method: 'POST', payload: currentCall })
        enqueueSuccessStack(t('calls.success.addCall'))
      } catch (err) {
        const error = err as Error
        enqueueErrorStack(t('calls.error.addCall', { message: error.message }))
      } finally {
        setSaving(false)
      }
    }
    dispatch(refreshApiView())
    onClose()
    if (withTodo) openTodoEditor({ callId: id, sapCustomerId: currentCall.sapCustomerId })
  }

  const validation = validationActive ? validationObject : {}

  /**
   * return loading, error or content
   */
  const getContent = (): ReactElement => {
    if (usersLoading || (callId && callLoading)) return <EditorSkeleton />
    else if (usersError) return <ErrorSkeleton title={t('errors.usersError', { message: usersError.message })} type={'editor'} />
    else if (callId && callError) return <ErrorSkeleton title={t('errors.callError', { message: callError.message })} type={'editor'} />

    return (
      <>
        <Grid container alignItems="center" style={{ marginTop: '0' }} spacing={2}>
          <Grid item xs={12}>
            <TextField
              label={t('common.owner')}
              value={currentCall ? getFullUserName(users, currentCall.user_id) : getFullUserName(users, userId)}
              disabled
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container alignItems="center">
              <Grid item>
                <Typography> Richtung: </Typography>
              </Grid>
              <Grid item>
                <OnOffCheckboxField
                  icons={[<J1DownleftarrowC key={0} />, <J1ToprightarrowC key={1} />]}
                  tooltips={[t('calls.inbound'), t('calls.outbound')]}
                  value={[currentCall.inbound === 1, !currentCall.inbound || currentCall.inbound === 0]}
                  onChange={(value: boolean[]) => {
                    handleInput('inbound', value[0] ? 1 : 0)
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <DateTimePicker
              label={t('common.date')}
              helperText={validation['date'] ? validation['date'].message : false}
              value={moment(currentCall.date)}
              onChange={(date: Moment | null) => handleInput('date', date ? date.toDate() : '')}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              helperText={validation['call_duration'] ? validation['call_duration'].message : false}
              label={t('calls.duration')}
              value={`${currentCall.call_duration}` || ''}
              onChange={(value: string) => handleInput('call_duration', value?.replace(',', '.'))}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              helperText={validation['call_subject'] ? validation['call_subject'].message : false}
              label={t('calls.subject')}
              value={currentCall.call_subject || ''}
              onChange={(value: string) => handleInput('call_subject', value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              helperText={validation['info'] ? validation['info'].message : false}
              label={t('calls.info')}
              multiline
              value={currentCall.info || ''}
              onChange={(value: string) => handleInput('info', value)}
            />
          </Grid>
          <Grid item xs={12}>
            <CustomerSelect
              value={currentCall.sapCustomerId}
              withRedirect={true}
              error={validation.sapCustomerId}
              onSelect={(customer: string) => handleInput('sapCustomerId', customer)}
            />
          </Grid>
          {currentCall.sapCustomerId && (
            <Grid item xs={12}>
              <CustomerSelectContact
                sapCustomerId={currentCall.sapCustomerId}
                value={currentCall.sapContactId}
                onSelect={contact => handleInput('sapContactId', contact.toString())}
              />
            </Grid>
          )}
        </Grid>
        {!callId && (
          <Grid item xs={12}>
            <FormGroup row>
              <FormControlLabel
                control={<CheckboxField value={withTodo} onChange={() => setWithTodo(!withTodo)} color="primary" />}
                label={t('calls.addTodo')}
              />
            </FormGroup>
          </Grid>
        )}
      </>
    )
  }

  return (
    <Dialog
      open={true}
      confirmClose={!shallowEqual(currentCall, callId ? call : defaultState)}
      onClose={() => onClose()}
      title={callId ? t('calls.title.edit') : t('calls.title.add')}
      place={place}
      actions={{
        primary: {
          name: t('common.save'),
          disabled:
            (validationActive && Object.keys(validationObject).length > 0) ||
            !canEdit ||
            !currentCall ||
            callLoading ||
            usersLoading ||
            (callId && Boolean(callError)) ||
            Boolean(usersError),
          onClick: () => saveCall(),
          isLoading: saving,
        },
        secondary: [
          {
            name: t('common.cancel'),
          },
        ],
      }}
      additionalActionsLeft={callId && !callError && currentCall ? <AddTodoButton callId={callId} sapCustomerId={currentCall.sapCustomerId} /> : null}
    >
      {getContent()}
    </Dialog>
  )
}

export default CallEditor
