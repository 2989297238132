import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { CustomerModel } from 'src/Routes/CustomerData/CustomerModel'

interface CustomersReducerStateModel {
  customers?: CustomerModel[]
}

export interface CustomersActionModel {
  type: string
  customers?: CustomerModel[]
}

const initialState: CustomersReducerStateModel = {
  customers: undefined,
}

function fetchReducer(state: CustomersReducerStateModel = initialState, action: CustomersActionModel): CustomersReducerStateModel {
  //let customerReports = state.slice(0)
  switch (action.type) {
    case 'customers_set':
      return { ...state, customers: action.customers }
    default:
      return state
  }
}

const persistConfig = {
  key: 'customers',
  storage: storage,
  whitelist: ['customers'],
}

export default persistReducer(persistConfig, fetchReducer)
