import React, { memo } from 'react'
import { Grid } from '@mui/material'
import { CheckboxField, MemoizedTextField, MemoizedNumberField } from '@e3dc-react/shell/Elements/InputFields'
import { Select } from '@e3dc-react/shell/Elements/Select'
import { shallowEqual } from 'fast-equals'
import { EditorSkeleton } from '@e3dc-react/shell/Elements/Skeletons'
import { ProjectModel } from '../ProjectModel'
import { ValidationResult } from 'src/Types/globalTypes'

interface BuildingStructureProps {
  buildingStructure?: Partial<ProjectModel>
  validation: ValidationResult
  onChange: (name: string, value: unknown) => void
}

const BuildingStructure: React.VFC<BuildingStructureProps> = ({ buildingStructure, validation, onChange }) => {
  if (!buildingStructure) return <EditorSkeleton small />

  const structureOptions = [
    { label: 'Gewerbe', value: 'Gewerbe' },
    { label: 'Landwirtschaft', value: 'Landwirtschaft' },
    { label: 'Gastronomie', value: 'Gastronomie' },
  ]

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Select
          error={validation['building_type'] ? validation['building_type'].message : undefined}
          options={structureOptions}
          value={buildingStructure.building_type}
          onChange={(value: string) => onChange('building_type', value)}
          label="Gebäudetyp"
        />
      </Grid>

      <Grid item xs={12}>
        <MemoizedTextField
          helperText={validation['branch'] ? validation['branch'].message : undefined}
          label="Branche"
          value={buildingStructure.branch || ''}
          onChange={(value: string) => onChange('branch', value)}
          required
        />
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={2} justifyContent="center" alignContent="center" alignItems="center">
          <Grid item xs={12} md={6}>
            <MemoizedNumberField
              helperText={validation['connected_load'] ? validation['connected_load'].message : undefined}
              label="Anschlussleistung in A"
              value={buildingStructure.connected_load}
              onChange={value => onChange('connected_load', value)}
              min={0}
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CheckboxField
              label="RLM-/Wandlermessung"
              value={buildingStructure.transducer_measurement ?? 0}
              onChange={value => onChange('transducer_measurement', value)}
              mapping={[0, 1]}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} md={6}>
        <Grid container direction="column" spacing={2}>
          <Grid item xs={12} md={10}>
            <CheckboxField
              label="Mehrfamilienhaus"
              value={buildingStructure.is_apartment_building || 0}
              onChange={(value: 0 | 1) => onChange('is_apartment_building', value)}
              mapping={[0, 1]}
            />
          </Grid>
          <Grid item xs={12} md={10}>
            <MemoizedNumberField
              helperText={validation['residential_units_amount'] ? validation['residential_units_amount'].message : undefined}
              label="Anzahl Wohneinheiten"
              value={buildingStructure.residential_units_amount}
              onChange={value => onChange('residential_units_amount', value)}
            />
          </Grid>
          <Grid item xs={12} md={10}>
            <MemoizedNumberField
              helperText={validation['owners_amount'] ? validation['owners_amount'].message : undefined}
              label="Anzahl Eigentümer"
              value={buildingStructure.owners_amount}
              onChange={value => onChange('owners_amount', value)}
            />
          </Grid>
          <Grid item xs={12} md={10}>
            <MemoizedNumberField
              helperText={validation['tenants_amount'] ? validation['tenants_amount'].message : undefined}
              label="Anzahl Mieter"
              value={buildingStructure.tenants_amount}
              onChange={value => onChange('tenants_amount', value)}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} md={6}>
        <Grid container direction="column" spacing={2}>
          <Grid item xs={12} md={10}>
            <CheckboxField
              label="Siedlungsprojekt"
              value={buildingStructure.settlement_project || 0}
              onChange={value => onChange('settlement_project', value)}
              mapping={[0, 1]}
            />
          </Grid>
          <Grid item xs={12} md={10}>
            <MemoizedNumberField
              helperText={validation['objects_amount'] ? validation['objects_amount'].message : undefined}
              label="Anzahl Objekte"
              value={buildingStructure.objects_amount}
              onChange={value => onChange('objects_amount', value)}
            />
          </Grid>
          <Grid item xs={12} md={10}>
            <MemoizedNumberField
              helperText={validation['residential_amount'] ? validation['residential_amount'].message : undefined}
              label="Anzahl Wohneinheiten"
              value={buildingStructure.residential_amount}
              onChange={value => onChange('residential_amount', value)}
            />
          </Grid>
          <Grid item xs={12} md={10}>
            <MemoizedNumberField
              helperText={validation['mains_connections'] ? validation['mains_connections'].message : undefined}
              label="Anzahl Netzanschlüsse"
              value={buildingStructure.mains_connections}
              onChange={value => onChange('mains_connections', value)}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default memo(
  BuildingStructure,
  (prevProps, nextProp) =>
    shallowEqual(prevProps.buildingStructure, nextProp.buildingStructure) && shallowEqual(prevProps.validation, nextProp.validation)
)
