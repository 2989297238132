import { combineReducers } from 'redux'

import authReducer from '@e3dc-react/shell/State/Auth/AuthReducer'
import appReducer from '@e3dc-react/shell/State/App/AppReducer'
import apiViewReducer from '@e3dc-react/shell/State/ApiView/ApiViewReducer'
import navigationReducer from '@e3dc-react/shell/State/Navigation/NavigationReducer'
import customers from './Customers/CustomersReducer'
import tcsystem from './TCSystem/TCSystemReducer'
import CustomersReducer from './Customers/CustomersReducer'
import { createSelectorHook } from 'react-redux'
import { TCSystemReducserStateModel } from './TCSystem/TCSystemModel'
import ModalReducer from '@e3dc-react/shell/State/Modals/ModalReducer'
import { ModalState } from 'src/Elements/ModalRoot'
import userReducer from './User/UserReducer'

const combinedReducer = combineReducers({
  auth: authReducer,
  app: appReducer,
  user: userReducer,
  customers: customers,
  modal: ModalReducer,
  tcSystem: tcsystem,
  apiView: apiViewReducer,
  navigation: navigationReducer,
})

export type RootState = {
  //TODO: wenn Reducer selbst typisiert sind, `ReturnType<typeof ...>` mit passendem Type ersetzen
  auth: ReturnType<typeof authReducer>
  app: ReturnType<typeof appReducer>
  navigation: ReturnType<typeof navigationReducer>
  customers: ReturnType<typeof CustomersReducer>
  modal: ModalState
  apiView: ReturnType<typeof apiViewReducer>
  tcSystem: TCSystemReducserStateModel
  user: ReturnType<typeof userReducer>
}

export const useTypedSelector = createSelectorHook<RootState>()

export default combinedReducer
