import React, { useState, useEffect } from 'react'
import Joi from 'joi'
import { Grid } from '@mui/material'
import { Dialog } from '@e3dc-react/shell/Elements/Dialog'
import { useDispatch } from 'react-redux'
import { useAuthFetchWithResult } from '@e3dc-react/shell/Hooks/AuthFetch'
import { useValidation } from '@e3dc-react/shell/Hooks/useValidation/useValidation'
import { useSnackbar } from '@e3dc-react/shell/Hooks/Snackbar'
import { refreshApiView } from '@e3dc-react/shell/State/ApiView/ApiViewActions'
import { TextField } from '@e3dc-react/shell/Elements/InputFields'
import { CustomerDetailsModel } from '../../Routes/CustomerData/CustomerModel'
import { useCustomTranslation } from '@e3dc-react/shell/Hooks/useCustomTranslation'
import { AuthFetchError } from '@e3dc-react/shell/Types/api'

const customerSchema = Joi.object().keys({
  note: Joi.string().allow('', null),
  externalSalesUsername: Joi.string().allow('', null),
  internalSalesUsername: Joi.string().allow('', null),
  website: Joi.string().allow('', null).uri(),
  employeeCount: Joi.number().allow(null),
})

interface CustomerEditorProps {
  customerId?: string
  keyToEdit: keyof CustomerDetailsModel
  place: number
  onClose: () => void
}

const CustomerEditor: React.VFC<CustomerEditorProps> = ({ customerId, place, onClose, keyToEdit }) => {
  const { t } = useCustomTranslation()
  const [customer, setCustomer] = useState<CustomerDetailsModel>()
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()
  const authFetch = useAuthFetchWithResult()
  const [validationActive, setValidationActive] = useState(false)
  const validationObject = useValidation(customer, customerSchema)
  const { enqueueErrorStack, enqueueSuccessStack } = useSnackbar()

  const saveCustomer = async (): Promise<void> => {
    // if save button clicked for the first time activate the validation
    if (!validationActive) setValidationActive(true)
    // if validation fails do not save
    if (Object.keys(validationObject).length > 0) return
    if (!customer) return

    try {
      await authFetch({
        path: 'customer-e3crm/customers/details/' + customerId,
        method: 'PUT',
        payload: { [keyToEdit]: customer[keyToEdit] },
      })
      enqueueSuccessStack(t('customers.success.save'))
    } catch (error) {
      const err = error as AuthFetchError

      enqueueErrorStack(t('customers.error.save', { errorCode: err.code, message: err.message }))
    }

    dispatch(refreshApiView())
    onClose()
  }

  useEffect((): (() => void) => {
    let isMounted = true
    const fetchCustomer = async (): Promise<void> => {
      setIsLoading(true)
      if (customerId) {
        const fetchedCustomer = await authFetch<CustomerDetailsModel>({ path: 'customer-e3crm/customers/details/' + customerId })

        if (fetchedCustomer && isMounted) {
          setCustomer(fetchedCustomer)
        }
      }
      if (isMounted) setIsLoading(false)
    }

    fetchCustomer()
    return () => (isMounted = false)
  }, [customerId]) //eslint-disable-line

  const handleInput = (value: string): void => {
    setCustomer(prev => ({ ...prev!, [keyToEdit]: keyToEdit === 'employeeCount' ? +value : value }))
  }

  const defineTitle = (): string | undefined => {
    switch (keyToEdit) {
      case 'employeeCount':
        return t('customerReport.employeeCount')
      case 'externalSalesUsername':
        return t('common.salesRepresentative')
      case 'internalSalesUsername':
        return t('common.insideSalesRepresentative')
      case 'website':
        return t('common.website')
      case 'note':
        return t('customers.info')

      default:
        return undefined
    }
  }

  const validation = validationActive ? validationObject : {}

  if (isLoading || !customerId) return null

  return (
    <Dialog
      open={true}
      confirmClose={true}
      onClose={() => onClose()}
      title={t('customers.edit')}
      place={place}
      actions={{
        primary: {
          name: t('common.save'),
          onClick: () => saveCustomer(),
          disabled: validationActive && Object.keys(validationObject).length > 0,
        },
        secondary: [
          {
            name: t('common.cancel'),
          },
        ],
      }}
    >
      <Grid container alignItems="center" style={{ marginTop: '0' }} spacing={2}>
        <Grid item xs={12}>
          <TextField
            helperText={validation[keyToEdit] ? validation[keyToEdit].message : false}
            label={defineTitle()}
            value={customer ? (customer[keyToEdit] ?? '').toString() : ''}
            required
            onChange={(value: string) => handleInput(value)}
            multiline={keyToEdit !== 'employeeCount'}
            type={keyToEdit === 'employeeCount' ? 'number' : undefined}
            name={keyToEdit}
          />
        </Grid>
      </Grid>
    </Dialog>
  )
}

export default CustomerEditor
