import React from 'react'
import { Grid } from '@mui/material'
import { Dialog } from '@e3dc-react/shell/Elements/Dialog'
import AddCallButton from '../Call/AddCallButton'
import AddCustomerReportButton from '../CustomerReport/AddCustomerReportButton'
import AddTodoButton from '../Todo/AddTodoButton'
import AddContactButton from './Contact/AddContactButton'
import ProspectiveCustomer from './ProspectiveCustomer'
import { useCustomTranslation } from '@e3dc-react/shell/Hooks/useCustomTranslation'
import AddNoteButton from '../Notes/AddNoteButton'
interface ProspectiveCustomerDisplayProps {
  customerId: string
  place: number
  onClose: () => void
}

const ProspectiveCustomerDisplay: React.VFC<ProspectiveCustomerDisplayProps> = ({ customerId, place, onClose }) => {
  const { t } = useCustomTranslation()

  return (
    <Dialog
      open={true}
      onClose={() => onClose()}
      title={t('prospectiveCustomer.title')}
      place={place}
      actions={{
        primary: undefined,
        secondary: undefined,
      }}
      additionalActionsLeft={
        <Grid container spacing={1}>
          <Grid item>
            <AddCallButton sapCustomerId={customerId} />
          </Grid>
          <Grid item>
            <AddCustomerReportButton sapCustomerId={customerId} />
          </Grid>
          <Grid item>
            <AddTodoButton sapCustomerId={customerId} />
          </Grid>
          <Grid item>
            <AddContactButton prospectiveCustomerId={customerId} />
          </Grid>
          <Grid item>
            <AddNoteButton sapCustomerId={customerId} />
          </Grid>
        </Grid>
      }
    >
      <ProspectiveCustomer customerId={customerId} />
    </Dialog>
  )
}

export default ProspectiveCustomerDisplay
