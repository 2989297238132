import React, { ReactElement } from 'react'
import { Typography } from '@mui/material'
import { Slider as SliderElement } from '@e3dc-react/shell/Elements/Slider'
import TodoPriority from '../TodoPriority'

const sliderMarks = ['sehr gering', 'gering', 'mittel', 'hoch', 'sehr hoch']

const getTodoPriority = (value: number): ReactElement => <TodoPriority priority={value} />

interface SliderProps {
  priority: number
  handleInput: (name: string, value: number) => void
  disabled?: boolean
}

const Slider: React.VFC<SliderProps> = ({ priority, handleInput, disabled }) => {
  return (
    <>
      <Typography variant={'subtitle2'}>Priorität</Typography>
      <SliderElement
        type="range"
        min={1}
        max={5}
        step={1}
        marks={[
          { value: 1, label: getTodoPriority(1) },
          { value: 2, label: getTodoPriority(2) },
          { value: 3, label: getTodoPriority(3) },
          { value: 4, label: getTodoPriority(4) },
          { value: 5, label: getTodoPriority(5) },
        ]}
        valueLabelFormat={(value: number) => sliderMarks[value - 1]}
        getAriaValueText={(value: number, index: number) => sliderMarks[index]}
        defaultValue={3}
        aria-labelledby="discrete-slider-always"
        valueLabelDisplay="auto"
        onChange={(value?: number | number[]) => handleInput('priority', value as number)}
        value={priority}
        disabled={disabled}
      />
    </>
  )
}

export default Slider
