import { AxiosError } from 'axios'
import { useFetchApiObject, FetchError } from '@e3dc-react/shell/Hooks/useFetch'
import { QueryObserverResult, RefetchOptions } from 'react-query'
import { UserModel } from 'src/Routes/Users/UserModel'

/**
 * Custom Hook that returns fetched users
 */
export const useUsers = (
  payload?: Record<string, unknown>
): [
  UserModel[] | undefined,
  boolean,
  FetchError | undefined,
  unknown,
  (options?: RefetchOptions | undefined) => Promise<QueryObserverResult<UserModel[], AxiosError<any>>> //eslint-disable-line
] => {
  return useFetchApiObject<UserModel[]>({ url: 'user-e3crm', payload })
}
