import React, { memo } from 'react'
import { Grid, Typography, Divider, Hidden } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { CheckboxField, MemoizedTextField } from '@e3dc-react/shell/Elements/InputFields'
import { AddButton, RemoveButton } from '@e3dc-react/shell/Elements/Buttons'
import { getArrayValidation } from '../../../Libs/validation.service'
import { deepEqual } from 'fast-equals'
import { ProjectModel } from '../ProjectModel'
import { ValidationResult } from 'src/Types/globalTypes'

const useStyles = makeStyles()(theme => ({
  removeButton: {
    '& button': {
      marginTop: 0,
      marginBottom: theme.spacing(1),
    },
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}))

interface FundingProps {
  funding?: Partial<ProjectModel>
  validation: ValidationResult
  onChange: (name: string, value: unknown) => void
  onArrayChange: (name: string, value: unknown, index: number) => void
  addFundingProgram: () => void
  removeFundingProgram: (index: number) => void
}
const Funding: React.VFC<FundingProps> = ({ funding, validation, onChange, onArrayChange, addFundingProgram, removeFundingProgram }) => {
  const getValidation = (index: number, type: string): string | undefined => getArrayValidation(validation, 'funding_carriers', index, type)
  const { classes } = useStyles()

  if (!funding) return null

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <CheckboxField
          label="Wird ein Förderprogramm in Anspruch genommen?"
          value={funding.is_funding_used || 0}
          onChange={value => onChange('is_funding_used', value)}
          mapping={[0, 1]}
        />
      </Grid>

      <Grid item xs={12}>
        <Typography>Programme</Typography>
      </Grid>
      <Grid item xs={12}>
        {funding.funding_carriers?.map((carrier, index) => {
          return (
            <Grid container spacing={2} key={index}>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={4}>
                        <MemoizedTextField
                          helperText={getValidation(index, 'name')}
                          label="Träger"
                          value={carrier.name || ''}
                          onChange={(value: string) => onArrayChange('name', value, index)}
                        />
                      </Grid>
                      <Grid item xs={12} md={7}>
                        <MemoizedTextField
                          helperText={getValidation(index, 'program_name')}
                          label="Programmbezeichnung"
                          value={carrier.program_name || ''}
                          onChange={(value: string) => onArrayChange('program_name', value, index)}
                        />
                      </Grid>
                      <Hidden mdDown>
                        <Grid item xs={1} className={classes.removeButton}>
                          <RemoveButton onClick={() => removeFundingProgram(index)}></RemoveButton>
                        </Grid>
                      </Hidden>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <MemoizedTextField
                      helperText={getValidation(index, 'specific_product_requirements')}
                      label="Spezielle Produktanforderungen des Programms"
                      value={carrier.specific_product_requirements || ''}
                      onChange={(value: string) => onArrayChange('specific_product_requirements', value, index)}
                      multiline={true}
                    />
                  </Grid>
                </Grid>
                <Hidden mdUp>
                  <Grid item xs={12} className={classes.removeButton}>
                    <RemoveButton onClick={() => removeFundingProgram(index)}></RemoveButton>
                  </Grid>
                </Hidden>
              </Grid>
              {funding.funding_carriers && index < funding.funding_carriers.length - 1 && (
                <Grid item xs={12}>
                  <Divider variant="fullWidth" className={classes.divider} />
                </Grid>
              )}
            </Grid>
          )
        })}
      </Grid>
      <Grid item xs={12} style={{ textAlign: 'center' }}>
        <AddButton onClick={addFundingProgram}></AddButton>
      </Grid>
    </Grid>
  )
}

export default memo(
  Funding,
  (prevProps, nextProp) => deepEqual(prevProps.funding, nextProp.funding) && deepEqual(prevProps.validation, nextProp.validation)
)
