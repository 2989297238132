import React from 'react'
import { AddButton } from '@e3dc-react/shell/Elements/Buttons'

import useModals from '../../Hooks/useModals'
import { useCustomTranslation } from '@e3dc-react/shell/Hooks/useCustomTranslation'
interface AddTodoButtonProps {
  callId?: number
  customerNoteId?: number
  salesNoteId?: number
  customerReportId?: number
  sapCustomerId?: string
  projectId?: number
  className?: string
  disabled?: boolean
}

const AddTodoButton: React.VFC<AddTodoButtonProps> = ({
  callId,
  customerNoteId,
  salesNoteId,
  customerReportId,
  sapCustomerId,
  projectId,
  className,
  disabled,
}) => {
  const { openTodoEditor } = useModals()
  const { t } = useCustomTranslation()

  return (
    <AddButton
      onClick={() => openTodoEditor({ callId, customerNoteId, salesNoteId, customerReportId, sapCustomerId, projectId })}
      label={t('todos.addTodoButton')}
      className={className}
      disabled={disabled}
    />
  )
}

export default AddTodoButton
