import React, { useState, useEffect } from 'react'
import Joi from 'joi'
import { useDispatch } from 'react-redux'

import { Grid, FormGroup, FormControlLabel, Typography } from '@mui/material'

import { useValidation } from '@e3dc-react/shell/Hooks/useValidation/useValidation'
import { useAuthFetchWithResult } from '@e3dc-react/shell/Hooks/AuthFetch'
import { Dialog } from '@e3dc-react/shell/Elements/Dialog'

import Details from './Editor/Details'
import Status from './Editor/Status'
import BuildingStructure from './Editor/BuildingStructure'
import Consumption from './Editor/Consumption'
import Generation from './Editor/Generation'
import Funding from './Editor/Funding'
import Questions from './Editor/Questions'
import Notes from './Editor/Notes'
import { refreshApiView } from '@e3dc-react/shell/State/ApiView/ApiViewActions'
import useModals from 'src/Hooks/useModals'
import { makeStyles } from 'tss-react/mui'
import AddTodoButton from 'src/Elements/Todo/AddTodoButton'
import { CheckboxField } from '@e3dc-react/shell/Elements/InputFields'
import { useSnackbar } from '@e3dc-react/shell/Hooks/Snackbar'
import useFetch, { useFetchApiObject } from '@e3dc-react/shell/Hooks/useFetch'
import { EditorSkeleton } from '@e3dc-react/shell/Elements/Skeletons'
import { ErrorSkeleton } from '@e3dc-react/shell/Elements/Error'
import { FileTransfer } from '@e3dc-react/shell/Elements/FileTransfer'
import { PhotovoltaicModuleApiModel, ProjectModel, ProjectModelArray, ProjectModelArrayKey } from './ProjectModel'
import { useTypedSelector } from 'src/State/RootReducer'
import { Unwrap } from 'src/Types/globalTypes'
import { useCustomTranslation } from '@e3dc-react/shell/Hooks/useCustomTranslation'
import { shallowEqual } from 'fast-equals'
import { AuthFetchError } from '@e3dc-react/shell/Types/api'
const stateSchema = Joi.object().keys({
  user_id: Joi.number(),
  id: Joi.number(),
  // details
  reference: Joi.string().required(),
  location: Joi.string().required(),
  certified: Joi.number().allow(null),
  company_name: Joi.string().required(),
  company_contact: Joi.string().required(),
  company_phone: Joi.string().required(),
  company_mail: Joi.string()
    .email({ minDomainSegments: 2, tlds: { allow: false } })
    .required(),
  // status
  project_start: Joi.date().required(),
  project_end: Joi.date().required(),
  is_planned: Joi.number().allow(null),
  is_realised: Joi.number().allow(null),
  probability_of_realisation: Joi.number().allow(null),
  // buildingStructure
  building_type: Joi.string().required(),
  branch: Joi.string().required(),
  connected_load: Joi.number().required(),
  transducer_measurement: Joi.number().allow(null),
  is_apartment_building: Joi.number().allow(null),
  residential_units_amount: Joi.number().allow(null),
  owners_amount: Joi.number().allow(null),
  tenants_amount: Joi.number().allow(null),
  settlement_project: Joi.number().allow(null),
  objects_amount: Joi.number().allow(null),
  residential_amount: Joi.number().allow(null),
  mains_connections: Joi.number().allow(null),
  //consumption
  consumption_per_year: Joi.number().required(),
  peak_load: Joi.number().required(),
  day_usage: Joi.number().required(),
  night_usage: Joi.number().required(),
  special_consumers: Joi.number().allow(null),
  heat_pump_own_counter: Joi.number().allow(null),
  own_load_profile: Joi.number().allow(null),
  consumers: Joi.array()
    .items(
      Joi.object().keys({
        id: Joi.number(),
        project_id: Joi.number(),
        name: Joi.string().allow(null, ''),
        amount: Joi.number().allow(null),
        type: Joi.string().allow(null, ''),
        consumption: Joi.number().allow(null),
      })
    )
    .allow(null),

  //generation
  photovoltaic_systems: Joi.array()
    .items(
      Joi.object().keys({
        id: Joi.number(),
        project_id: Joi.number(),
        type: Joi.string().allow(null, ''),
        power: Joi.number().min(10).allow(null),
        yeld: Joi.number().allow(null),
        orientation: Joi.string().allow(null, ''),
        status: Joi.string().allow(null, ''),
        commissioning_year: Joi.number().allow(null),
        module_manufacturer: Joi.string().allow(null, ''),
        inverter_manufacturer: Joi.string().allow(null, ''),
      })
    )
    .allow(null),
  generators: Joi.array().items(
    Joi.object().keys({
      id: Joi.number(),
      project_id: Joi.number(),
      name: Joi.string().allow(null, ''),
      type: Joi.string().allow(null, ''),
      yeld: Joi.number().allow(null),
      operating_mode: Joi.string().allow(null, ''),
      status: Joi.string().allow(null, ''),
    })
  ),
  //funding
  is_funding_used: Joi.number().allow(null),
  funding_carriers: Joi.array()
    .items(
      Joi.object().keys({
        id: Joi.number(),
        project_id: Joi.number(),
        name: Joi.string().allow(null, ''),
        program_name: Joi.string().allow(null, ''),
        specific_product_requirements: Joi.string().allow(null, ''),
      })
    )
    .allow(null),

  //questions
  project_consulting: Joi.number().allow(null),
  project_selection_support: Joi.number().allow(null),
  technical_consulting: Joi.number().allow(null),
  inverter_design: Joi.number().allow(null),
  offer_preparation: Joi.number().allow(null),
  storage_calculation: Joi.number().allow(null),
  installator_procurement: Joi.number().allow(null),
  counter_concept: Joi.number().allow(null),
  created_at: Joi.date().allow(null, ''),
  //notes
  notes: Joi.string().allow(null, ''),
})

const useStyles = makeStyles()(theme => ({
  leftAction: {
    paddingLeft: theme.spacing(3),
  },
  spacer: {
    marginBottom: theme.spacing(4),
  },
}))

interface ProjectEditorProps {
  projectId?: number
  onClose: () => void
  place: number
}

const ProjectEditor: React.VFC<ProjectEditorProps> = ({ projectId, onClose, place }) => {
  const { t } = useCustomTranslation()
  const { classes } = useStyles()
  const { openTodoEditor, openConfirmationModal } = useModals()
  const userId = useTypedSelector(s => s.auth.decoded?.userId)
  const defaultState: ProjectModel = {
    user_id: userId,
    consumers: [],
    photovoltaic_systems: [],
    generators: [],
    funding_carriers: [],
  }
  const [project, setProject] = useState<ProjectModel>(defaultState)
  const [fetchedProject, projectLoading, projectError] = useFetch<ProjectModel>({
    url: 'projects/' + projectId,
    doNotFetch: projectId === undefined,
  })
  const [generators, generatorsLoading, generatorsError] = useFetchApiObject<PhotovoltaicModuleApiModel[]>({
    url: 'pv-modules',
  })
  const [withTodo, setWithTodo] = useState(false)
  const authFetch = useAuthFetchWithResult()
  const dispatch = useDispatch()
  const [validationActive, setValidationActive] = useState(false)
  const validationObject = useValidation(project, stateSchema)
  const [producerList, setProducerList] = useState<string[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [details, setDetails] = useState<Partial<ProjectModel>>()
  const [status, setStatus] = useState<Partial<ProjectModel>>()
  const [buildingStructure, setBuildingStructure] = useState<Partial<ProjectModel>>()
  const [consumption, setConsumption] = useState<Partial<ProjectModel>>()
  const [generation, setGeneration] = useState<Partial<ProjectModel>>()
  const [funding, setFunding] = useState<Partial<ProjectModel>>()
  const [questions, setQuestions] = useState<Partial<ProjectModel>>()
  const [notes, setNotes] = useState<Partial<ProjectModel>>()
  const { enqueueErrorStack, enqueueSuccessStack } = useSnackbar()

  const closeDialog = (): void => {
    setProject(defaultState)
    onClose()
  }

  // initial property initialization is called on first call and after each save action
  useEffect(() => {
    if ((projectId && project.reference) || !projectId) {
      setDetails({
        reference: project.reference,
        location: project.location,
        company_name: project.company_name,
        certified: project.certified,
        company_contact: project.company_contact,
        company_phone: project.company_phone,
        company_mail: project.company_mail,
      })
      setStatus({
        project_start: project.project_start,
        project_end: project.project_end,
        is_planned: project.is_planned,
        is_realised: project.is_realised,
        probability_of_realisation: project.probability_of_realisation,
      })
      setBuildingStructure({
        building_type: project.building_type,
        branch: project.branch,
        connected_load: project.connected_load,
        transducer_measurement: project.transducer_measurement,
        is_apartment_building: project.is_apartment_building,
        residential_units_amount: project.residential_units_amount,
        owners_amount: project.owners_amount,
        tenants_amount: project.tenants_amount,
        settlement_project: project.settlement_project,
        objects_amount: project.objects_amount,
        residential_amount: project.residential_amount,
        mains_connections: project.mains_connections,
      })
      setConsumption({
        consumption_per_year: project.consumption_per_year,
        peak_load: project.peak_load,
        day_usage: project.day_usage,
        night_usage: project.night_usage,
        special_consumers: project.special_consumers,
        heat_pump_own_counter: project.heat_pump_own_counter,
        own_load_profile: project.own_load_profile,
        consumers: project.consumers,
      })
      setGeneration({
        photovoltaic_systems: project.photovoltaic_systems,
        generators: project.generators,
      })
      setFunding({
        is_funding_used: project.is_funding_used,
        funding_carriers: project.funding_carriers,
      })
      setQuestions({
        project_consulting: project.project_consulting,
        project_selection_support: project.project_selection_support,
        technical_consulting: project.technical_consulting,
        inverter_design: project.inverter_design,
        offer_preparation: project.offer_preparation,
        storage_calculation: project.storage_calculation,
        installator_procurement: project.installator_procurement,
        counter_concept: project.counter_concept,
        created_at: project.created_at,
      })
      setNotes({
        notes: project.notes,
      })
    }
  }, [project, projectId])

  const handleInput = (name: string, value: unknown, arrayName?: string, changedArray?: ProjectModelArray): void => {
    if (arrayName && changedArray) {
      setProject(project => ({
        ...project,
        [arrayName]: changedArray,
      }))
    } else {
      setProject(project => ({ ...project, [name]: value }))
    }
  }
  //specific functions for array values in ProjectModel, todo: solve with generics?
  const handleConsumerChange = (name: string, value: unknown, index: number): void => {
    const changedArray = project['consumers'].map((item, itemIndex) => {
      if (index === itemIndex) return { ...item, [name]: value }
      return item
    })

    handleInput(name, value, 'consumers', changedArray)
  }

  const handlePvChange = (name: string, value: unknown, index: number): void => {
    const changedArray = project['photovoltaic_systems'].map((item, itemIndex) => {
      if (index === itemIndex) return { ...item, [name]: value }
      return item
    })

    handleInput(name, value, 'photovoltaic_systems', changedArray)
  }

  const handleGeneratorsChange = (name: string, value: unknown, index: number): void => {
    const changedArray = project['generators'].map((item, itemIndex) => {
      if (index === itemIndex) return { ...item, [name]: value }
      return item
    })

    handleInput(name, value, 'generators', changedArray)
  }

  const handleFundingsChange = (name: string, value: unknown, index: number): void => {
    const changedArray = project['funding_carriers'].map((item, itemIndex) => {
      if (index === itemIndex) return { ...item, [name]: value }
      return item
    })

    handleInput(name, value, 'funding_carriers', changedArray)
  }

  const saveProject = async (): Promise<void> => {
    setIsLoading(true)
    // if save button clicked for the first time activate the validation
    if (!validationActive) setValidationActive(true)
    // if validation fails do not save
    if (Object.keys(validationObject).length > 0) {
      enqueueErrorStack('Füllen Sie bitte alle rotmarkierte Pflichtfelder aus.')
      setIsLoading(false)
      return
    }

    const project = { ...details, ...status, ...consumption, ...generation, ...buildingStructure, ...funding, ...notes, ...questions }
    if (projectId) {
      try {
        await authFetch({ path: 'projects/' + projectId, method: 'PUT', payload: { ...project, id: projectId } })
        enqueueSuccessStack('Projekt gespeichert')
        onClose()
        if (withTodo) openTodoEditor({ projectId: projectId })
      } catch (error) {
        const err = error as AuthFetchError

        enqueueErrorStack('Projekt bearbeiten ist fehlgeschlagen', err.code, err.message)
        setIsLoading(false)
      } finally {
        setIsLoading(false)
      }
    } else {
      try {
        const response = await authFetch<number>({ path: 'projects', method: 'POST', payload: project })
        enqueueSuccessStack('Projekt hinzugefügt')
        onClose()
        if (withTodo) openTodoEditor({ projectId: response })
      } catch (error) {
        const err = error as AuthFetchError

        enqueueErrorStack('Projekt hinzufügen ist fehlgeschlagen', err.code, err.message)
        setIsLoading(false)
      } finally {
        setIsLoading(false)
      }
    }
    dispatch(refreshApiView())
  }

  const deleteProject = async (): Promise<void> => {
    try {
      await authFetch({ path: 'projects/' + projectId, method: 'DELETE' })
      enqueueSuccessStack('Projekt gelöscht')
    } catch (error) {
      const err = error as AuthFetchError

      enqueueErrorStack('Projekt löschen ist fehlgeschlagen', err.code, err.message)
    }
    onClose()
    dispatch(refreshApiView())
  }

  useEffect(() => {
    if (projectId && fetchedProject) {
      setProject(fetchedProject)
    }
    setProducerList(Array.from(new Set(generators?.map(generator => generator['PRODUCER']))).sort())
  }, [projectId, generators, fetchedProject])

  const removeFromArray = (type: ProjectModelArrayKey, index: number): void => {
    setProject(state => ({
      ...state,
      [type]: (state[type] as Unwrap<ProjectModelArray>).filter((_item, itemIndex) => index !== itemIndex),
    }))
  }

  const addToArray = (type: ProjectModelArrayKey, defaultValues: Record<string, unknown> = {}): void => {
    setProject(state => ({
      ...state,
      [type]: [...state[type], { ...defaultValues }],
    }))
  }

  const secondaryActions = []
  if (projectId)
    secondaryActions.push({
      name: t('common.delete'),
      onClick: () =>
        openConfirmationModal({
          title: 'Projekt löschen',
          text: 'Wollen sie diese Projekt wirklich löschen?',
          onConfirm: async (onClose: () => void) => {
            await deleteProject()
            onClose()
          },
          buttonText: [t('common.cancel'), t('common.delete')],
        }),
    })
  secondaryActions.push({
    name: t('common.cancel'),
  })

  const validation = validationActive ? validationObject : {}

  return (
    <Dialog
      open={true}
      onClose={() => closeDialog()}
      confirmClose={!shallowEqual(project, projectId ? fetchedProject : defaultState)}
      title={`Projekt ${projectId ? 'bearbeiten' : 'einfügen'}`}
      place={place}
      actions={{
        primary: {
          disabled: validationActive && Object.keys(validationObject).length > 0,
          onClick: () => saveProject(),
          name: t('common.save'),
          isLoading: isLoading,
        },
        secondary: secondaryActions,
      }}
      additionalActionsLeft={
        <div className={classes.leftAction}>
          {projectId ? (
            <AddTodoButton projectId={projectId} />
          ) : (
            <FormGroup row>
              <FormControlLabel
                control={<CheckboxField value={withTodo} onChange={() => setWithTodo(!withTodo)} color="primary" />}
                label="Todos für dieses Projekt anlegen"
              />
            </FormGroup>
          )}
        </div>
      }
    >
      {(projectError && projectId) || generatorsError ? (
        <ErrorSkeleton title={'Projekt konnte nicht geladen werden'} type={'editor'} />
      ) : projectLoading || generatorsLoading ? (
        <EditorSkeleton />
      ) : (
        <Grid container alignItems="center" style={{ marginTop: '0' }} spacing={4}>
          <Grid item xs={12}>
            <Typography variant="h6">Projektdetails</Typography>
          </Grid>
          <Grid item xs={12} className={classes.spacer}>
            <Details details={details} onChange={(name, value) => handleInput(name, value)} validation={validation} />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6">Projektstatus</Typography>
          </Grid>
          <Grid item xs={12} className={classes.spacer}>
            <Status status={status} onChange={(name, value) => handleInput(name, value)} validation={validation} />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6">Gebäudestruktur</Typography>
          </Grid>
          <Grid item xs={12} className={classes.spacer}>
            <BuildingStructure buildingStructure={buildingStructure} onChange={(name, value) => handleInput(name, value)} validation={validation} />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6">Verbrauchsprofil</Typography>
          </Grid>
          <Grid item xs={12} className={classes.spacer}>
            <Consumption
              consumption={consumption}
              onChange={(name, value) => handleInput(name, value)}
              onArrayChange={handleConsumerChange}
              validation={validation}
              addConsumer={() => addToArray('consumers')}
              removeConsumer={index => removeFromArray('consumers', index)}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6">Erzeugung</Typography>
          </Grid>
          <Grid item xs={12} className={classes.spacer}>
            <Generation
              generation={generation}
              onPVChange={handlePvChange}
              onGeneratorsChange={handleGeneratorsChange}
              validation={validation}
              addPhotovoltaicSystem={defaultValues => addToArray('photovoltaic_systems', defaultValues)}
              removePhotovoltaicSystem={index => removeFromArray('photovoltaic_systems', index)}
              addGenerator={() => addToArray('generators')}
              removeGenerator={index => removeFromArray('generators', index)}
              producerList={producerList}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6">Förderprogramme</Typography>
          </Grid>
          <Grid item xs={12} className={classes.spacer}>
            <Funding
              funding={funding}
              onChange={(name, value) => handleInput(name, value)}
              onArrayChange={handleFundingsChange}
              validation={validation}
              addFundingProgram={() => addToArray('funding_carriers')}
              removeFundingProgram={index => removeFromArray('funding_carriers', index)}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6">Was können wir für Sie tun?</Typography>
          </Grid>
          <Grid item xs={12} className={classes.spacer}>
            <Questions questions={questions} onChange={(name, value) => handleInput(name, value)} validation={validation} />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6">Anmerkungen</Typography>
          </Grid>
          <Grid item xs={12} className={classes.spacer}>
            <Notes notes={notes} onChange={(name, value) => handleInput(name, value)} validation={validation} />
          </Grid>

          <Grid item xs={12}>
            {project.id ? (
              <FileTransfer
                uploadUrl={'projects/' + project.id + '/upload'}
                downloadListUrl={'projects/' + project.id + '/download/list'}
                downloadGenerateUrl={(filename: string) => 'projects/' + project.id + '/download/' + filename}
                deleteGenerateUrl={(filename: string) => 'projects/' + project.id + '/delete/' + filename}
                apiEndpoint={process.env.REACT_APP_API_ENDPOINT ?? ''}
              />
            ) : (
              <Typography variant="subtitle2" color="error">
                {t('common.filemanagement.firstUpload')}
              </Typography>
            )}
          </Grid>
        </Grid>
      )}
    </Dialog>
  )
}

export default ProjectEditor
