import { compose, applyMiddleware, createStore } from 'redux'
import { persistStore } from 'redux-persist'

import rootReducer from './RootReducer'
import thunk from 'redux-thunk'

const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({}) : compose

const enhancer = composeEnhancers(
  applyMiddleware(thunk)
  // other store enhancers if any
)

const store = createStore(rootReducer, enhancer)
const persistor = persistStore(store)

// pause the persistor and decide to resume when initializing the app... if relogin was successfull continue...  and after login (but before saving credentials) continue
persistor.pause()

// add the persistor to the store, since appShell might need to access it and cannot include this file to do so!
store._PERSISTOR = persistor

export { store, persistor }
