import React, { memo } from 'react'
import { Grid, FormControl, Typography } from '@mui/material'

import CustomerSelect from '../../Customer/Select/Select'
import CustomerSelectContact from '../../Customer/Select/SelectContact'
import { shallowEqual } from 'fast-equals'
import { ValidationResult } from 'src/Types/globalTypes'
import PotentialSlider from './PotentialSlider'
import { useCustomTranslation } from '@e3dc-react/shell/Hooks/useCustomTranslation'
import { CustomerReportModel } from 'src/Routes/CustomerReport/CustomerReportModel'
/**
 * Part of the customer report editor with the information about the customer.
 */
interface CustomerModel {
  sapCustomerId?: string
  sapContactId?: string
  potential?: number
}

interface CustomerProps {
  validation: ValidationResult
  customer: CustomerModel
  handleInput: (name: keyof CustomerReportModel, value: unknown) => void
  disabled: boolean
}

const Customer: React.VFC<CustomerProps> = ({ validation, customer, handleInput, disabled }) => {
  const { sapCustomerId, sapContactId, potential } = customer
  const { t } = useCustomTranslation()
  return (
    <>
      <Grid item xs={12} sm={9}>
        <FormControl error={Boolean(validation.sapCustomerId)}>
          <CustomerSelect
            onSelect={cnr => handleInput('sapCustomerId', cnr)}
            value={sapCustomerId}
            withRedirect={true}
            error={validation.sapCustomerId ? t('customerReport.error.noCustomerChosen') : undefined}
            disabled={disabled}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={3}>
        <FormControl error={Boolean(validation.sapCustomerId)}>
          <CustomerSelectContact
            sapCustomerId={sapCustomerId ?? ''}
            value={sapContactId}
            onSelect={contact => handleInput('sapContactId', contact.toString())}
            error={validation.sapContactId ? t('customerReport.error.noContactChosen') : undefined}
            disabled={disabled || !sapCustomerId}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle2">{t('customerReport.potential')}</Typography>
        <PotentialSlider
          potential={potential || 0}
          handleInput={(value?: number | number[]) => handleInput('potential', value)}
          disabled={disabled}
        />
      </Grid>
    </>
  )
}

export default memo(
  Customer,
  (prevProps, nextProp) => shallowEqual(prevProps.customer, nextProp.customer) && shallowEqual(prevProps.validation, nextProp.validation)
)
