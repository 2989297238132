import { UserModel } from 'src/Routes/Users/UserModel'
import { UserAction } from './UserModel'

interface UserState {
  userData?: UserModel
}

const initialState: UserState = {
  userData: undefined,
}

const userReducer = (state: UserState = initialState, action: UserAction): UserState => {
  switch (action.type) {
    case 'UPDATE_USER_DATA':
      return {
        ...state,
        userData: action.userData,
      }
    default:
      return state
  }
}

export default userReducer
